/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../components/app-context";
import { boolToStringFormatter } from "../../../commonUtil/utils/formatter";
import Button from "@cx/ui/Button";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../api/xmmAxios";
import { OperationRecord } from "../../../constants/ModuleConstants";
import GenericSlider from "../../../commonUtil/components/GenericSlider";
import CustomLoadingOverlay from "../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
// import CheckboxCell from "../../../commonUtil/renders/CheckboxCell";
import CheckboxCell from "../../../components/reusable/CheckboxCell";
import OperationTabs from "./content/tabs/OperationTabs";
import { OperationContext } from "./operation-context";
import { toast } from "@cx/ui/Toast";
import { applyCustomKeyNavigation } from "../../../commonUtil/utils/keyNavigation";

class MainPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    // bind other external actions
    this.addActionSlider = this.addActionSlider.bind(this);
    this.hideSlider = this.hideSlider.bind(this);
    this.closeSlider = this.closeSlider.bind(this);
    this.updateGridAfterSave = this.updateGridAfterSave.bind(this);
    this.updateGridAfterBulkEdit = this.updateGridAfterBulkEdit.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.formatCategoryGroup = this.formatCategoryGroup.bind(this);
    const localeStrings = context.localeStrings;
    const gridOptions = {
      // other state props
      searchKey: context.globalOperationsGrid.searchKey,
      globalOperationsGrid: context.globalOperationsGrid,
      pageTitle: "Global Operations",
      editOption: null, // set values as edit, add, delete
      operationName: "",
      rowRecord: OperationRecord,
      sliderWidth: 1000,
      flexWidth: true,
      showSlide: false,
      disableAction: false,
      // ag-grid props
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        suppressMenu: false,
        // headerClass: "ag-numeric-header",
        sortingOrder: ["asc", "desc", null],
        minWidth: 100,
        autoHeight: true,
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 120,
          minWidth: 120,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            suppressMiniFilter: true,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      rowSelection: "single",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
          }
        ],
        hiddenByDefault: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    this.loadGridData();
    this.loadPositionOptions();
    window.addEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }

  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const globalOperationsGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      console.log("save filter", globalOperationsGrid);
      this.setState({
        globalOperationsGrid
      });
      this.context.setGlobalOperationsGridState(globalOperationsGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, searchKey } =
      this.state.globalOperationsGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.setColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey
        },
        prevState => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  onFirstDataRendered(params) {
    this.restoreGridState();
  }
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.operationId; // primary or unique key of record
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleGridSizeChanged = () => {
    this.sizeToFit();
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.applySortConfig();
    this.sizeToFit();
  };
  updateState(datalist) {
    if (datalist && Array.isArray(datalist)) {
      datalist.forEach((row, index) => {
        row.id = row.operationId.toString();
        if (row.labels) {
          const { labels } = row.labels;
          if (labels && Array.isArray(labels)) {
            row.labels = labels;
            // row.labelDisplay = labels.join(", ");
          } else {
            row.labels = [];
            // row.labelDisplay = "";
          }
        } else {
          row.labels = [];
          // row.labelDisplay = "";
        }
      });
      // clear all overlays
      this.gridApi && this.gridApi.hideOverlay();
    } else {
      // show 'no rows' overlay
      this.gridApi && this.gridApi.showNoRowsOverlay();
    }
    this.setState({ rowData: datalist }, () => {
      setTimeout(() => {
        this.sizeToFit();
      }, 100);
    });
  }
  loadGridData() {
    // Point to static Json file
    // const restUrl = window.origin + "/data/getAllDealerOperations.json";
    const restUrl = "/opsadmin/rest-db/getValues/globalOps";

    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          console.log(data);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching global operations.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  loadPositionOptions() {
    const { globalOpsData } = this.context;
    const { positionOptions, positionOptionsMap } = globalOpsData;
    if (positionOptions && positionOptions.length !== 0) {
      this.setState({ positionOptions, positionOptionsMap });
      return;
    }
    const restUrl = "/opsadmin/rest-db/getValues/positionOptions";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      positionOptions => {
        if (positionOptions && Array.isArray(positionOptions)) {
          console.log(positionOptions);
          const positionOptionsMap = {};
          positionOptions.forEach(pos => {
            pos.value = pos.value.toString();
            positionOptionsMap[pos.value] = pos.label;
          });
          this.setState({ positionOptions, positionOptionsMap });
          globalOpsData.positionOptions = positionOptions;
          globalOpsData.positionOptionsMap = positionOptionsMap;
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching position options.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  // event handler to refresh grid data
  refreshGridData = () => {
    this.loadGridData();
  };

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "name",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "categoryName",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  showError(operation) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(operation.operationId);
    if (rowNode) {
      const params = {
        force: true,
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.showError(operation);
    toast.error(operation.errors[field]);
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "price":
          if (newValue && newValue > 9999.99) {
            errorKey = "xmm.portal.validation.price_exceeds_max";
          }
          break;
        case "shopDuration":
          if (newValue && newValue > 1440) {
            errorKey = "xmm.portal.validation.duration_max_value_exceeded";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent(params) {
    const field = params.colDef.field;
    if (field === "name") {
      // callback logic to open slider, show tooltip, icon click, show modal window etc
      const record = params.data;
      console.log("Callback of cellclickevent for", record);
      // we can pass row record when slider is opened
      this.setState(prevState => ({
        showSlide: !prevState.showSlide,
        editOption: "edit",
        operationName: !record.name ? "Edit Operation" : record.name,
        rowRecord: record
      }));
    }
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (this.validateField(params)) {
      this.clearFieldValidation(params);
      this.onSaveCellEdit(params);
    }
  }
  onSaveCellEdit(params) {
    this.savePayload(params.data, params.colDef.field, params.value);
  }
  /* celledit handler to save edits */
  savePayload(record, field, value) {
    const restEndPoint = "/opsadmin/rest-db/updateTableData/operation";
    const payload = this.getCellEditPayload(record, field, value);
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload
      },
      data => {
        if (data) {
          toast.success(data);
          // const dealerOperation = data.dealerOperation;
          // // ag-grid API to update rowData for edited cell.
          // if (this.gridApi) {
          //   const rowNode = this.gridApi.getRowNode(
          //     dealerOperation.operationId
          //   );
          //   // copy values from response and preserve existing values that are not in the response like priceStatus, contentEnabledAlacarte, and contentEnabledMenus
          //   // dealerOperation = Object.assign(rowNode.data, dealerOperation);
          //   rowNode.data[field] = dealerOperation[field];
          //   // Replace the data on the rowNode (all columns in that row). When complete, the grid will refresh the rendered row.
          //   rowNode.setData(rowNode.data);
          // }
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg);
      }
    );
  }
  getCellEditPayload(record, field, value) {
    const payload = {
      id: record.operationId
    };

    // console.log("getCellEditPayload", field, record);
    if (field) {
      payload[field] = value;
    }
    // console.log(payload);
    return payload;
  }
  /* Action event to clear column filters */
  clearFilters() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      if (filterModel) {
        this.gridApi.setFilterModel(null);
      }
      this.gridApi.onFilterChanged();
      document.querySelector("#operation-search-box").value = "";
      this.onSearchBoxChanged();
    }
  }
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#operation-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.setState({
        searchKey
      });
    }
  };
  getColumnList(localeStrings) {
    const baseCols = [
      // {
      //   headerName: "ID",
      //   field: "operationId",
      //   filter: "agTextCellEditor",
      //   hide: true,
      //   filterParams: { suppressMiniFilter: true },
      //   maxWidth: 100,
      //   minWidth: 80
      // },
      {
        headerName: "Operation Name",
        field: "name",
        pinned: "left",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell xmm-link-cell",
        autoHeight: true,
        minWidth: 200,
        maxWidth: 400,
        sortingOrder: ["asc", "desc"],
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Category",
        field: "categoryName",
        valueGetter: formatServiceCategory,
        cellClass: "xmm-wrap-cell",
        tooltipField: "categoryName",
        tooltipComponentParams: { field: "categoryName" },
        minWidth: 200,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: "Category Group",
        field: "serviceCategoryId",
        valueGetter: this.formatCategoryGroup,
        cellClass: "xmm-wrap-cell",
        minWidth: 200,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        hide: true
      },
      {
        headerName: "External Ops",
        headerClass: "ag-text-header",
        field: "cntExternalOps",
        type: "numberColumn",
        sortingOrder: ["asc", "desc"],
        suppressSizeToFit: true,
        maxWidth: 120,
        minWidth: 100
      },
      {
        headerName: "External Parts",
        field: "cntExternalParts",
        type: "numberColumn",
        maxWidth: 120,
        minWidth: 100
      },
      {
        headerName: "External Fluids",
        field: "cntExternalFluids",
        type: "numberColumn",
        maxWidth: 120,
        minWidth: 100
      },
      {
        headerName: "Integration Enabled",
        field: "integrationEnabled",
        valueFormatter: boolToStringFormatter,
        cellRendererFramework: CheckboxCell,
        cellRendererParams: {
          field: "integrationEnabled"
        },
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: false,
          buttons: ["clear"]
        },
        editable: false,
        maxWidth: 150,
        minWidth: 150
      },
      {
        headerName: "Labels",
        field: "labels",
        valueFormatter: labelsFormatter,
        // tooltipField: "labels",
        // tooltipComponentParams: { field: "labels" },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        cellClass: "xmm-wrap-cell",
        minWidth: 200
      },
      {
        headerName: "Description",
        field: "description",
        hide: true,
        headerClass: "ag-text-header",
        autoHeight: true,
        minWidth: 200,
        maxWidth: 500,
        sortingOrder: ["asc", "desc"],
        filter: "agTextColumnFilter"
      },
      {
        headerName: "Position",
        field: "positionId",
        hide: true,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        // tooltipField: "externalOpsList",
        // tooltipComponentParams: { field: "externalOpsList" },
        // cellClass: "xmm-wrap-cell",
        // valueFormatter: this.formatPosition,
        tooltipValueGetter: params => this.getPosition(params),
        valueGetter: this.getPosition,
        width: 150,
        // maxWidth: 250,
        minWidth: 150
      },
      {
        headerName: "External Ops List",
        field: "externalOpsList",
        hide: true,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        tooltipField: "externalOpsList",
        tooltipComponentParams: { field: "externalOpsList" },
        cellClass: "xmm-wrap-cell",
        width: 250,
        // maxWidth: 250,
        minWidth: 120
      },
      {
        headerName: "External Parts List",
        field: "externalPartsList",
        hide: true,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        tooltipField: "externalPartsList",
        tooltipComponentParams: { field: "externalPartsList" },
        cellClass: "xmm-wrap-cell",
        width: 250,
        // maxWidth: 250,
        minWidth: 120
      },
      {
        headerName: "External Fluids List",
        field: "externalFluidsList",
        hide: true,
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        tooltipField: "externalFluidsList",
        tooltipComponentParams: { field: "externalFluidsList" },
        cellClass: "xmm-wrap-cell",
        width: 250,
        // maxWidth: 250,
        minWidth: 120
      },
      {
        headerName: "Operation ID",
        field: "operationId",
        hide: true,
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        width: 120,
        // maxWidth: 250,
        minWidth: 80
      },
      {
        headerName: "Vehicles",
        field: "vehicles",
        hide: true,
        editable: true,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: [
                "All Vehicles",
                "None",
                "Factory Assigned",
                "Unsupported Makes Only"
              ]
            }
          };
        },
        refData: {
          "All Vehicles": localeStrings["xmm.portal.common.all_vehicles"],
          None: localeStrings["xmm.portal.common.none"],
          "Factory Assigned":
            localeStrings["xmm.portal.common.factory_assigned"],
          "Unsupported Makes Only":
            localeStrings["xmm.portal.common.unsupported_makes"]
        },
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        cellClass: "editable-caret-cell",
        width: 160,
        // maxWidth: 250,
        minWidth: 120
      }
    ];
    return baseCols;
  }
  // bind to add operation click event
  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: OperationRecord,
      operationName: "Add Operation"
    }));
  };
  /* Hide Handler for Slider to close the drawer
   */
  hideSlider = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: OperationRecord,
      operationName: ""
    }));
  };

  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSlider = event => {
    // status returned from App-context; close slider when form is not dirty
    const status = this.context.discardUnsavedChanges(event, this.hideSlider);

    if (status) {
      this.hideSlider(event);
    }
  };

  updateGridAfterBulkEdit = () => {
    // Add login here
  };
  /* Update Grid rows when new/existing record saved from Form slider
     Add case: Refresh all Grid rows;
     Edit case: Update Grid row with recently saved
  */
  updateGridAfterSave = (record, editOption) => {
    if (this.gridApi && record) {
      if (editOption === "add") {
        const { rowData } = this.state;
        const res = this.gridApi.applyTransaction({
          add: [record]
        });
        // get the row node with ID
        const rowNode = this.gridApi.getRowNode(record.operationId);
        rowNode.setSelected(true);
        this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
        // const gridParams = {
        //   force: true,
        //   rowNodes: [rowNode]
        // };
        rowData.push(record);
        this.setState({
          rowData,
          rowRecord: record,
          editOption: "edit",
          operationName: record.name
        });
        // this.callRefreshAfterMillis(gridParams, this.gridApi);
      } else if (editOption === "edit") {
        const rowNode = this.gridApi.getRowNode(record.operationId);
        rowNode.setData(record);
        this.setState({ rowRecord: record, operationName: record.name });
      }
    }
  };
  saveCellEdit(operation, field, value) {
    this.updateStatusBox(
      this.context.localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    if (operation.make === "ANY") {
      this.saveAnyMakeOperation(operation, field, value);
    } else {
      this.savePayload(operation, field, value);
    }
  }
  handleSaveCellEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    const operation = data;
    this.saveCellEdit(operation, field, value);
  };
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  getPosition = params => {
    const { positionOptionsMap } = this.state;
    return !params.data || !params.data.positionId
      ? ""
      : positionOptionsMap[params.data.positionId];
  };
  formatCategoryGroup = params => {
    if (params && params.data) {
      const { serviceCategoryId } = params.data;
      // return `${categoryName} (${serviceCategoryId})`;
      const { serviceCategoryGroupsMap } = this.context;
      if (serviceCategoryId) {
        const serviceCategoryGroup =
          serviceCategoryGroupsMap[serviceCategoryId.toString()];
        if (serviceCategoryGroup && serviceCategoryGroup.categoryGroupName) {
          return serviceCategoryGroup.categoryGroupName;
        }
      }
    }
    return "";
  };
  render() {
    const { localeStrings } = this.context;
    // set component state to operation-context
    const contextValue = {
      appContext: this.context, // send AppContext{} as props
      localeStrings,
      discardUnsavedChanges: this.context.discardUnsavedChanges,
      editOption: this.state.editOption,
      loadOperation: this.state.rowRecord,
      operations: this.state.operations,
      updateGridAfterBulkEdit: this.updateGridAfterBulkEdit,
      updateGridAfterSave: this.updateGridAfterSave
    };
    const header = (
      <React.Fragment>
        <OperationContext.Provider value={contextValue}>
          <div className="content-header">
            <div className="xmm-main-title">
              <h3>{this.state.pageTitle}</h3>
            </div>
            <div className="xmm-form-header">
              <Button
                htmlId="addActionBtn"
                buttonStyle="primary"
                disabled={this.state.disableAction}
                onClick={this.addActionSlider}
              >
                {"Add Operation"}
              </Button>
              <div className="xmm-input-search">
                <input
                  type="text"
                  id="operation-search-box"
                  className="xmm-input"
                  placeholder={"Search"}
                  onInput={this.onSearchBoxChanged}
                  autoComplete="off"
                />
              </div>
              <Dropdown
                icon={<IconMore />}
                htmlId="opcodesActionBtn"
                id="opcodesActionBtn"
                name="opcodesActionBtn"
                className="xmm-dotted-dropdown btn--icon hide"
                options={[]}
                value=""
                pullRight
              />
            </div>
          </div>
        </OperationContext.Provider>
      </React.Fragment>
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="globalOpsGrid" className="ag-grid-container ag-theme-balham">
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressMenuHide={false}
            // suppressContextMenu={true}
            suppressRowClickSelection={true}
            rowData={this.state.rowData}
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            frameworkComponents={this.state.frameworkComponents}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            onGridSizeChanged={this.handleGridSizeChanged}
            getRowNodeId={this.getRowNodeId}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={true}
            enableCharts={true}
            enableCellTextSelection={false}
            enableBrowserTooltips={true}
            rowHeight={50}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    // Add components within slider
    const modalTitle = <span>{this.state.operationName}</span>;
    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editOperationSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        closeButton={false}
      >
        <div>
          <OperationTabs
            key={"editOperation"}
            rowRecord={this.state.rowRecord}
            editForm={true}
            editOption={this.state.editOption}
            closeSlider={this.closeSlider}
            updateGridAfterSave={this.updateGridAfterSave}
          />
        </div>
      </GenericSlider>
    );
    return (
      <React.Fragment>
        {header}
        {gridWidget}
        {formSlider}
      </React.Fragment>
    );
  }
}

export default MainPage;

MainPage.propTypes = {};

function formatServiceCategory(params) {
  if (params && params.data) {
    const { categoryName, serviceCategoryId } = params.data;
    // return `${categoryName} (${serviceCategoryId})`;
    return categoryName && serviceCategoryId
      ? `${categoryName} (${serviceCategoryId})`
      : "";
  }
  return "";
}
function labelsFormatter(params) {
  if (!params || !params.data) {
    return "";
  }
  const { labels } = params.data;
  if (labels && Array.isArray(labels) && labels.length !== 0) {
    const str = labels.join(", ");
    return str;
  }
  return "";
}
/* eslint-enable no-console */
