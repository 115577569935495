// refer: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const IS_MOBILE = window.navigator.userAgent.indexOf("Mobi") > -1;
export const IS_IE = window.navigator.userAgent.indexOf("Trident") > -1;
export const IS_EDGE = window.navigator.userAgent.indexOf("Edge") > -1;
export const IS_CHROME = window.navigator.userAgent.indexOf("Chrome") > -1;

/* Use this file, Add ag-grid controls to global state  */
export const initAppConfig = {
  globalOperationsGrid: {
    colState: null,
    filterState: null,
    pivotState: null,
    searchKey: ""
  },

  packagesGrid: {
    colState: null,
    filterState: null,
    pivotState: null,
    searchKey: ""
  }
};
