import React from "react";

export const initPreviewState = {
  appContext: null,
  locale: "",
  showFilter: false,
  selectedOperation: null,
  operationList: [],
  partsAndLabor: null,
  decodeVinOk: false,
  acesAttributes: [],
  undecodedAttributes: [],
  vehicleDesc: {
    year: "",
    make: "",
    model: "",
    trim: "",
    engineType: "",
    engineSize: "",
    driveType: "",
    transmissionType: ""
  },
  vin: "", // 5XYKTCA67FG571802
  dealerCode: "",
  dealerFlag: "0",
  mileage: ""
};

export const PreviewContext = React.createContext(initPreviewState);
export const PreviewProvider = PreviewContext.Provider;
export const PreviewConsumer = PreviewContext.Consumer;
