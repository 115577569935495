/* eslint-disable no-console */

/* Admin portal related objects */
// Array for copytodealer PLMP page
export const SettingsChkValues = [
  {
    value: "copyDealerSettings",
    label: "Settings (Rounding, Taxes, Shop Supplies, etc)"
  },
  { value: "copyIntervals", label: "Intervals" },
  { value: "copyVehicleGroups", label: "Vehicle Groups" }
];
export const GlobalOperationsChkValues = [
  {
    value: "copyRepairOpSettings",
    label: "Repair Operation Settings (Names, Op Codes, Prices, Vehicles, etc)"
  },
  {
    value: "copyRepairOpPriceOverrides",
    label: "Repair Operation Pricing Overrides"
  }
];
export const OperationsChkValues = [
  { value: "copyDealerServices", label: "Dealer-created Operations" },
  { value: "copyServicePrices", label: "Factory Operations Prices" },
  { value: "copyServiceOpcodes", label: "Factory Operations Op Codes" },
  {
    value: "copyServiceOther",
    label:
      "Factory Operations Other Settings (Vehicles, Durations, Enabled, Op Code Data, etc)"
  },
  { value: "copyLaborOverrides", label: "Operation Overrides" }
];
export const MenusChkValues = [
  { value: "copyRecommendations", label: "Dealer Menus" },
  { value: "copyPackages", label: "Package Overrides" }
];
export const PartsLaborChkValues = [
  { value: "copyParts", label: "Parts (Prices)" },
  {
    value: "copyFluids",
    label: "Fluids (Prices, UOMs, Descriptions, Part Numbers)"
  },
  { value: "copyLaborRates", label: "Labor Rates" },
  { value: "copyPayTypes", label: "Pay Types" }
];

export const CopyDealerTemplate = {
  make: "",
  variant: "",
  dealerCode: "",
  targetDealerCode: "",
  copyDealerServices: 0,
  copyServicePrices: 0,
  copyServiceOpcodes: 0,
  copyServiceOther: 0,
  copyPackages: 0,
  copyRecommendations: 0,
  copyIntervals: 0,
  copyDealerSettings: 0,
  copyParts: 0,
  copyFluids: 0,
  copyLaborRates: 0,
  copyLaborOverrides: 0,
  copyVehicleGroups: 0,
  copyPayTypes: 0,
  menuTypes: []
};
export const CopyGlobalRepairTemplate = {
  dealerCode: "",
  targetDealerCode: "",
  copyRepairOpSettings: 0,
  copyRepairOpPriceOverrides: 0,
  copyPayTypes: 0
};
export const CopyAnyMakeDealerTemplate = {
  make: "",
  variant: "",
  dealerCode: "",
  targetDealerCode: "",
  copyDealerServices: 0,
  copyServicePrices: 0,
  copyServiceOpcodes: 0,
  copyServiceOther: 0,
  copyDealerSettings: 0,
  copyPayTypes: 0,
  copyLaborOverrides: 0
};
// used to initialize state for shared preview component
export const globalOpsPreviewState = {
  showFilter: false,
  selectedOperation: null,
  operationList: [],
  selectableOperations: [],
  selectedLabels: [],
  partsAndLabor: null,
  decodeVinOk: false,
  acesAttributes: [],
  undecodedAttributes: [],
  vehicleDesc: {
    year: "",
    make: "",
    model: "",
    trim: "",
    engineType: "",
    engineSize: "",
    driveType: "",
    transmissionType: ""
  },
  vin: "",
  dealerCode: "",
  dealerFlag: "0",
  mileage: "",
  serviceRecommendations: []
};

export const relationShipList = ["Primary", "Recommended", "Related/Optional"];

/* This objects are freezed, used for cell renderer  */
export const YesNoMap = {
  0: "NO",
  1: "YES",
  null: "NO"
};

/* This objects are freezed, used for cell renderer  */
export const YesNoDefault = {
  0: "NO",
  1: "YES"
};

/* This objects are freezed, used for cell renderer  */
export let DaysMap = {
  1: "1 Day",
  2: "2 Days",
  3: "3 Days",
  4: "4 Days",
  5: "5 Days",
  6: "6 Days",
  7: "7 Days"
};
// Place holder - not used yet
export const DaysArray = [
  { 1: "1 Day" },
  { 2: "2 Days" },
  { 3: "3 Days" },
  { 4: "4 Days" },
  { 5: "5 Days" },
  { 6: "6 Days" },
  { 7: "7 Days" }
];

export let ServiceKindMap = {
  repair: "Repair1",
  maintenance: "Maintenance1",
  autorecall: "Auto Recall1",
  declined: "Declined1"
};

export const MVC_CHOOSE_VEHICLES = "0";
export const MVC_ALL_VEHICLES = "1";

export const MetaVehicleScopeMap = {
  "-1": "None",
  3: "Factory Assigned",
  1: "All Vehicles",
  0: "Choose Vehicles",
  4: "Unscheduled Only",
  2: "All except Supported Makes"
};

export const MetaVehicleScopeAnyCatalogMap = {
  "-1": "None",
  1: "All Vehicles",
  2: "All except Supported Makes"
};

export const MetaVehicleScopeVariantCatalogMap = {
  "-1": "None",
  3: "Factory Assigned",
  1: "All Vehicles",
  0: "Choose Vehicles",
  4: "Unscheduled Only"
};

export const MetaVehicleScopeDealerCatalogMap = {
  "-1": "None",
  1: "All Vehicles",
  0: "Choose Vehicles",
  4: "Unscheduled Only"
};

export let EnabledDisabledOptions = [
  { value: "1", label: "Enabled" },
  { value: "0", label: "Disabled" }
];

export let ApplicationVisibilityOptions = [
  { value: "0", label: "All" },
  { value: "1", label: "Internal only" },
  { value: "-1", label: "None" }
];

export let DrivingConditionOptions = [
  { value: "Normal", label: "Normal" },
  { value: "Severe", label: "Severe" }
];

export let RoundToOptions = [
  { value: "0", label: "None" },
  { value: "1", label: "Round to Cents on the Dollar" },
  { value: "1a", label: "Round to Next Nickel" },
  { value: "2", label: "Round to Next Dime" },
  { value: "3", label: "Round to Next Dollar" },
  { value: "4", label: "Round to Ten Dollars" }
];

export const PriceStatusMap = {
  "Default Labor and Parts": "Default Labor and Parts",
  "Default Price Only": "Default Price Only",
  "Has Pricing Overrides": "Has Pricing Overrides",
  "No Pricing": "No Pricing",
  "Value Pricing": "Value Pricing"
};

export const PriceLevel = {
  DMS: "DMS",
  MANUAL: "MANUAL",
  MSRP: "MSRP"
};

export const UnitOfMeasure = {
  "": "",
  Gallons: "Gallons",
  Liters: "Liters",
  Milliliters: "Milliliters",
  Ounces: "Ounces",
  Pints: "Pints",
  Quarts: "Quarts"
};

export const VersionStatusMap = {
  "IN-PROGRESS": "In Progress",
  "NOT-READY": "Not Ready",
  "IN-TESTING": "In Testing",
  READY: "Ready",
  DELETED: "Deleted"
};

/* Since React gives warning on null values when binded with Form fields,
   convert null values to {""} before Formik field binding
   Usage - This object used for default form state in Add case, close slider
   Default case - Allow waiters should be enabled (ask from ASC team)
*/
/* Default global operation record used for Add case */
export const OperationRecord = {
  operationId: "",
  id: "",
  categoryName: "",
  cntExternalFluids: 0,
  cntExternalOps: 0,
  cntExternalParts: 0,
  dealerLookupEnabled: false,
  integrationEnabled: false,
  labels: [],
  name: "",
  serviceKind: "",
  serviceCategoryId: null,
  positionId: null
};
/* Default global operation record used for Edit case */
export const EditOperationRecord = {
  // dealerLookupEnabled: false,
  // integrationEnabled: false,
  labels: [],
  name: "",
  // internalName: "",
  positionId: null,
  serviceCategoryId: null,
  vehicles: ""
};

// TODO - add custom cols in ag-grid here- example
export const customCols = [
  {
    headerName: "Log",
    width: 100,
    cellRenderer: params => {
      const button = document.createElement("button");
      button.innerHTML = `Log me`;
      button.addEventListener("click", () => {
        // console.table(params.node.data);
      });
      return button;
    },
    pinned: "right",
    editable: false
  },
  // keep this action column to display status icon in future
  {
    headerName: "",
    field: "serviceId",
    pinned: "left",
    type: "actionColumn",
    cellRenderer(params) {
      const index = Number(params.value);
      if (typeof index !== "number" || isNaN(index)) {
        return;
      } else {
        return '<i class="fas fa-edit"></i>';
      }
    },
    width: 60
  }
];

export const AllMetaVehicleCriteriaList = Object.freeze([
  "Set(s)",
  "Model(s)",
  "Trim(s)",
  "Start Year", // only IS
  "End Year", // only IS, NONE if startYear is NONE
  "Year(s)", // "is in range of" or "is not"
  "Engine Type(s)",
  "Engine Size(s)",
  "Drive Type(s)",
  "Transmission Type(s)",
  "Body Type(s)",
  "Fuel Type(s)"
]);

export const SETS = "Set(s)";
export const MODELS = "Model(s)";
export const TRIMS = "Trim(s)";
export const YEARS = "Year(s)";
export const ENGINE_TYPES = "Engine Type(s)";
export const ENGINE_SIZES = "Engine Size(s)";
export const DRIVE_TYPES = "Drive Type(s)";
export const TRANSMISSION_TYPES = "Transmission Type(s)";
export const BODY_TYPES = "Body Type(s)";
export const FUEL_TYPES = "Fuel Type(s)";

export const AllMetaVehicleCriteriaMinusStartEndYear = Object.freeze([
  SETS,
  MODELS,
  TRIMS,
  YEARS,
  ENGINE_TYPES,
  ENGINE_SIZES,
  DRIVE_TYPES,
  TRANSMISSION_TYPES,
  BODY_TYPES,
  FUEL_TYPES
]);

export const NonUsaMetaVehicleCriteriaMinusStartEndYear = Object.freeze([
  SETS,
  MODELS,
  TRIMS,
  YEARS,
  ENGINE_TYPES,
  ENGINE_SIZES,
  DRIVE_TYPES,
  TRANSMISSION_TYPES
]);

export const XMMAdminSettings = {
  settings: []
};

export const MetaVehicleCriteria = Object.freeze({
  sets: [],
  models: [],
  trims: [],
  startYear: "",
  endYear: "",
  years: [], // is in range of OR is not
  engineTypes: [],
  engineSizes: [],
  driveTypes: [],
  transmissionTypes: [],
  bodyTypes: [],
  fuelTypes: []
});

export const AboutUsTemplate = {
  application: "",
  url: "",
  buildVersion: "",
  browser: "",
  operatingSystem: "",
  userName: "",
  copyYear: ""
};

export let CreatedByOptions = [
  { label: "All Types", value: "all" },
  { label: "Dealer", value: "dealer" },
  { label: "Factory", value: "factory" }
];

/* Define width, height for specific components here */
export const COMP_SIZES = Object.freeze({
  MAX_WIDTH: 400,
  MAX_HEIGHT: 150
});

export let CreatedByMap = {
  dealer: "Dealer",
  factory: "Factory"
};

export const IncludedInspectionTags = [
  "inspect",
  "disclaimer",
  "lubricate",
  "reset",
  "tighten",
  "test"
];

export const PartsFluidsPriceColumnIdBySource = {
  DMS: "dmsPrice",
  MSRP: "msrp",
  MANUAL: "price"
};

export const NO_PRICING_ANY_CATALOG = {
  baseLocale: "en_US",
  isStaging: 0,
  make: "ANY",
  label: "ANY",
  value: "ANY",
  pricingMethod: 0,
  variant: "SERVICETAB"
};

export function getCatalogFromMakeVariantMap(makeVariantMap, make) {
  let catalog = makeVariantMap[make];
  if (!catalog && make === "ANY") {
    const dealerCatalogs = Object.values(makeVariantMap);
    const { dealerCode } = dealerCatalogs[0];
    catalog = Object.assign({ dealerCode }, NO_PRICING_ANY_CATALOG);
  }
  return catalog;
}

/* util to translate Static objects  */
export const translateObjects = localeStrings => {
  ServiceKindMap = {
    repair: localeStrings["xmm.portal.common.repair_lbl"],
    maintenance: localeStrings["xmm.portal.common.maintenance_lbl"],
    autorecall: localeStrings["xmm.portal.common.autorecall_lbl"],
    declined: localeStrings["xmm.portal.common.decline_service_lbl"]
  };
  CreatedByMap = {
    dealer: localeStrings["xmm.portal.common.dealer_lbl"],
    factory: localeStrings["xmm.portal.common.factory_lbl"]
  };
  DaysMap = {
    1: localeStrings["xmm.portal.common.1_day"],
    2: localeStrings["xmm.portal.common.2_day"],
    3: localeStrings["xmm.portal.common.3_day"],
    4: localeStrings["xmm.portal.common.4_day"],
    5: localeStrings["xmm.portal.common.5_day"],
    6: localeStrings["xmm.portal.common.6_day"],
    7: localeStrings["xmm.portal.common.7_day"]
  };

  CreatedByOptions = [
    { label: localeStrings["xmm.portal.common.all_types_lbl"], value: "all" },
    { label: localeStrings["xmm.portal.common.dealer_lbl"], value: "dealer" },
    {
      label: localeStrings["xmm.portal.common.factory_lbl"],
      value: "factory"
    }
  ];
  MetaVehicleScopeMap["-1"] = localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeMap["3"] =
    localeStrings["xmm.portal.common.factory_assigned"];
  MetaVehicleScopeMap["1"] = localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeMap["0"] = localeStrings["xmm.portal.common.choose_vehicles"];
  MetaVehicleScopeMap["4"] =
    localeStrings["xmm.portal.common.unscheduled_only"];
  MetaVehicleScopeMap["2"] =
    localeStrings["xmm.portal.common.all_except_supported_makes"];

  MetaVehicleScopeAnyCatalogMap["-1"] = localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeAnyCatalogMap["1"] =
    localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeAnyCatalogMap["2"] =
    localeStrings["xmm.portal.common.all_except_supported_makes"];

  MetaVehicleScopeVariantCatalogMap["-1"] =
    localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeVariantCatalogMap["3"] =
    localeStrings["xmm.portal.common.factory_assigned"];
  MetaVehicleScopeVariantCatalogMap["1"] =
    localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeVariantCatalogMap["0"] =
    localeStrings["xmm.portal.common.choose_vehicles"];
  MetaVehicleScopeVariantCatalogMap["4"] =
    localeStrings["xmm.portal.common.unscheduled_only"];

  MetaVehicleScopeDealerCatalogMap["-1"] =
    localeStrings["xmm.portal.common.none"];
  MetaVehicleScopeDealerCatalogMap["1"] =
    localeStrings["xmm.portal.common.all_vehicles"];
  MetaVehicleScopeDealerCatalogMap["0"] =
    localeStrings["xmm.portal.common.choose_vehicles"];
  MetaVehicleScopeDealerCatalogMap["4"] =
    localeStrings["xmm.portal.common.unscheduled_only"];

  EnabledDisabledOptions = [
    { value: "1", label: localeStrings["xmm.portal.common.enabled"] },
    { value: "0", label: localeStrings["xmm.portal.common.disabled"] }
  ];

  ApplicationVisibilityOptions = [
    { value: "0", label: localeStrings["xmm.portal.common.all_lbl"] },
    { value: "1", label: localeStrings["xmm.portal.common.internal_only_lbl"] },
    { value: "-1", label: localeStrings["xmm.portal.common.none"] }
  ];

  DrivingConditionOptions = [
    {
      value: "Normal",
      label: localeStrings["xmm.portal.preview.vehicle.normal_lbl"]
    },
    {
      value: "Severe",
      label: localeStrings["xmm.portal.preview.vehicle.severe_lbl"]
    }
  ];

  RoundToOptions = [
    { value: "0", label: localeStrings["xmm.portal.common.none"] },
    {
      value: "1",
      label: localeStrings["xmm.portal.common.round_to_cents_on_dollar"]
    },
    {
      value: "1a",
      label: localeStrings["xmm.portal.common.round_to_next_nickel"]
    },
    {
      value: "2",
      label: localeStrings["xmm.portal.common.round_to_next_dime"]
    },
    {
      value: "3",
      label: localeStrings["xmm.portal.common.round_to_next_dollar"]
    },
    {
      value: "4",
      label: localeStrings["xmm.portal.common.round_to_next_10_dollars"]
    }
  ];

  PriceStatusMap["Default Labor and Parts"] =
    localeStrings["xmm.portal.common.default_labor_and_parts"];
  PriceStatusMap["Default Price Only"] =
    localeStrings["xmm.portal.common.default_price_only"];
  PriceStatusMap["Has Pricing Overrides"] =
    localeStrings["xmm.portal.common.has_priciing_override"];
  PriceStatusMap["No Pricing"] = localeStrings["xmm.portal.common.no_priciing"];
  PriceStatusMap["Value Pricing"] =
    localeStrings["xmm.portal.common.value_pricing"];

  PriceLevel.DMS = localeStrings["xmm.portal.common.dms_label"];
  PriceLevel.MANUAL = localeStrings["xmm.portal.common.manual"];
  PriceLevel.MSRP = localeStrings["xmm.portal.common.msrp"];

  UnitOfMeasure.Gallons = localeStrings["xmm.portal.common.gallons"];
  UnitOfMeasure.Liters = localeStrings["xmm.portal.common.liters"];
  UnitOfMeasure.Milliliters = localeStrings["xmm.portal.common.milliliters"];
  UnitOfMeasure.Ounces = localeStrings["xmm.portal.common.ounces"];
  UnitOfMeasure.Pints = localeStrings["xmm.portal.common.pints"];
  UnitOfMeasure.Quarts = localeStrings["xmm.portal.common.quarts"];

  YesNoDefault["0"] = localeStrings["xmm.portal.common.no_label"];
  YesNoDefault["1"] = localeStrings["xmm.portal.common.yes_label"];
  YesNoMap["0"] = localeStrings["xmm.portal.common.no_label"];
  YesNoMap["1"] = localeStrings["xmm.portal.common.yes_label"];
  YesNoMap[null] = localeStrings["xmm.portal.common.no_label"];
};

/* eslint-enable no-console */
