/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../app-context";
import { xlate } from "../../../commonUtil/i18n/locales";
import { hasUserRole } from "../../../role/roleUtil";
import {
  namespace,
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../api/xmmAxios";
// import ToggleSwitch from "@cx/ui/ToggleSwitch";
import Popover from "@cx/ui/Popover";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import { AgGridReact } from "ag-grid-react";
import { getCellClassRule } from "../../../commonUtil/utils/validation";
import { toast } from "@cx/ui/Toast";
import { applyCustomKeyNavigation } from "../../../commonUtil/utils/keyNavigation";
import { isArrayExist } from "../../../commonUtil/utils/object";
import CustomLoadingOverlay from "../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import TextEditor from "../../../commonUtil/editors/TextEditor";
import { toEmptyStringIfUndefined } from "../../../commonUtil/utils/string";
import Button from "@cx/ui/Button";
import { XMMAdminSettings } from "../../../constants/ModuleConstants";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Confirmation from "../../../commonUtil/dialog/Confirmation";
import MultiSelectSchemaEditor from "../../reusable/editors/MultiSelectSchemaEditor";

export default class XMMAdmin extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    const validationFields = ["dealers", "schemas"];
    const editableFields = ["dealers", "schemas"];
    const platform = window.navigator.platform;
    // this.xmmAdminToggleRef = React.createRef();
    // this.onSaveCellEdit = this.onSaveCellEdit.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.saveEnableAllHandler = this.saveEnableAllHandler.bind(this);

    const isMacOS = platform && platform.indexOf("Mac") >= 0;
    this.initializeLocaleValues(isMacOS);
    const { role } = context;
    const isMotorEnabled =
      hasUserRole("launchAir/motor", role) && namespace.indexOf("aus") === -1;
    const isMVEditorEnabled = hasUserRole("launchAir/mveditor", role);
    const isSSEnabled = hasUserRole("launchAir/servicestudio", role);
    this.state = {
      isMacOS,
      isMotorEnabled,
      isMVEditorEnabled,
      isSSEnabled,
      role
    };
    const gridOptions = {
      copyConfirmMessage: "",
      enableAllConfirmMessage: "",
      disableAllConfirmMessage: "",
      editOption: null, // set values as edit, add, delete
      showSlide: false,
      columnDefs: this.getColumnList(),
      actionColumnDefs: this.getActionColumnList(),
      settings: [],
      defaultColDef: {
        sortable: false,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        // sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        // filter: "agTextColumnFilter",
        cellClassRules: getCellClassRule(validationFields, editableFields, []),
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      eventDefaultColDef: {
        sortable: false,
        resizable: false,
        editable: false, // default disable editor
        enableRowGroup: false,
        width: 300,
        autoHeight: true,
        rowGroup: false
      },
      rowSelection: "single",
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        // laborRateCodeEditor: LaborRateCodeEditor,
        textEditor: TextEditor,
        schemaEditor: MultiSelectSchemaEditor
        // priceEditor: PriceEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      hiddenByDefault: false,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      }
    };
    this.state = gridOptions;
  }

  componentDidMount() {}

  componentWillUnmount() {}

  initializeLocaleValues(isMacOS) {
    this.xmmadminAppsLabel = xlate("xmmadmin.common.header");
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    // this.applySortConfig();
  };
  onEventLogGridReady = params => {
    this.eventLogGridApi = params.api;
    this.eventLogGridColumnApi = params.columnApi;
    this.eventLogGridApi.closeToolPanel();
  };
  updateState(data) {
    if (data) {
      let datalist = [];
      let eventdatalist = [];
      if (isArrayExist(data.settings) && data.settings.length > 0) {
        datalist = data.settings;
      }
      if (
        isArrayExist(data.xmmMaintenanceEventLog) &&
        data.xmmMaintenanceEventLog.length > 0
      ) {
        eventdatalist = data.xmmMaintenanceEventLog;
      }
      if (isArrayExist(datalist) && datalist.length === 0) {
        // show 'no rows' overlay
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        // clear all overlays
        this.gridApi && this.gridApi.hideOverlay();
      }
      console.log(datalist);
      this.setState(
        {
          settings: datalist,
          logevents: eventdatalist
        },
        () => {
          this.sizeToFit();
        }
      );
    }
  }
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const payload = {
      dealerCode
    };
    const restUrl = "/opsadmin/xmmsettings/get";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: payload,
        headers
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          // const filterList = data.filter(function(obj) {
          // return obj.pricingMethod === 1;
          // });
          console.log(data.settings);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  getColumnList() {
    const baseCols = [
      {
        headerName: "Client Type",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "clientType",
        width: 100,
        editable: false
      },
      {
        headerName: "Enabled?",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        width: 105,
        editable: false,
        cellRenderer(params) {
          // const schemaVal = toEmptyStringIfUndefined(params.value);
          const schemaVal = toEmptyStringIfUndefined(params.data.schemas);
          // const displayValue = toEmptyStringIfUndefined(
          //   xlate(OpcodeStatusKeyMap[params.value])
          // );
          // let statusValue = toEmptyStringIfUndefined(params.value);
          // statusValue = statusValue ? statusValue.toLowerCase() : "";
          // console.log(params.data.serviceId, params.value);
          let iconHtml = "";
          if (schemaVal === "") {
            iconHtml = `enabled<span title="disabled" class="xmmadminfloat-right-green"><i class="far fa-circle xmmadmin-green-indicator"></i></span>`;
          } else {
            iconHtml = `disabled<span title="enabled" class="xmmadminfloat-right-red"><i class="far fa-circle xmmadmin-red-indicator"></i></span>`;
          }
          return iconHtml;
        }
      },
      {
        headerName: "Schemas",
        field: "schemas",
        // tooltipField: "schemas",
        // tooltipComponentParams: { field: "schemas" },
        cellEditor: "schemaEditor",
        enableRowGroup: false,
        editable: true,
        width: 350,
        valueFormatter: schemaValueFormatter,
        // autoHeight: true,
        // cellStyle(params) {
        //   // console.log("days", params.value);
        //   if (params.schemas.value === "" || params.schemas.value === null) {
        //     // mark empty cells as red
        //     return { border: "1px solid #dd2b2b !important" };
        //   } else if (isArrayExist(params.value) && params.value.length === 0) {
        //     return { border: "1px solid #dd2b2b !important" };
        //   } else {
        //     return { border: "none" };
        //   }
        // },
        filterParams: {
          buttons: ["clear"]
        }
      },
      // {
      //   headerName: "Schemas",
      //   headerClass: "ag-text-header",
      //   field: "schemas",
      //   // eslint-disable-next-line no-constant-condition
      //   editable: true,
      //   cellEditor: "textEditor",
      //   cellClass: "editable-cell xmm-blue-cell xmm-wrap-cell",
      //   width: 350
      // },
      {
        headerName: "Dealers (For Testing only)",
        headerClass: "ag-text-header",
        field: "dealers",
        editable: true,
        cellEditor: "textEditor",
        cellClass: "editable-cell xmm-blue-cell xmm-wrap-cell",
        tooltipComponentParams: { field: "dealers" },
        width: 200
      },
      {
        headerName: "Last Modified By",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "modifiedBy",
        width: 216,
        editable: false
      },
      {
        headerName: "Last Modified Time",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "last_modified",
        width: 230,
        editable: false
      }
    ];
    return baseCols;
  }

  getActionColumnList() {
    const baseCols = [
      {
        headerName: "Event Type",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "event_type",
        width: 300,
        editable: false
      },
      {
        headerName: "Event Logs",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "eventMsg",
        width: 500,
        editable: false
      },
      {
        headerName: "Modified By",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "modifiedBy",
        width: 180,
        editable: false
      },
      {
        headerName: "Modified Date/Time",
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "mod_time",
        width: 200,
        editable: false
      }
    ];
    return baseCols;
  }

  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    console.log(params);
    console.log("onCellValueChanged", params.oldValue, params.newValue, params);
    if (
      toEmptyStringIfUndefined(params.oldValue) ===
      toEmptyStringIfUndefined(params.newValue)
    ) {
      // console.log("onCellValueChanged", params.colDef.field, params.data);
      // this.onSaveCellEdit(params);
      return;
    } else {
      if (params.colDef.field === "schemas") {
        const localSchemas = params.newValue;
        const schemaStringArray = localSchemas.map(item => item.value);
        const schemasString = schemaStringArray.join(",");

        params.data.schemas = schemasString;
      } else if (params.colDef.field === "dealers") {
        params.data.dealers = params.newValue;
      }
      // this.saveSingleSetting(params);
      // this.onSaveCellEdit(params);
    }
  }

  onSaveCellEdit(params) {
    this.saveSingleSetting(params, params.value);
  }

  saveSingleSetting(gridParams, value) {
    const userName = this.context.userName;
    showBodyMask();
    makeSecureRestApi(
      {
        url: `/opsadmin/xmmsettings/saveSetting/${userName}`,
        method: "post",
        data: gridParams.data,
        // params: payload,
        params: {}
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          // const filterList = data.filter(function(obj) {
          // return obj.pricingMethod === 1;
          // });
          console.log(data.settings);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
          this.gridApi.refreshCells({ force: true });
          toast.success("Settings Saved!!");
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  // bind to add rate click event
  saveSettings = event => {
    if (event) {
      event.preventDefault();
    }
    // const { toDealerName, fromDealerName } = this.state.formParams;
    const msg = `Do you want to save this settings? If you save this seetings, it will enable This settings will turn XMM maintenance mode on/off, and impact live traffic!`;
    this.setState({
      showCopyModal: true,
      copyConfirmMessage: msg
    });
  };

  // bind to add rate click event
  disabledAll = event => {
    if (event) {
      event.preventDefault();
    }
    // const { toDealerName, fromDealerName } = this.state.formParams;
    const msg = `Do you want to disable xmm maintenance mode for all client portal? This settings will turn XMM maintenance mode off.`;
    this.setState({
      showDisableAllModal: true,
      disableAllConfirmMessage: msg
    });
  };

  // bind to add rate click event
  enabledAll = event => {
    if (event) {
      event.preventDefault();
    }
    // const { toDealerName, fromDealerName } = this.state.formParams;
    const msg = `Do you want to enable xmm maintenance mode for all client portal? This settings will turn XMM maintenance mode on and impact live tarffic.`;
    this.setState({
      showEnableAllModal: true,
      enableAllConfirmMessage: msg
    });
  };

  getPostData(settings) {
    // add and edit menu type template have different field order for saving data
    const template = XMMAdminSettings;
    const xmmAdminSettingsPayload = Object.assign({}, template);
    const keys = Object.keys(settings);
    keys.forEach(key => {
      // xmmAdminSettingsPayload[key] = settings[key];
      // xmmAdminSettingsPayload.clientType = settings[key].clientType;
      // xmmAdminSettingsPayload.dealers = settings[key].dealers;
      // xmmAdminSettingsPayload.schemas = settings[key].schemas;
      xmmAdminSettingsPayload.settings = settings;
    });
    console.log(xmmAdminSettingsPayload);
    return xmmAdminSettingsPayload;
  }

  saveSettingHandler = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showCopyModal: false
    });
    const userName = this.context.userName;
    console.log(this.state.settings);
    showBodyMask();
    makeSecureRestApi(
      {
        url: `/opsadmin/xmmsettings/saveSettings/${userName}`,
        method: "post",
        data: this.state.settings,
        // params: payload,
        params: {}
      },
      data => {
        if (data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          // const filterList = data.filter(function(obj) {
          // return obj.pricingMethod === 1;
          // });
          console.log(data.settings);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
          this.gridApi.refreshCells({ force: true });
          toast.success("Settings Saved!!");
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  };

  saveEnableAllHandler = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showEnableAllModal: false
    });
    const settings = this.updateAllSchemas("ALL");
    this.setState({
      settings
    });
  };

  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }

  updateAllSchemas(value) {
    const { settings } = this.state;
    settings.forEach(tempSetting => {
      tempSetting.schemas = value;
    });
    this.gridApi.setRowData(settings);
    return settings;
  }

  saveDisableAllHandler = event => {
    if (event) {
      event.preventDefault();
    }
    const settings = this.updateAllSchemas("");
    this.setState({
      showDisableAllModal: false,
      settings
    });
  };

  closeSaveModel = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showCopyModal: false,
      copyConfirmMessage: ""
    });
  };

  closeEnableAllModel = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showEnableAllModal: false,
      enableAllConfirmMessage: ""
    });
  };

  closeDisableAllModel = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showDisableAllModal: false,
      disableAllConfirmMessage: ""
    });
  };

  setDisableAll = () => {
    for (let i = 0; i < this.state.settings.length; i++) {
      if (this.state.settings[i].schemas !== "") {
        return true;
      }
    }
    return false;
  };

  setEnabledAll = () => {
    for (let i = 0; i < this.state.settings.length; i++) {
      if (this.state.settings[i].schemas !== "") {
        return false;
      }
    }
    return true;
  };

  render() {
    const isDisabledAll = this.setDisableAll();
    const isEnabledAll = this.setEnabledAll();
    // const { localeStrings } = this.context;
    // const onToggleClick = cxEvent => {
    //   const checked = cxEvent.target.value === "checked";
    //   const tempSettings = this.state.settings;
    //   if (checked) {
    //     console.log("if block" + this.state.settings);
    //     // we can use for each loop
    //     tempSettings.forEach(tempSetting => {
    //       tempSetting.schemas = "ALL";
    //     });
    //     console.log("if block temp" + tempSettings);
    //     this.setState({
    //       settings: tempSettings
    //     });
    //     console.log("if block temp temp" + this.state.settings);
    //     // const listItems = tempSettings.map(
    //     //   setting => (setting.schemas = "ALL")
    //     // );
    //   } else {
    //     console.log(this.state.settings);
    //     tempSettings.forEach(tempSetting => {
    //       tempSetting.schemas = "";
    //     });
    //   }
    //   this.gridApi.refreshCells({ force: true });
    //   // console.log("toggle click", name, value, toggleStatus);
    // };
    const pricebyPopover = (
      <div>
        <b>{"When Globally Enabled"}</b>
        <p className="xmm-popover-text">
          {
            "When you toggle switch to XMM Maintenance Mode globally enable, it will set Schemas for all client Type to 'ALL', which means XMM will turn on for all clients."
          }
        </p>
        <b>{"Individual Row Edit"}</b>
        <p className="xmm-popover-text">
          {
            " You can turn on individual portal based on schemas, you need to enter comma seperated schema name like, X5,X7/ALL etc."
          }
        </p>
      </div>
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div
          id="summaryView"
          className="ag-grid-container ag-theme-balham xmm-admin"
        >
          <AgGridReact
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            rowData={this.state.settings}
            getRowNodeId={data => {
              return data.settingId;
            }}
            onGridReady={this.onGridReady}
            statusBar={this.state.statusBar}
            components={this.state.components}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowHeight={50}
            onCellValueChanged={this.onCellValueChanged}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
    );
    const actionGridWidget = (
      <div id="grid-wrapper">
        <div
          id="summaryView"
          className="ag-grid-container ag-theme-balham xmm-admin"
        >
          <AgGridReact
            columnDefs={this.state.actionColumnDefs}
            defaultColDef={this.state.eventDefaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            rowData={this.state.logevents}
            getRowNodeId={data => {
              return data.event_id;
            }}
            onGridReady={this.onEventLogGridReady}
            statusBar={this.state.statusBar}
            components={this.state.components}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            rowHeight={50}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
    );

    const popoverWidget = (
      <div>
        <Popover
          htmlId="tipPriceby"
          popoverContent={pricebyPopover}
          position="right"
          trigger={["click", "outsideClick"]}
        >
          <IconInfoOutline htmlId="IconPriceBy" />
        </Popover>
      </div>
    );

    const saveConfirmationDialog = (
      <Confirmation
        htmlId="saveSettingDialog"
        message={this.state.copyConfirmMessage}
        proceedButtonStyle="danger"
        show={this.state.showCopyModal}
        actionFunction={this.saveSettingHandler}
        closeDialog={this.closeSaveModel}
        titleId="Are you sure, you want to save this settings?"
      />
    );
    const enableAllConfirmationDialog = (
      <Confirmation
        htmlId="enableAllDialog"
        message={this.state.enableAllConfirmMessage}
        proceedButtonStyle="danger"
        show={this.state.showEnableAllModal}
        actionFunction={this.saveEnableAllHandler}
        closeDialog={this.closeEnableAllModel}
        titleId="Are you sure, you want to change this settings?"
      />
    );
    const disableAllConfirmationDialog = (
      <Confirmation
        htmlId="disableAllDialog"
        message={this.state.disableAllConfirmMessage}
        proceedButtonStyle="danger"
        show={this.state.showDisableAllModal}
        actionFunction={this.saveDisableAllHandler}
        closeDialog={this.closeDisableAllModel}
        titleId="Are you sure, you want to change this settings?"
      />
    );
    return (
      <div className="xmm-center-container">
        <div>
          <h2>{this.xmmadminAppsLabel}</h2>
        </div>

        <React.Fragment>
          <Row className="xmm-menu-type-header">
            <Col xs={1} md={2}>
              <div className="info-section">
                <h4>{"How it works?"}</h4>
                {popoverWidget}
              </div>
            </Col>
          </Row>
          <Row className="xmm-menu-type-header">
            <Col xs={2} md={2}>
              <h5>XMM Maintenance Mode</h5>
            </Col>
            <Col xs={1} md={0}>
              <Button
                htmlId="addActionBtn"
                disabled={!isEnabledAll}
                onClick={this.enabledAll}
                className="float-right"
              >
                {"Enable All"}
              </Button>
            </Col>
            <Col xs={1} md={0}>
              <Button
                htmlId="addActionBtn"
                disabled={!isDisabledAll}
                onClick={this.disabledAll}
                className="float-right"
              >
                {"Disable All"}
              </Button>
            </Col>
            <Col>
              <Button
                htmlId="addActionBtn"
                disabled={this.state.disableAction}
                onClick={this.saveSettings}
                className="float-right"
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
          {gridWidget}
          <Row className="xmm-menu-type-header">
            <Col xs={3} md={3}>
              <h4>Actions/log </h4>
            </Col>
          </Row>
          {actionGridWidget}
        </React.Fragment>
        {saveConfirmationDialog}
        {enableAllConfirmationDialog}
        {disableAllConfirmationDialog}
      </div>
    );
  }
}

function schemaValueFormatter(params) {
  const value = params.value;
  if (value && Array.isArray(value)) {
    const newValues = value.map(v => {
      return v.label;
    });
    return newValues;
  }
  return value;
}
