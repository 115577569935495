/* eslint-disable no-console */
/* This class WILL NOT be part of the npm package.
 It's merely here to run the component locally.
*/

import React from "react";
import PropTypes from "prop-types";
import { PreviewPane } from "./components/Preview";
import { initPreviewState } from "./components/preview-ops-context";
export default class CatalogPreview extends React.Component {
  state = {};
  render() {
    const {
      locale,
      serviceApi,
      savePreviewState,
      updateModalTitleWithVersion,
      appContext,
      previewState
    } = this.props;
    return (
      <React.Fragment>
        <div id="previewBody" className="ops-preview-pane">
          <PreviewPane
            locale={locale}
            serviceApi={serviceApi}
            appContext={appContext}
            previewState={previewState}
            savePreviewState={savePreviewState}
            updateModalTitleWithVersion={updateModalTitleWithVersion}
          />
        </div>
      </React.Fragment>
    );
  }
}

CatalogPreview.propTypes = {
  savePreviewState: PropTypes.func,
  updateModalTitleWithVersion: PropTypes.func,
  appContext: PropTypes.object,
  previewState: PropTypes.object,
  serviceApi: PropTypes.func,
  locale: PropTypes.string
};

CatalogPreview.defaultProps = {
  locale: "en_US",
  serviceApi: () => {
    // handle function here
  },
  savePreviewState: () => {
    // handle function here
  },
  previewState: initPreviewState
};
