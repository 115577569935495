/*
  for motor: "launchAir/motor"
  for dealerAdmin: "dealerAdmin"
*/
import appRoles from "./appRoles.json";

export function hasUserRole(pagePath, userRole) {
  const paths = pagePath.split("/");
  switch (paths.length) {
    case 1: {
      const tab = findTab(appRoles.tabs, paths[0]);
      if (tab) {
        if (tab.roles) {
          return tab.roles.indexOf(userRole) !== -1;
        } else {
          // user parent's roles
          return appRoles.roles && appRoles.roles.indexOf(userRole) !== -1;
        }
      }
      return false;
    }
    case 2: {
      const tab = findTab(appRoles.tabs, paths[0]);
      if (tab) {
        const subtab = findTab(tab.subTabs, paths[1]);
        if (subtab.roles) {
          return subtab.roles.indexOf(userRole) !== -1;
        } else if (tab.roles) {
          // user parent's roles
          return tab.roles.indexOf(userRole) !== -1;
        } else {
          // user parent's roles
          return appRoles.roles && appRoles.roles.indexOf(userRole) !== -1;
        }
      }
      return false;
    }
    default:
      break;
  }
  return false;
}

function findTab(tabs, tabName) {
  for (const tab of tabs) {
    if (tab.name === tabName) {
      return tab;
    }
  }
  return null;
}
