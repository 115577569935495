const KEY_C = 67;
const KEY_V = 86;
const KEY_X = 88;

export const isFun = fname => {
  return typeof fname === "function";
};

export const isTrue = (value = "false") => {
  if (value === true) {
    return true;
  }
  if (value === null || value === undefined || value === false) {
    return false;
  }
  const valueStr = typeof value === "number" ? value.toString() : value;
  switch (valueStr.toLowerCase()) {
    case "true":
    case "yes":
    case "y":
    case "1":
    case "enabled":
      return true;
    default:
      return false;
  }
};

export const undefinedToTrue = value => {
  return value === undefined ? true : value;
};

export const isObjEmpty = obj => {
  return JSON.stringify(obj) === "{}";
};

export const parseBool = str => {
  // const boolVal = str.toLowerCase() == "true" ? true : false;
  return JSON.parse(str); // returns true for "true"
};

export const isAllDigits = str => {
  return /^\d+$/.test(str);
};

export const isPriceInput = str => {
  return /^\d+(?:\.\d{0,2})$/.test(str) || isAllDigits(str);
};

export const isFloatingNumber = str => {
  return /^\d*(?:\.\d{0,2})$/.test(str) || isAllDigits(str);
};

export const isFloatingNumberWithTenths = str => {
  return /^\d*(?:\.\d{0,1})$/.test(str) || isAllDigits(str);
};

export const isCopyAndPaste = event => {
  const evt = event || window.event; // IE support
  const c = evt.keyCode;
  const ctrlDown = evt.ctrlKey || evt.metaKey; // Mac support

  // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
  // if (ctrlDown && evt.altKey) return true
  // Check for ctrl+c, v and x
  if (ctrlDown && (c === KEY_C || c === KEY_V || c === KEY_X)) {
    return true;
  }
  return false;
};

export const defaultToZeroIfNull = value => {
  return value ? value : 0;
};

export const defaultToZeroIfNullOrEmpty = value => {
  return !value ? 0 : value;
};

export const toNumber = value => {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    return parseInt(value, 10);
  }
  return NaN;
};
export const toFloat = value => {
  if (typeof value === "number") {
    return value;
  } else if (typeof value === "string") {
    return parseFloat(value);
  }
  return NaN;
};

export function convertMinutesToTenths(timeParam) {
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time /= 60;
    time *= 10;
    time = Math.ceil(time);
    time /= 10;
    return time;
  }
  return 0;
}
export function convertMinutesToHundredths(timeParam) {
  /*
   * example:
   * 8.4/60 = 0.14
   * 0.14 * 100 = 14.000000000000002
   * 14.000000000000002 toFixed(1) becomes 14.0
   * Math.ceil(14.0) becomes 14
   * 14/100 = 0.14
   * 0.14.toFixed(2) becomes 0.14
   * 0.14 is returned
   *
   * */
  let time = 0;
  if (timeParam) {
    time = timeParam;
    time /= 60;
    time *= 100;
    /* just one decimal is enough to take ceiling */
    time = time.toFixed(1);
    time = Math.ceil(time);
    time /= 100;
    time = time.toFixed(2);
    return time;
  }
  return 0;
}

/**
 * Corrects floating point numbers that overflow to a non-precise
 * value because of their floating nature, for example `0.1 + 0.2`
 * @param {Number} number
 * @return {Number} The correctly rounded number
 */
export function correctFloat(n) {
  // This is to correct the type of errors where 2 floats end with
  // a long string of decimals, eg 0.1 + 0.2. When they overflow in this
  // manner, they usually go to 15-16 decimals, so we cut it off at 14.
  return parseFloat(n.toPrecision(14));
}

/**
 * Formats a number using fixed-point notation
 * @param {Number} value The number to format
 * @param {Number} precision The number of digits to show after the decimal point
 */
export function toFixed(value, precision) {
  let localPrecision = 2;
  if (!precision) {
    return value.toFixed(localPrecision);
  } else {
    localPrecision = precision || 0;
  }
  const pow = Math.pow(10, localPrecision);
  return (Math.round(value * pow) / pow).toFixed(localPrecision);
}

export function isLargerThan(a, b) {
  return Number(a) > Number(b);
}

export function round(num) {
  return Math.round(num * 100) / 100;
}
