import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import Label from "@cx/ui/internal/Label";
import { Picky } from "react-picky";
// TODO: We can't use direct CSS from react-picky at this time due to
// CX3 CSS bundling/chunk bug on build
// import "react-picky/dist/picky.css";
import "./xmmPickyCustom.scss";
// import { xlate } from "../../commonUtil/i18n/locales";

const PickyFilter = ({
  disabled,
  dropdownHeight,
  htmlId,
  includeFilter,
  includeSelectAll,
  label,
  multiple,
  name,
  initialValue,
  keepOpen,
  onChange,
  options,
  placeholder,
  numberDisplayed
}) => {
  const [selected, setSelected] = useState(initialValue);

  useEffect(() => {
    // if (!initialValue[0]) setSelected(options);
    setSelected(initialValue);
  }, [initialValue, options]);

  const onPickySelect = value => {
    const requestedValue = value;
    onChange(requestedValue); // Pass empty value for simpler paramString in api call
    setSelected(value);
    // const valueHasChanged = JSON.stringify(value) !== JSON.stringify(selected);
    // if (valueHasChanged) {
    //   const requestedValue = value.length === options.length ? [] : value;
    //   onChange(requestedValue); // Pass empty value for simpler paramString in api call
    //   setSelected(value);
    // }
  };

  const noLabel = label === "" || label === undefined || label === null;
  const labelClass = noLabel ? "hide" : "";
  // const allLabel = xlate("xmm.portal.common.all_lbl")
  const allLabel = "All".concat(" ").concat(label);
  const iconClassNames = noLabel
    ? "pickyMultiSelect-dropdown-no-label-icon"
    : "pickyMultiSelect-dropdown-icon";

  return (
    <div
      id={htmlId}
      className={classNames([
        "input-wrapper",
        "pickyFilter-inputWrapper",
        "agentFilter-inputWrapper__formGroup",
        "form-group"
      ])}
    >
      <div className={labelClass}>
        <Label htmlId={`${htmlId}-label`}>{label}</Label>
      </div>
      <div>
        <Picky
          allSelectedPlaceholder={allLabel}
          buttonProps={{
            className: "form-control",
            "aria-label": `${name}-btn-dropdown`
          }}
          className={`${name}-btn-dropdown`}
          clearFilterOnClose
          disabled={disabled}
          dropdownHeight={dropdownHeight}
          id={name}
          includeSelectAll={includeSelectAll}
          includeFilter={includeFilter}
          keepOpen={keepOpen}
          multiple={multiple}
          name={name}
          numberDisplayed={numberDisplayed}
          onChange={onPickySelect}
          options={options}
          placeholder={placeholder}
          selectAllText={allLabel}
          labelKey="label"
          valueKey="value"
          value={selected}
        />
        <IconKeyboardArrowDown
          htmlId={`${htmlId}-dropdown-icon`}
          className={classNames([`${iconClassNames}`, "select-dropdown-icon"])}
        />
      </div>
    </div>
  );
};

PickyFilter.propTypes = {
  disabled: PropTypes.bool,
  dropdownHeight: PropTypes.number,
  htmlId: PropTypes.string.isRequired,
  includeFilter: PropTypes.bool.isRequired,
  includeSelectAll: PropTypes.bool.isRequired,
  initialValue: PropTypes.array,
  keepOpen: PropTypes.bool,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  numberDisplayed: PropTypes.number
};

PickyFilter.defaultProps = {
  disabled: false,
  dropdownHeight: 600,
  includeSelectAll: true,
  includeFilter: true,
  initialValue: [],
  keepOpen: false,
  multiple: true,
  numberDisplayed: 2
};

export default PickyFilter;
