/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../components/app-context";
import Button from "@cx/ui/Button";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { toast } from "@cx/ui/Toast";
import TextInput from "@cx/ui/TextInput";
import IconDelete from "@cx/ui/Icons/IconDelete";
import CustomLoadingOverlay from "./../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../api/xmmAxios";

import Confirmation from "../../../commonUtil/dialog/Confirmation";
import StatusBox from "../../../commonUtil/components/templates/StatusBox";
import { AgGridReact } from "ag-grid-react";
import { applyCustomKeyNavigation } from "../../../commonUtil/utils/keyNavigation";

class MainPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.onGridReady = this.onGridReady.bind(this);
    this.setAutoHeight = this.setAutoHeight.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.onChangeVendor = this.onChangeVendor.bind(this);
    this.renderDeleteButton = this.renderDeleteButton.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.handleSaveVendor = this.handleSaveVendor.bind(this);
    const localeStrings = context.localeStrings;
    const gridOptions = {
      // other state props
      pageTitle: "Vendors",
      vendor: "",
      showDeleteConfirmation: false,
      // ag-grid props
      rowData: null, // should be null - fix to skip "No records found" msg on grid load.
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        // cellClass: "xmm-wrap-cell",
        // autoHeight: true,
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        suppressMenu: false,
        sortingOrder: ["asc", "desc", null],
        filter: false,
        filterParams: {
          buttons: ["clear"]
        },
        floatingFilter: false, // true - enable column header filters
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      // multiSortKey: "ctrl",
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },

      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: {
        filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
        selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
        totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
        totalAndFilteredRows:
          localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
        noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      rowSelection: "single",
      sideBar: {
        hiddenByDefault: true
      }
    };
    this.state = gridOptions;
  }
  componentDidMount() {
    this.loadGridData();
  }

  componentWillUnmount() {}

  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  callRefreshAfterMillis(params, gridApi) {
    gridApi.redrawRows(params);
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 30);
  }

  getColumnList() {
    const baseCols = [
      {
        headerName: "Vendor",
        headerClass: "ag-text-header",
        field: "vendor"
      },
      {
        headerName: "",
        field: "",
        cellEditorParams: {
          parentHandle: this
        },
        hide: true,
        cellRendererFramework: this.renderDeleteButton,
        minWidth: 61,
        maxWidth: 61,
        width: 61,
        filter: false,
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressColumnsToolPanel: true
      }
    ];
    return baseCols;
  }
  renderDeleteButton(params) {
    const vendor = params.data;
    return (
      <IconDelete
        className="xmm-rule-dropdown-menu-renderer"
        onClick={() => {
          this.confirmDeleteVendor(vendor);
        }}
      />
    );
  }
  renderDeleteVendorConfirmation() {
    const { vendor, showDeleteConfirmation } = this.state;
    const { localeStrings } = this.context;
    return showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteVendor"
        message={localeStrings["xmmadmin.portal.vendor.confirm_delete"]}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.handleDeleteVendor(vendor);
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
  }
  confirmDeleteVendor = vendor => {
    this.setState({ vendor, showDeleteConfirmation: true });
  };
  handleDeleteVendor(vendor) {
    const { localeStrings } = this.context;
    this.updateStatusBox(
      localeStrings["xmm.portal.common.deleting"],
      "pending",
      false
    );
    const params = { vendor: vendor.vendor };
    const url = "/opsadmin/proxyapi/ddsproxy/rest/proc/deleteWarrantyVendor";
    makeSecureRestApi(
      {
        url,
        method: "get",
        params
      },
      () => {
        const res = this.gridApi.applyTransaction({
          remove: [vendor]
        });
        this.updateStatusBox(
          localeStrings["xmm.portal.common.deleted"],
          "success",
          true
        );
        this.setState({ showDeleteConfirmation: false });

        const rowNode = res.remove[0];
        const gridParams = {
          force: true,
          rowNodes: [rowNode]
        };
        this.callRefreshAfterMillis(gridParams, this.gridApi);
      },
      error => {
        this.closeDeleteModal();
        const msg = error["message"] ? error.message : this.deletingErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  handleSaveVendor() {
    const { vendor, rowData } = this.state;
    const { userName, localeStrings } = this.context;
    this.updateStatusBox(
      localeStrings["xmm.portal.common.saving"],
      "pending",
      false
    );
    const params = { vendor, modUser: userName };
    const url = "/opsadmin/proxyapi/ddsproxy/rest/proc/addWarrantyVendor";
    makeSecureRestApi(
      {
        url,
        method: "get",
        params
      },
      response => {
        if (response[0]["'added'"]) {
          const res = this.gridApi.applyTransaction({
            add: [{ vendor }]
          });
          this.updateStatusBox(
            localeStrings["xmm.portal.common.saved"],
            "success",
            true
          );
          const rowNode = res.add[0];
          rowData.push({ vendor });
          this.setState({ rowData, showDeleteConfirmation: false });
          this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
          rowNode.setSelected(false);
          const gridParams = {
            force: true,
            rowNodes: [rowNode]
          };
          this.callRefreshAfterMillis(gridParams, this.gridApi);
        } else if (response[0]["'duplicatedetected'"]) {
          this.updateStatusBox(
            localeStrings["xmmadmin.portal.vendor.duplicate_msg"],
            "error",
            true
          );
        }
      },
      error => {
        const msg = error["message"] ? error.message : this.deletingErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }
  closeDeleteModal() {
    this.setState({ showDeleteConfirmation: false });
  }
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    setTimeout(() => {
      this.setAutoHeight();
    }, 0);
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  setAutoHeight = () => {
    this.sizeToFit();
  };
  handleGridSizeChanged = () => {
    this.sizeToFit();
    this.setAutoHeight();
  };

  getRowNodeId(data) {
    return data.vendor;
  }
  loadGridData() {
    const restUrl = "/opsadmin/proxyapi/ddsproxy/rest/table/warrantyVendor";

    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          console.log(data);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  updateState(data) {
    const vendors = Array.isArray(data) ? data : [];
    this.setState(
      {
        rowData: vendors
      },
      () => {
        setTimeout(() => {
          this.sizeToFit();
        }, 0);
        this.setAutoHeight();
      }
    );
  }
  onChangeVendor = (cxEvent, isValid) => {
    const { value } = cxEvent.target;
    this.setState({
      vendor: value
    });
  };

  render() {
    const deleteModal = this.renderDeleteVendorConfirmation();
    const { vendor } = this.state;
    const gridClassName = "ag-grid-container ag-theme-balham vendor-grid";
    const tooltipInfo = (
      <div>
        <p className="xmm-popover-text">
          {
            this.context.localeStrings[
              "xmmadmin.portal.version.create_vendor_tip"
            ]
          }
        </p>
      </div>
    );
    const createVendorPopover = (
      <div>
        <TextInput
          htmlId="inputVendor"
          label="Vendor Name"
          placeholder="Enter a new vendor"
          name="inputVendor"
          className="xmm-md-input"
          required
          onChange={this.onChangeVendor}
        />
        <Button
          htmlId="createNewVendor"
          buttonStyle="secondary"
          size="small"
          block
          onClick={this.handleSaveVendor}
          disabled={!vendor}
        >
          {"Create Vendor"}
        </Button>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const header = (
      <React.Fragment>
        <div className="content-header">
          <div className="xmm-main-title">
            <h3>{this.state.pageTitle}</h3>
          </div>
        </div>
        <div className="content-header">
          <div>
            <Popover
              htmlId="createVendorPopover"
              popoverContent={createVendorPopover}
              position="right"
              trigger={["click", "outsideClick"]}
            >
              <Button htmlId="createVendor" buttonStyle="primary">
                {"Create Vendor"}
              </Button>
            </Popover>
            <Popover
              htmlId="tooltipVendorInfo"
              popoverContent={tooltipInfo}
              position="right"
              trigger={["click", "outsideClick"]}
            >
              <IconInfoOutline className="info-blue" />
            </Popover>
          </div>
          <div className="float-right">{statusHtml}</div>
        </div>
      </React.Fragment>
    );
    const gridWidget = (
      <div id="grid-wrapper">
        <div id="vendorGrid" className={gridClassName}>
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressMenuHide={false}
            getRowNodeId={this.getRowNodeId}
            rowData={this.state.rowData}
            rowSelection="single"
            singleClickEdit={true}
            stopEditingWhenGridLosesFocus={true}
            animateRows={true}
            statusBar={this.state.statusBar}
            sideBar={this.state.sideBar}
            enableRangeSelection={true}
            enableCellTextSelection={false}
            enableBrowserTooltips={true}
            onCellValueChanged={this.onCellValueChanged}
            onFilterChanged={this.onFilterChanged}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            frameworkComponents={this.state.frameworkComponents}
            domLayout={this.state.domLayout}
          />
        </div>
      </div>
    );

    return (
      <div className="xmm-center-container">
        {header}
        {gridWidget}
        {deleteModal}
      </div>
    );
  }
}

export default MainPage;

MainPage.propTypes = {};
