/* eslint-disable no-console */
import React from "react";
import Navbar from "react-bootstrap/lib/Navbar";
import Nav from "react-bootstrap/lib/Nav";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import MenuItem from "react-bootstrap/lib/MenuItem";
import IconHelpOutline from "@cx/ui/Icons/IconHelpOutline";
import IconSettings from "@cx/ui/Icons/IconSettings";
import IconPeople from "@cx/ui/Icons/IconPeople";
import { AppContext, AppConsumer } from "../app-context";
import { getLoginUrl } from "../../api/xmmAxios";
import { PropTypes } from "prop-types";
import AboutUsDialog from "../Header/AboutUsDialog";
import ManageCategoryGroupDialog from "../Header/ManageCategoryGroupDialog";
import UserManagementDialog from "../Header/UserManagementDialog";
import { doesEmpty, isEmpty } from "../../commonUtil/utils/object";
import { hasUserRole } from "../../role/roleUtil";

export default class Header extends React.Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.userName !== prevState.userName) {
      return { userName: nextProps.userName };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);

    const { baseLocale, locale, role } = context;
    const manageCategoryGroupEnabled = hasUserRole(
      "settings/manageCategoryGroups",
      role
    );
    const settingsEnabled = hasUserRole("settings", role);

    let code = "EN";
    if (!doesEmpty(locale)) {
      console.log("Header locale", baseLocale, locale);
      const lang = locale.split("_");
      code = lang[0].toUpperCase();
    }
    const { userName } = this.props;

    this.state = {
      isActive: false,
      locale,
      languageCode: code,
      manageCategoryGroupEnabled,
      settingsEnabled,
      userName
    };
    // This binding is necessary to make `this` work in the callback
    this.openAboutUsDialog = this.openAboutUsDialog.bind(this);
    this.closeAboutUsDialog = this.closeAboutUsDialog.bind(this);
    this.openManageCategoryGroupModal =
      this.openManageCategoryGroupModal.bind(this);
    this.closeManageCategoryGroupModal =
      this.closeManageCategoryGroupModal.bind(this);
    this.openUserManagementDialog = this.openUserManagementDialog.bind(this);
    this.closeUserManagementDialog = this.closeUserManagementDialog.bind(this);
    this.openTrainingPage = this.openTrainingPage.bind(this);
    this.openSupportPage = this.openSupportPage.bind(this);
    this.toggleLang = this.toggleLang.bind(this);
  }

  /**
   * Add event listener
   */
  componentDidMount() {}

  openAboutUsDialog = event => {
    this.setState({
      showAboutUsDialog: true
    });
  };

  openUserManagementDialog = event => {
    this.setState({
      showUserManagementDialog: true
    });
  };

  closeUserManagementDialog = event => {
    this.setState({ showUserManagementDialog: false });
  };

  closeAboutUsDialog = event => {
    this.setState({ showAboutUsDialog: false });
  };
  openManageCategoryGroupModal = event => {
    const { manageCategoryGroupEnabled } = this.state;
    if (manageCategoryGroupEnabled) {
      this.setState({
        showManageCategoryGroupModal: true
      });
    }
  };

  closeManageCategoryGroupModal = event => {
    this.setState({ showManageCategoryGroupModal: false });
  };

  openTrainingPage = event => {
    const loginUrl = getLoginUrl() + "?pt=ht";
    // target window reference
    let winRef = null;
    if (winRef && !winRef.closed) {
      // Window is open, refresh it
      winRef.location.reload(true);
    } else {
      winRef = window.open(loginUrl, "_XtimeUniversity");
    }
  };
  openSupportPage = event => {
    const { locale } = this.context;
    const userLocale = locale.replace("_", "-").toLowerCase();
    const supportUrl =
      "https://support.xtime.com/hc/" +
      userLocale +
      "/requests/new?ticket_form_id=4734";

    // target window reference
    let winRef = null;
    if (winRef && !winRef.closed) {
      // Window is open, refresh it
      winRef.location.reload(true);
    } else {
      winRef = window.open(supportUrl, "_CustomerSupport");
    }
  };
  /* This will set selected locale to App context and reload page */
  toggleLang = (event, locale) => {
    const { reloadPageWithLocale } = this.context;
    const lang = locale.split("_");
    const languageCode = lang[0].toUpperCase();
    this.setState({
      languageCode
    });
    reloadPageWithLocale(locale);
  };
  renderMenuLocales = () => {
    const { languages } = this.context;
    const items = [];
    if (!isEmpty(languages) && languages.length > 1) {
      languages.forEach((obj, index) => {
        items.push(
          <MenuItem
            key={obj.value}
            onClick={event => {
              event.preventDefault();
              this.toggleLang(event, obj.value);
            }}
          >
            {obj.label}
          </MenuItem>
        );
      });
    }
    // console.log("render", languages);
    return items;
  };
  render() {
    const { localeStrings } = this.context;
    const { manageCategoryGroupEnabled, settingsEnabled, userName } =
      this.state;
    // const clsHideMenu =
    //   isEmpty(languages) || languages.length === 1
    //     ? "navbar-icon-caret no-dropdown"
    //     : "navbar-icon-caret";
    const aboutUsModal = (
      <AboutUsDialog
        show={this.state.showAboutUsDialog}
        closeDialog={this.closeAboutUsDialog}
      />
    );
    const ManageCategoryGroupModal = (
      <ManageCategoryGroupDialog
        show={this.state.showManageCategoryGroupModal}
        closeDialog={this.closeManageCategoryGroupModal}
      />
    );
    const UserManagementModal = (
      <UserManagementDialog
        show={this.state.showUserManagementDialog}
        closeDialog={this.closeUserManagementDialog}
      />
    );
    const navDropdownHelp = <IconHelpOutline className="header-icon" />;
    const navDropdownSettings = <IconSettings className="header-icon" />;
    const peopleLink = <IconPeople className="header-icon hand-cursor" />;
    const peopleNav = settingsEnabled ? (
      <NavDropdown
        title={peopleLink}
        id="user-nav-dropdown"
        className="navbar-dropdown-icon"
        onClick={this.openUserManagementDialog}
      />
    ) : (
      ""
    );
    const settingsNav = settingsEnabled ? (
      <NavDropdown
        title={navDropdownSettings}
        id="help-nav-dropdown"
        className="navbar-dropdown-icon"
        onToggle={() => {
          /* Do nothing. This prop must be declared to supress a warning. */
        }}
      >
        <MenuItem
          onClick={this.openManageCategoryGroupModal}
          // active={manageCategoryGroupEnabled}
          disabled={!manageCategoryGroupEnabled}
        >
          {localeStrings["xmm.portal.nav.settings.manage_categories"]}
        </MenuItem>
      </NavDropdown>
    ) : (
      ""
    );
    // const navDropdownLanguage = (
    //   <div>
    //     <span className="nav-language-text">{this.state.languageCode}</span>
    //     <IconPublic className="header-icon" />{" "}
    //   </div>
    // );
    return (
      <AppConsumer>
        {context => (
          <Navbar fluid={true} staticTop={true} className="header-bar">
            <Navbar.Header className="header-bar__nav-header">
              <Navbar.Brand className="header-bar__nav-brand">
                <img
                  alt=""
                  className="header-bar__nav-logo"
                  src={require("../../images/xtime-logo-small.png")}
                />
                {window.location.pathname === "/xmmAdmin"
                  ? localeStrings["xmmadmin.portal.common.xmm_name"]
                  : localeStrings["xmmadmin.portal.common.app_name"]}
              </Navbar.Brand>
            </Navbar.Header>
            <Navbar.Collapse>
              <div className="float-right user-name-block">
                {userName} &nbsp;
              </div>
              <Nav className="icon-nav">
                {peopleNav}
                {settingsNav}
                <NavDropdown
                  title={navDropdownHelp}
                  id="help-nav-dropdown"
                  className="navbar-dropdown-icon"
                  onToggle={() => {
                    /* Do nothing. This prop must be declared to supress a warning. */
                  }}
                >
                  <MenuItem onClick={this.openAboutUsDialog}>
                    {localeStrings["xmm.portal.nav.about"]}
                  </MenuItem>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
            {aboutUsModal}
            {ManageCategoryGroupModal}
            {UserManagementModal}
          </Navbar>
        )}
      </AppConsumer>
    );
  }
}

Header.propTypes = {
  userName: PropTypes.string
};
