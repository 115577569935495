import React from "react";
import { PropTypes } from "prop-types";
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";
import { LoginCallback, Security } from "@okta/okta-react";
import { islocalhost } from "../api/xmmAxios";

// PROD only - copy okta related values here
let issuerUrl = "https://coxauto.okta.com/oauth2/default";
let clientId = "0oa1ji10ssplCbkx40h8";

if (islocalhost) {
  // david.lee's local setup
  issuerUrl = "https://dev-430670.okta.com/oauth2/default";
  clientId = "0oax2gkh72cogcqEj4x6";
}
const redirectUrl = `${window.location.origin}/loginCallback`;
const Root = ({ store }) => (
  <Security
    issuer={issuerUrl}
    clientId={clientId}
    redirectUri={redirectUrl}
    pkce={true}
  >
    <BrowserRouter>
      <Route path="/loginCallback" component={LoginCallback} />
      <App />
    </BrowserRouter>
  </Security>
);

export default Root;

Root.propTypes = {
  store: PropTypes.object
};
