/* eslint-disable no-console */
import React, { Component } from "react";
import { bool, PropTypes } from "prop-types";
import { AppContext } from "../../components/app-context";
import ModalDialog from "@cx/ui/ModalDialog";
import CustomLoadingOverlay from "../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import TextEditor from "../../commonUtil/editors/TextEditor";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import { toast } from "@cx/ui/Toast";
import { loadAgGridLocale } from "../../i18n/LocaleSender";
import { AgGridReact } from "ag-grid-react";
import {
  makeSecureRestApi,
  namespace,
  showBodyMask,
  hideBodyMask
} from "../../api/xmmAxios";
import { isDifferentValue } from "../../commonUtil/utils/string";
import { applyCustomKeyNavigation } from "../../commonUtil/utils/keyNavigation";

/** Header */
export default class ManageCategoryGroupDialog extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onExportMappings = this.onExportMappings.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    const localeStrings = context.localeStrings;
    const gridOptions = {
      columnDefs: [], // this.getColumnList(localeStrings),
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false,
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        suppressKeyboardEvent: applyCustomKeyNavigation,
        minWidth: 120,
        // autoHeight: true,
        filter: true,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      rowData: [],
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        textEditor: TextEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      columnTypes: {
        nonEditableColumn: { editable: false }
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: false
    };
    this.state = gridOptions;
  }
  componentDidMount() {
    if (namespace && namespace.indexOf("aus") === -1) {
      this.loadGridData();
      this.loadCategoryGroups();
    }
  }
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };

  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  };
  handleGridSizeChanged = () => {
    this.sizeToFit();
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      this.gridApi.setQuickFilter(
        document.querySelector("#page-search-box").value
      );
      // this.clearGridSelections();
    }
  };
  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.serviceCategoryId;
  }

  getColumnList(localeStrings, categoryGroupsMap) {
    const baseCols = [
      {
        headerName: localeStrings["xmmadmin.portal.nav.settings.category_name"],
        headerClass: "ag-text-header",
        field: "serviceCategoryName",
        sortingOrder: ["asc", "desc"],
        filter: "agTextColumnFilter",
        // width: 250,
        minWidth: 250,
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmmadmin.portal.nav.settings.category_id"],
        headerClass: "ag-text-header",
        field: "serviceCategoryId",
        editable: false,
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
        filterParams: {
          buttons: ["clear"]
        },
        sortingOrder: ["asc", "desc"],
        width: 120,
        minWidth: 120
      },
      {
        headerName:
          localeStrings["xmmadmin.portal.nav.settings.category_group"],
        headerClass: "ag-text-header",
        field: "categoryGroupId",
        editable: true,
        // cellEditor: "textEditor",
        // cellEditorParams: {
        //   maxLength: 32,
        //   cellHeight: 25
        // },
        // cellClass: "editable-cell xmm-blue-cell",
        cellClass: "editable-caret-cell",
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          values: Object.keys(categoryGroupsMap),
          cellHeight: 25
        },
        refData: categoryGroupsMap,
        filter: "agSetColumnFilter",
        suppressSizeToFit: true,
        filterParams: {
          buttons: ["clear"]
        },
        sortingOrder: ["asc", "desc"],
        // width: 300,
        minWidth: 200,
        maxWidth: 200
      },
      {
        valueFormatter(params) {
          return params.value && params.value.synonyms
            ? params.value.synonyms
            : params.value || "";
        },
        filter: "agTextColumnFilter",
        filterParams: { buttons: ["clear"] },
        headerName: localeStrings["xmmadmin.portal.nav.settings.Synonyms"],
        field: "synonyms",
        valueGetter: synonymsGetter,
        editable: true,
        cellClass: "editable-cell"
      }
    ];
    return baseCols;
  }
  loadGridData() {
    // @note: this is the one that Inspect and Service quoting can use
    // const restUrl = "/opsadmin/servicecategory/list";
    const restUrl = "/opsadmin/rest-db/getValues/serviceCategoryGroups";

    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          console.log(data);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          const serviceCategoryGroupsMap = {};
          data.forEach(scg => {
            serviceCategoryGroupsMap[scg.serviceCategoryId.toString()] = scg;
            scg.synonyms = scg.synonyms ? scg.synonyms.synonyms : [];
          });
          this.context.saveServiceCategoryGroupsMap(serviceCategoryGroupsMap);
          this.setState({ rowData: data, serviceCategoryGroupsMap }, () => {
            this.sizeToFit();
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching service category group mappings.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  loadCategoryGroups() {
    const { categoryGroups, categoryGroupsMap, localeStrings } = this.context;
    if (categoryGroups && categoryGroups.length !== 0) {
      this.setState({
        categoryGroups,
        categoryGroupsMap,
        columnDefs: this.getColumnList(localeStrings, categoryGroupsMap)
      });
      return;
    }
    // Point to static Json file
    // const restUrl = window.origin + "/data/getAllDealerOperations.json";
    const restUrl = "/opsadmin/rest-db/getValues/categoryGroups";

    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      categoryGroups => {
        if (categoryGroups && Object.keys(categoryGroups).length !== 0) {
          console.log(categoryGroups);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          const categoryGroupsMap = {};
          categoryGroups.forEach(c => {
            const { id, name } = c;
            categoryGroupsMap[id.toString()] = name;
          });
          this.context.saveCategoryGroupMappings(
            categoryGroups,
            categoryGroupsMap
          );
          this.setState({
            categoryGroups,
            categoryGroupsMap,
            columnDefs: this.getColumnList(localeStrings, categoryGroupsMap)
          });
        } else {
          toast.error("Missing Category Groups.");
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching service category group mappings.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  onCellValueChanged(params) {
    const { oldValue, newValue } = params;
    if (isDifferentValue(oldValue, newValue)) {
      this.onSaveCellEdit(params);
    }
  }
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  onSaveCellEdit(params) {
    const restAPI =
      params.column.colId === "synonyms"
        ? "/opsadmin/rest-db/updateTableData/serviceCategoryGroup"
        : "/opsadmin/rest-db/function/upsertServiceCategoryGroup";

    const { serviceCategoryId, categoryGroupId } = params.data;
    const { categoryGroupsMap, serviceCategoryGroupsMap } = this.state;
    const serviceCategoryGroup =
      serviceCategoryGroupsMap[serviceCategoryId.toString()];
    serviceCategoryGroup.categoryGroupName =
      categoryGroupsMap[categoryGroupId.toString()];
    const parameters =
      params.column.colId === "synonyms"
        ? {
            id: params.data.serviceCategoryId,
            synonyms: this.arrayList(params.data.synonyms)
          }
        : { serviceCategoryId, categoryGroupId };
    makeSecureRestApi(
      {
        url: restAPI,
        method: "post",
        data: parameters
      },
      response => {
        console.log(response);
        // const copyOperation = response ? response["copy_operation"] : null;
        // if (copyOperation) {
        //   const { value } = copyOperation;
        //   const rowRecord = JSON.parse(value);
        //   if (rowRecord.hasOwnProperty("errorCode")) {
        //     const { errorMessage } = rowRecord;
        //     toast.error(errorMessage, {
        //       closeOnClick: true
        //     });
        //     return;
        //   }
        //   rowRecord.id = rowRecord.operationId.toString();
        //   if (rowRecord.labels) {
        //     const { labels } = rowRecord.labels;
        //     if (labels && Array.isArray(labels)) {
        //       rowRecord.labels = labels;
        //     } else {
        //       rowRecord.labels = [];
        //     }
        //   } else {
        //     rowRecord.labels = [];
        //   }
        //   const newRecord = JSON.parse(JSON.stringify(rowRecord));
        //   const { updateGridAfterSave } = this.props;
        //   updateGridAfterSave(rowRecord, "add");
        //   this.setState({ rowRecord, newRecord });
        // }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "Error while saving changes";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  arrayList = value => {
    if (value && typeof value === "string") {
      return value.split(",").map(v => {
        return v.trim();
      });
    }
    return value;
  };
  onExportMappings = () => {
    // const { dealerCode } = this.context;
    // const columnKeys = [];
    // this.gridColumnApi.getAllDisplayedColumns().forEach(col => {
    //   if (col.colId !== "checked") {
    //     columnKeys.push(col.colId);
    //   }
    // });
    const params = {
      // columnKeys,
      fileName: `Export_Category_Group_Mappings`,
      sheetName: `Category Group Mappings`,
      processCellCallback: params => {
        console.log(params);
        const { column, node } = params;
        if (column.colId === "categoryGroupId") {
          return node.data.categoryGroupName;
        }
        return params.value;
      }
    };
    this.gridApi.exportDataAsExcel(params);
    // this.gridApi.exportDataAsExcel();
  };

  render() {
    const {
      localeStrings
      // languages
    } = this.context;
    const header = (
      <React.Fragment>
        <Dropdown
          id="toolsDropdown"
          htmlId="toolsDropdown"
          name="toolsDropdown"
          // className="xmm-dotted-dropdown btn--icon"
          buttonStyle="link"
          displayCaret={false}
          size="small"
          // disabled={disableCopyOperation}
          options={[
            {
              label: "Export to Excel",
              value: "exportCategoryGroupMappings",
              onSelect: this.onExportMappings
            }
          ]}
          pullRight
        >
          <IconMore />
        </Dropdown>
        <div className="xmm-input-search float-right">
          <input
            type="text"
            id="page-search-box"
            className="xmm-input"
            placeholder={"Search"}
            onInput={this.onSearchBoxChanged}
            autoComplete="off"
          />
        </div>
      </React.Fragment>
    );
    const gridWidget = (
      <div
        id="manageDealerOpcodesGrid"
        className="xmm-category-group-grid-container ag-theme-balham"
      >
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressMenuHide={false}
          suppressContextMenu={true}
          rowData={this.state.rowData}
          rowSelection={"single"}
          animateRows={true}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          loadingOverlayComponent={this.state.loadingOverlayComponent}
          loadingOverlayComponentParams={
            this.state.loadingOverlayComponentParams
          }
          noRowsOverlayComponent={this.state.noRowsOverlayComponent}
          noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
          statusBar={this.state.statusBar}
          components={this.state.components}
          singleClickEdit={true}
          stopEditingWhenGridLosesFocus={true}
          onColumnResized={this.handleColumnResized}
          onCellValueChanged={this.onCellValueChanged}
          onCellClicked={this.onCellClickedEvent}
          onGridSizeChanged={this.handleGridSizeChanged}
          getRowNodeId={this.getRowNodeId}
          sideBar={this.state.sideBar}
          columnTypes={this.state.columnTypes}
          multiSortKey={this.state.multiSortKey}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          enableBrowserTooltips={true}
          // enterMovesDownAfterEdit={true}
          // rowHeight={50}
        />
      </div>
    );
    return (
      <ModalDialog
        htmlId="ModalDialogWithHeader"
        className="xmm-category-group-modal"
        show={this.props.show}
        backdrop={"static"}
        keyboard={false}
        header={
          <ModalDialog.Title>
            {localeStrings["xmm.portal.nav.settings.manage_categories"]}
          </ModalDialog.Title>
        }
        onHide={this.props.closeDialog}
      >
        {header}
        {gridWidget}
      </ModalDialog>
    );
  }
}

function synonymsGetter(params) {
  const { data } = params;
  if (!data || !data.synonyms) {
    return "";
  }
  if (Array.isArray(data.synonyms)) {
    return data.synonyms.join(", ");
  }
  if (typeof data.synonyms === "string") {
    return data.synonyms;
  }
  return "";
}
ManageCategoryGroupDialog.propTypes = {
  show: bool,
  closeDialog: PropTypes.func
};
ManageCategoryGroupDialog.defaultProps = {
  show: false
};
