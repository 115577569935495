/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { isSameValue } from "../../commonUtil/utils/string";

function CheckboxCell({ value, node, field, tellUsMoreServiceId }) {
  const [checked, setChecked] = useState(value);
  // console.log("CheckboxCell", node, value, field);
  const { make, serviceKind, liveFlag, status } = node.data;
  const disabled =
    (field === "liveFlag" &&
      (status !== "READY" || (status === "READY" && liveFlag))) ||
    (field === "commentsRequired" && serviceKind !== "repair") ||
    (field === "unscheduledDefault" && make === "ANY") ||
    (tellUsMoreServiceId &&
      isSameValue(tellUsMoreServiceId, node.data.serviceId));
  return (
    <div className="xmm-checkbox-container">
      <input
        className="form-checkbox"
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={() => {
          const newChecked = !checked;
          setChecked(newChecked);
          if (node.data && field) {
            node.data[field] = newChecked;
            const value = newChecked;
            const detail = { data: node.data, field, value };
            window.dispatchEvent(
              new CustomEvent("saveCellEditEvent", {
                detail,
                bubbles: true,
                cancelable: true
              })
            );
          }
          // console.log(node.data);
        }}
      />
      <span className="xmm-checkmark" />
      <span className="label-checkbox" />
    </div>
  );
}

export default CheckboxCell;
