export const InspectionItemCategory = "service category";

export function updateOperationStatuses(operation, operationStatuses) {
  operation.priceStatus = operationStatuses.priceStatus;
  operation.contentEnabledAlacarte = operationStatuses.contentEnabledAlacarte;
  operation.contentEnabledMenus = operationStatuses.contentEnabledMenus;
  operation.laborTimeRange = operationStatuses.laborTimeRange;
  operation.dealerLaborRateCodeId = operationStatuses.dealerLaborRateCodeId;
  operation.numPricingOverrides = operationStatuses.numPricingOverrides;
  operation.numOpcodeOverrides = operationStatuses.numOpcodeOverrides;
}

export function getInspectionItemDisplay(inspectionItem) {
  if (!inspectionItem) {
    return "";
  }
  const { category, categoryName, itemTypeName, inspectionItemName } =
    inspectionItem;
  const theCategorty = category ? category : categoryName;
  const theInspectionItem = itemTypeName ? itemTypeName : inspectionItemName;
  return `${theCategorty} - ${theInspectionItem}`;
}

export function appendToExternalList(
  record,
  fieldName,
  externalNames,
  extCntName
) {
  const str = record[fieldName];
  let newExternalNames = externalNames;
  if (str) {
    const origExternalNames = str.split(";").map(s => s.trim());
    newExternalNames = origExternalNames.concat(externalNames);
  }
  newExternalNames.sort((a, b) => {
    return a === b ? 0 : a > b ? 1 : -1;
  });
  const namesStr = newExternalNames.join("; ");
  record[fieldName] = namesStr;
  record[extCntName] += externalNames.length;
}
export function removeFromExternalList(
  record,
  fieldName,
  externalName,
  extCntName
) {
  const str = record[fieldName];
  let newExternalNames = [externalName];
  if (str) {
    const origExternalNames = str.split(";").map(s => s.trim());
    newExternalNames = origExternalNames.filter(s => s !== externalName);
  }
  newExternalNames.sort((a, b) => {
    return a === b ? 0 : a > b ? 1 : -1;
  });
  const namesStr = newExternalNames.join("; ");
  record[fieldName] = namesStr;
  record[extCntName] -= 1;
}
