/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import SearchableSelect from "@cx/ui/SearchableSelect";
// import SearchPane from "./SearchPane";
// import VehicleCard from "./VehicleCard";
// import LaborPartsPane from "./LaborPartsPane";
import "../../../../../previewGrids/index.scss";
import ResultsPane from "../../../../../previewGrids/components/ResultsPane";
import { PreviewContext, initPreviewState } from "./../../preview-ops-context";
import {
  defaultToZeroIfNullOrEmpty,
  findRecord,
  isDifferentValue
  // sleep
} from "../../../utils/helper";
import { getPartsAndLaborAndTotal } from "../../../../../previewGrids/components/utils/helper";
import { round } from "../../../utils/value";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import { toast } from "@cx/ui/Toast";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import PickyFilter from "../../../filters/PickyFilter";
import { isSameValue } from "../../../../../commonUtil/utils/string";
import generateApiToken from "../../../utils/apiTokenValidator";

// const validStatuses = ["IN-TESTING", "READY"];

/**
 * FiltersPane Class description.
 * E.g:
 * ```html
 * <FiltersPane locale={"en_US"} previewState={state} />
 * ```
 */
class FiltersPane extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      dealerCode,
      dealerFlag,
      decodeVinOk,
      operationList,
      serviceRecommendations,
      showFilter,
      serviceCategoryGroupsMapLoaded,
      vin,
      vehicleDesc
    } = nextProps;
    if (
      dealerCode !== prevState.dealerCode ||
      dealerFlag !== prevState.dealerFlag ||
      serviceRecommendations !== prevState.serviceRecommendations ||
      showFilter !== prevState.showFilter ||
      serviceCategoryGroupsMapLoaded !==
        prevState.serviceCategoryGroupsMapLoaded ||
      vin !== prevState.vin ||
      vehicleDesc !== prevState.vehicleDesc ||
      (operationList && operationList !== prevState.operationList)
    ) {
      if (operationList) {
        setTimeout(() => {
          prevState.thisHandle.onSelectLabels([]);
        }, 0);
      }
      return {
        dealerCode,
        dealerFlag,
        decodeVinOk,
        operationList,
        selectableOperations: [...operationList],
        serviceRecommendations,
        showFilter,
        serviceCategoryGroupsMapLoaded,
        vehicleDesc,
        vin
      };
    }
    return null;
  }
  constructor(props) {
    super(props);
    // this.decodeVin = this.decodeVin.bind(this);
    // this.getAllOperations = this.getAllOperations.bind(this);

    this.resultsPaneRef = React.createRef();

    this.selectOperation = this.selectOperation.bind(this);
    // this.updateVehicleInfo = this.updateVehicleInfo.bind(this);
    this.updateMask = this.updateMask.bind(this);
    this.updateShowFilter = this.updateShowFilter.bind(this);
    // this.populateContext = this.populateContext.bind(this);
    this.onCategoryGroup = this.onCategoryGroup.bind(this);
    this.onSelectOperation = this.onSelectOperation.bind(this);
    this.selectOperationByCategoryId =
      this.selectOperationByCategoryId.bind(this);
    this.onSelectLabels = this.onSelectLabels.bind(this);
    this.getLaborTimeTotal = this.getLaborTimeTotal.bind(this);
    this.getPartsPriceTotal = this.getPartsPriceTotal.bind(this);
    // read App context values passed as props
    const {
      appContext,
      operationList,
      previewState,
      serviceCategoryGroupsMapLoaded
    } = props;
    const { role } = appContext;
    // const { operationId, selectedOperation } = previewState;

    this.startingPreview = false;

    this.state = {
      thisHandle: this,
      role,
      showMask: false, // flag to show mask when ResultPane loading
      showFilter: previewState.showFilter,
      decodeVinOk: previewState.decodeVinOk,
      acesAttributes: previewState.acesAttributes,
      undecodedAttributes: previewState.undecodedAttributes,
      vin: previewState.vin,
      dealerCode: previewState.dealerCode,
      dealerFlag: previewState.dealerFlag,
      mileage: previewState.mileage || "",
      vehicleDesc: previewState.vehicleDesc,
      errors: { vin: "", operations: "", dealerCode: "" },
      laborPartsTotal: {
        isPublishedCatalog: false,
        laborRate: 0,
        laborTime: 0,
        partsTotal: 0,
        laborTotal: 0,
        total: 0
      },
      operationId: previewState.selectedOperation
        ? previewState.selectedOperation.value
        : "",
      selectedOperation: previewState.selectedOperation,
      operationList: operationList || previewState.operationList,
      selectableOperations: previewState.selectableOperations,
      partsAndLabor: previewState.partsAndLabor,
      selectedLabors: previewState.selectedLabors,
      selectedParts: previewState.selectedParts,
      selectedLabels: previewState.selectedLabels,
      globalOperationlabelsMap: {},
      selectedCategoryGroups: previewState.selectedCategoryGroups || [],
      categoryGroups: previewState.categoryGroups || [],
      serviceRecommendations: previewState.serviceRecommendations || [],
      versionRowSelected: null,
      opsByYMM: null,
      serviceCategoryGroupsMapLoaded
    };
  }
  componentDidMount() {
    const { showLabelFilter } = this.props;
    if (showLabelFilter) {
      this.loadLabels();
      this.loadGlobalOperationLabels();
      this.resetSelectedOperation();
    }
    // this.updateModalTitleWithVersionAndSchema();
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    if (!this.startingPreview) {
      this.updatePreviewState();
    }
  }
  resetGlobalOpsPreview = () => {
    this.startingPreview = true;
    this.setState({
      // showFilter: false,
      selectedOperation: null,
      operationList: [],
      selectableOperations: [],
      selectedLabels: [],
      selectedLabors: [],
      selectedParts: [],
      partsAndLabor: {},
      decodeVinOk: false,
      acesAttributes: [],
      undecodedAttributes: [],
      vehicleDesc: {
        year: "",
        make: "",
        model: "",
        trim: "",
        engineType: "",
        engineSize: "",
        driveType: "",
        transmissionType: ""
      },
      vin: ""
      // dealerCode: "",
      // dealerFlag: "0",
      // mileage: "",
      // serviceRecommendations: []
    });
  };

  updatePreviewState = () => {
    const {
      showFilter,
      selectedLabels,
      selectableOperations,
      selectedOperation,
      categoryGroups,
      selectedCategoryGroups,
      operationList,
      partsAndLabor,
      selectedLabors,
      selectedParts,
      acesAttributes,
      undecodedAttributes,
      vehicleDesc,
      vin,
      dealerCode,
      decodeVinOk,
      dealerFlag,
      mileage,
      serviceRecommendations,
      opsByYMM
    } = this.state;
    const previewState = {
      showFilter,
      selectedLabels,
      categoryGroups,
      selectedCategoryGroups,
      selectedOperation,
      selectableOperations,
      operationList,
      partsAndLabor,
      selectedLabors,
      selectedParts,
      acesAttributes,
      undecodedAttributes,
      vehicleDesc,
      vin,
      dealerCode,
      decodeVinOk,
      dealerFlag,
      mileage,
      serviceRecommendations,
      opsByYMM,
      versionRowSelected: []
    };
    this.props.savePreviewState(previewState);
  };
  // populateContext = (key, value) => {
  //   this.setState(
  //     state => ({
  //       ...state,
  //       [key]: value
  //     }),
  //     () => {
  //       // callback
  //     }
  //   );
  // };
  // updateYMM = data => {
  //   this.setState({ opsByYMM: data });
  // };

  // Update grand-parent {preview-context}, from grand children {search-pane, results grid etc }
  // updateVehicleInfo = vehicle => {
  //   sleep(1000).then(() => {
  //     console.log("preview context vehicle", vehicle);
  //     this.setState(prevState => {
  //       return { vehicle };
  //     });
  //   });
  // };
  /* not used
  refineOperations = datalist => {
    const operations = [];
    if (datalist && datalist.length > 0) {
      datalist.forEach(op => {
        operations.push({
          label: op.operationName,
          value: op.operationId.toString(),
          operationName: op.operationName,
          operationId: op.operationId,
          categoryId: op.categoryId + "",
          operationSource: op.operationSource,
          opCode: op.opCode
        });
      });
    }
    return operations;
  };
  */

  stripOemPartNumber(oePartNumber, stripLeadingZerosFlag) {
    if (!oePartNumber) {
      return "";
    }
    let str = oePartNumber.replace(/[-\s]/g, "");
    if (stripLeadingZerosFlag) {
      str = str.replace(/^0+/, "");
    }
    return str.toUpperCase();
  }
  selectOperation(selectedOperation) {
    const { serviceApi } = this.props;
    const { operationId, operationSource } = selectedOperation;
    const { dealerCode, vin, vehicleDesc } = this.state;
    let { make } = vehicleDesc;
    if (!make) {
      make = "ANY";
    }
    const apiVersion = 2;
    const params = { dealerCode, operationId, operationSource, apiVersion };
    if (!dealerCode) {
      delete params.dealerCode;
    }
    if (!vin || vin === "UNKNOWN") {
      params.metaVehicleId = vehicleDesc.metaVehicleId;
    }

    // reset total and price overrides on selecting an operation
    const laborPartsTotal = {
      isPublishedCatalog: false,
      laborRate: 100,
      laborTime: 0,
      partsTotal: 0,
      laborTotal: 0,
      total: 0
    };

    this.updateLaborPartsTotal(laborPartsTotal, {});

    // clear the selectedVehicleAttributeMap
    const partsAndLabor = {
      operation: null,
      labors: [],
      laborsColumns: [],
      parts: [],
      partsColumns: [],
      selectedVehicleAttributeMap: {}
    };
    this.setState({ partsAndLabor });
    const { version } = this.props;
    if (!isNaN(version)) {
      params.version = version;
    }

    const request = {
      apiKey: "getOperationDetail",
      url: `/opsadmin/operations/getOperationDetail/make/${make}/vin/${vin}`,
      method: "get",
      params
    };
    this.updateMask(true);
    serviceApi(
      request,
      response => {
        const { statusCode } = response;
        if (statusCode && statusCode !== 200) {
          const { errors } = this.state;
          errors.operations = "Internal error occurred.";
          this.setState({
            showMask: false,
            showFilter: true,
            errors: { ...errors }
          });
          // toast.error("Internal servrer error occurred.");
          return;
        }
        const { partsAndLabor, laborPartsTotal, priceOverrides } =
          getPartsAndLaborAndTotal(selectedOperation, response);

        const params = {
          make,
          vin,
          appSource: "CatalogAdminPreview",
          dealerCode,
          operationId,
          operationSource,
          payType: "C", // Inspect can pass a value
          serviceType: "", // Inspect can pass a value
          partsAndLabor
        };

        this.resultsPaneRef &&
          this.resultsPaneRef.current &&
          this.resultsPaneRef.current.getPartsPricingAndInventory(
            this.serviceApiCallback,
            params
          );
        // }

        this.setState(
          {
            showMask: false,
            selectedOperation,
            partsAndLabor,
            selectedLabors: [],
            selectedParts: [],
            laborPartsTotal,
            priceOverrides
          },
          () => {
            this.props.setLaborPartsTotal(laborPartsTotal, priceOverrides);
          }
        );

        // this.updateMask(false);
      },
      error => {
        // let { message } = error;
        const { response } = error;
        if (!response) {
          const { errors } = this.state;
          errors.operations = error.message;
          this.setState({
            showFilter: true,
            errors: { ...errors }
          });
        } else {
          const { success, message } = response.data;
          if (!success && message) {
            const { errors } = this.state;
            errors.dealerCode = message;
            this.setState({
              showFilter: true,
              errors: { ...errors }
            });
          }
        }
        this.updateMask(false);
      }
    );
  }
  serviceApiCallback = (data, successCallback, errorCallback) => {
    const { serviceApi } = this.props;
    const { dealerCode, vehicleDesc } = this.state;
    const { make } = vehicleDesc;
    const apiToken = generateApiToken();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-Token": apiToken // "RGF2aWQgQ2hpIExlZQ==A"
    };

    const request = {
      headers,
      apiKey: "getPartsPricingAndInventory", // used by Catalog Admin and Catalog Manager only (not needed by inspect)
      url: `/opsadmin/operations/getPartsPricingAndInventory/make/${make}/dealerCode/${dealerCode}`,
      method: "post",
      data
    };
    serviceApi(
      request,
      response => {
        successCallback(response);
      },
      error => {
        errorCallback(error);
      }
    );
  };
  getPriceOverrides = selectedLabor => {
    const {
      laborPrice,
      laborPriceOverridden,
      partsPrice,
      partsPriceOverridden,
      totalPrice,
      totalPriceOverridden
    } = selectedLabor;
    return {
      laborPrice,
      laborPriceOverridden,
      partsPrice,
      partsPriceOverridden,
      totalPrice,
      totalPriceOverridden
    };
  };
  selectGridRow = (selectedRows, type, selectedParts) => {
    // laborPartsTotal: { laborRate: 100, laborTime: 1.25, partsTotal: 0 },
    const { laborPartsTotal } = this.state;
    if (laborPartsTotal.isPublishedCatalog) {
      return;
    }

    if (type === "labor") {
      let { laborTime, partsTotal } = laborPartsTotal;
      laborTime = this.getLaborTimeTotal(selectedRows);
      let priceOverrides = {};
      if (selectedRows.length !== 0) {
        priceOverrides = this.getPriceOverrides(selectedRows[0]);
      }
      if (selectedRows.length === 0) {
        partsTotal = 0;
        this.setState({ selectedParts: [] });
      } else if (selectedParts) {
        partsTotal = this.getPartsPriceTotal(selectedParts);
        this.setState({ selectedParts });
      }
      const newLaborPartsTotal = { ...laborPartsTotal, laborTime, partsTotal };
      this.setState(
        { selectedLabors: selectedRows, laborPartsTotal: newLaborPartsTotal },
        () => {
          this.updateLaborPartsTotal(newLaborPartsTotal, priceOverrides);
        }
      );
    }
    if (type === "parts") {
      const partsTotal = this.getPartsPriceTotal(selectedRows);
      const newLaborPartsTotal = { ...laborPartsTotal, partsTotal };
      this.setState(
        { selectedParts: selectedRows, laborPartsTotal: newLaborPartsTotal },
        () => {
          this.updateLaborPartsTotal(newLaborPartsTotal);
        }
      );
    }
  };

  getLaborTimeTotal = (selectedRows, selectedParts) => {
    // const { laborPartsTotal } = this.state;
    let laborTime = 0;
    selectedRows.forEach(labor => {
      const { quantity, laborHours } = labor;
      laborTime +=
        defaultToZeroIfNullOrEmpty(quantity) *
        defaultToZeroIfNullOrEmpty(laborHours);
    });
    return laborTime;
  };

  getPartsPriceTotal = selectedRows => {
    // const { laborPartsTotal } = this.state;
    let partsTotal = 0;
    selectedRows.forEach(parts => {
      const { dmsPrice, price, quantity } = parts;
      partsTotal +=
        defaultToZeroIfNullOrEmpty(dmsPrice ? dmsPrice : price) *
        defaultToZeroIfNullOrEmpty(quantity);
    });
    return round(partsTotal);
    // const newLaborPartsTotal = {
    //   ...laborPartsTotal,
    //   partsTotal: round(partsTotal)
    // };
    // const selectedParts = selectedRows;
    // this.setState(
    //   { selectedParts, laborPartsTotal: newLaborPartsTotal },
    //   () => {
    //     this.props.setLaborPartsTotal(newLaborPartsTotal);
    //   }
    // );
  };

  updateLaborPartsTotal = (laborPartsTotal, priceOverrides) => {
    this.props.setLaborPartsTotal(laborPartsTotal, priceOverrides);
  };

  /*
  getServiceRecommendationMileage(year) {
    const now = new Date();
    const years = now.getFullYear() - year;
    return years * 10000;
  }
  getServiceRecommendations(dealerCode, mileage, categoryIdToServiceListMap) {
    const { vehicleDesc, vin } = this.state;
    const { id, year } = vehicleDesc;
    const data = Object.assign({}, serviceRecommendationPostData);
    const { vehicle } = data;
    vehicle.dealerCode = dealerCode;
    vehicle.vin = vin;
    vehicle.metaVehicleId = id ? id.toString() : "";
    vehicle.mileage = mileage
      ? Number(mileage)
      : this.getServiceRecommendationMileage(year);
    const { serviceApi } = this.props;
    const request = {
      url: "/opsadmin/recommendations/getRecommendations",
      method: "post",
      data
    };
    serviceApi(
      request,
      response => {
        const { data, errors } = response;
        let serviceRecommendations = [];
        if (data) {
          const { serviceRecommendation } = data;
          if (serviceRecommendation) {
            serviceRecommendations = serviceRecommendation;
            serviceRecommendations.forEach(sr => {
              const { serviceCategoryId } = sr;
              const linkedServices =
                categoryIdToServiceListMap[serviceCategoryId];
              sr.linkedServices = linkedServices;
            });
          }
        }
        this.setState({ mileage: vehicle.mileage, serviceRecommendations });
        console.log("getRecommendations errors:", errors);
        // if (errors && errors.length !== 0) {
        //   const { message } = errors[0];
        //   if (message) {
        //     toast.info(message);
        //   } else {
        //     toast.error("Unknown error in getting service recommendations.");
        //   }
        // }
      },
      error => {
        let { message } = error;
        if (!message) {
          message = "Error in getting service recommendatons.";
        }
        toast.error(error.message);
      }
    );
  }

  updateModalTitleWithVersionAndSchema() {
    const { appContext } = this.props;
    const { versionRowSelected } = appContext;
    if (this.hasSelectedVersion(versionRowSelected)) {
      this.uppdateVersionSchema(versionRowSelected[0]);
    } else {
      this.getLiveVersionAndMotorSchema();
    }
  }

  hasSelectedVersion(versionRowSelected) {
    return (
      versionRowSelected &&
      versionRowSelected.length &&
      validStatuses.includes(versionRowSelected[0].status)
    );
  }

  uppdateVersionSchema(versionRowSelected) {
    const { version, motorSchema, status, liveFlag } = versionRowSelected;
    this.props.updateModalTitleWithVersion(
      version,
      motorSchema,
      status,
      liveFlag
    );
  }

  getLiveVersionAndMotorSchema() {
    const restUrl = "/opsadmin/rest-db/getValues/liveReadyVersion";

    const { serviceApi } = this.props;
    serviceApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        console.log(response);
        if (response && Array.isArray(response) && response.length !== 0) {
          response[0].liveFlag = true;
          this.uppdateVersionSchema(response[0]);
          // const { version, motorSchema, status } = response[0];
          // const liveFlag = true;
          // this.props.updateModalTitleWithVersion(
          //   version,
          //   motorSchema,
          //   status,
          //   liveFlag
          // );
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  getAllOperations(dealerCode, mileage) {
    this.props.resetLaborPartsTotal();
    this.resetSelectedOperation();
    const { serviceApi, appContext } = this.props;
    const { opsByYMM, vin, vehicleDesc } = this.state;
    let { make } = vehicleDesc;
    if (!make) {
      make = "ANY";
    }
    const params = !dealerCode ? {} : { dealerCode };

    if (opsByYMM !== null) {
      params.filterOpsByYMM = opsByYMM ? 1 : 0;
    }
    if (appContext.versionRowSelected && appContext.versionRowSelected.length) {
      const status = appContext.versionRowSelected[0].status;
      if (validStatuses.includes(status)) {
        const version = appContext.versionRowSelected[0].version;
        params.version = version;
      }
    }

    const request = {
      url: `/opsadmin/operations/findOperationsByVin/make/${make}/vin/${vin}`,
      method: "get",
      params
    };
    this.updateMask(true);
    serviceApi(
      request,
      response => {
        const { errors } = this.state;
        const { success, operations, message } = response;
        errors.dealerCode = success ? "" : message;
        if (success && message) {
          toast.info(message);
        }
        if (success) {
          const categoryIdToServiceListMap = {};
          operations.forEach(op => {
            const { operationName, categoryId } = op;
            op.label = `${operationName}`;
            op.value = makeOperationId(op); // `${operationId}:${operationSource}`;
            if (categoryId) {
              const list = categoryIdToServiceListMap[categoryId];
              if (list) {
                list.push(op);
              } else {
                categoryIdToServiceListMap[categoryId] = [op];
              }
            }
          });
          operations.sort((a, b) => {
            return a.operationName > b.operationName ? 1 : -1;
          });
          this.setState(
            {
              dealerCode,
              showFilter: true,
              showMask: false,
              operationList: operations,
              selectableOperations: [...operations],
              partsAndLabor: null,
              errors: { ...errors }
            },
            () => {
              const { selectedLabels } = this.state;
              this.onSelectLabels(selectedLabels);
              // call service recommendations api
              if (dealerCode) {
                this.getServiceRecommendations(
                  dealerCode,
                  mileage,
                  categoryIdToServiceListMap
                );
              }
            }
          );
        } else {
          this.setState({
            dealerCode,
            showFilter: true,
            showMask: false,
            operationList: [],
            partsAndLabor: null,
            errors: { ...errors }
          });
        }
      },
      error => {
        // let { message } = error;
        const { response } = error;
        const { success, message } = response.data;
        if (!success && message) {
          const { errors } = this.state;
          errors.dealerCode = message;
          this.setState({
            dealerCode,
            showFilter: true,
            operationList: [],
            partsAndLabor: null,
            errors: { ...errors }
          });
        }
        this.updateMask(false);
      }
    );
  }
  */

  resetSelectedOperation() {
    this.setState({
      operationId: "",
      selectedOperation: null,
      partsAndLabor: null
    });
  }
  // Update mask state when Get services button is clicked; decode button clicked
  updateMask = isReady => {
    this.setState({
      showMask: isReady
    });
  };
  updateShowFilter = show => {
    this.setState({
      showFilter: show
    });
  };
  findOperation(operationId) {
    const { showLabelFilter } = this.props;
    const { operationList } = this.state;
    const selectedOp = findRecord(operationList, "value", operationId);
    if (selectedOp && Object.keys(selectedOp).length > 0) {
      let msg = "";
      if (showLabelFilter) {
        if (selectedOp.operationSource === "DealerPublishedCatalog") {
          msg = "Displaying data from Dealer Catalog";
        } else if (selectedOp.operationSource === "GlobalCatalog") {
          msg = "Displaying raw data from Motor";
        }
      }
      this.setState(
        {
          selectedLaborRowCount: 0,
          operation: selectedOp,
          operationId,
          msg,
          showMask: true
        },
        () => {
          this.selectOperation(selectedOp);
        }
      );
    } else {
      this.setState({ msg: "", showMask: false });
    }
  }

  loadLabels() {
    // const { role } = this.state;
    // if (role !== "metavehicle") {
    //   return;
    // }
    const { serviceApi } = this.props;
    const restUrl = "/opsadmin/rest-db/getValues/uniqueLabels";
    serviceApi(
      {
        apiKey: "uniqueLabels",
        url: restUrl,
        method: "get"
      },
      datalist => {
        if (datalist) {
          console.log(datalist);
          const labels = datalist
            .filter(l => l.label !== null)
            .map(lbl => {
              const { label } = lbl;
              lbl.label = label.toString();
              lbl.value = lbl.label;
              return lbl;
            });
          labels.sort((a, b) => {
            const label1 = a.label.toLowerCase();
            const label2 = b.label.toLowerCase();
            return label1 === label2 ? 0 : label1 > label2 ? 1 : -1;
          });
          this.setState({ labels });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  loadGlobalOperationLabels() {
    // const { role } = this.state;
    // if (role !== "metavehicle") {
    //   return;
    // }
    const { serviceApi } = this.props;
    const restUrl = "/opsadmin/rest-db/getValues/globalOpsWithLabels";
    serviceApi(
      {
        apiKey: "globalOpsWithLabels",
        url: restUrl,
        method: "get"
      },
      datalist => {
        if (datalist) {
          console.log(datalist);
          // const globalOperationlabels = [];
          const globalOperationlabelsMap = {};
          datalist.forEach(op => {
            const globalOperationId = op.globalOperationId.toString();
            const labels = op.labels.labels;
            globalOperationlabelsMap[globalOperationId] = labels;
          });
          this.setState({ globalOperationlabelsMap }, () => {
            // this.onSelectLabels([]);
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  onSelectLabels = selectedLabels => {
    const selectedLabelValues = selectedLabels.map(m => m.value);
    const {
      globalOperationlabelsMap,
      // labels,
      selectedOperation,
      operationList
    } = this.state;
    let opSelected = false;
    let selectableOperations = [];
    if (selectedLabels.length === 0) {
      selectableOperations = [...operationList];
      opSelected = true;
    } else {
      selectableOperations = operationList.filter(op => {
        const labelList = globalOperationlabelsMap[op.operationId.toString()];
        if (labelList) {
          let included = true;
          for (let i = 0; i < selectedLabelValues.length; i++) {
            if (!labelList.includes(selectedLabelValues[i])) {
              included = false;
              break;
            }
          }
          if (included) {
            if (
              !opSelected &&
              selectedOperation &&
              op.operationId === selectedOperation.operationId
            ) {
              opSelected = true;
            }
          }
          return included;
        }
        return false;
      });
      if (!opSelected) {
        this.props.resetLaborPartsTotal();
        this.resetSelectedOperation();
      }
    }
    const categoryGroupsMap = {};
    const { serviceCategoryGroupsMap } = this.props.appContext;
    selectableOperations.forEach(op => {
      const { categoryId } = op;
      if (categoryId) {
        const serviceCateogryGroup =
          serviceCategoryGroupsMap[categoryId.toString()];
        if (serviceCateogryGroup) {
          const { categoryGroupId, categoryGroupName } = serviceCateogryGroup;
          if (categoryGroupName && !categoryGroupsMap[categoryGroupName]) {
            categoryGroupsMap[categoryGroupName] = {
              label: categoryGroupName,
              value: categoryGroupId.toString()
            };
          }
        }
      }
    });
    const categoryGroups = Object.values(categoryGroupsMap).sort((a, b) => {
      return a.label < b.label ? -1 : 1;
    });
    categoryGroups.splice(0, 0, {
      label: "All Categories",
      value: ""
    });

    const selectedCategoryGroups = [];
    // const selectedCategoryGroups =
    //   categoryGroups.length === 1 ? categoryGroups : [];
    const selectableOperationsByLabel = [...selectableOperations];
    this.setState({
      selectedLabels,
      selectableOperations,
      selectableOperationsByLabel,
      selectedCategoryGroups,
      categoryGroups
    });
  };
  onCategoryGroup = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    // const optionValue = value && value.length !== 0 ? value[0].value : "";
    const { selectedOperation, selectedCategoryGroups } = this.state;
    const operationId = selectedOperation ? selectedOperation.operationId : "";
    if (selectedCategoryGroups !== value) {
      if (value.length === 0) {
        const { selectedLabels } = this.state;
        this.onSelectLabels(selectedLabels);
      } else {
        const categoryIds = value.map(cg => cg.value);
        const { serviceCategoryGroupsMap } = this.props.appContext;
        const { selectableOperations, selectableOperationsByLabel } =
          this.state;
        let opSelected = false;
        const operations =
          selectableOperationsByLabel &&
          selectableOperationsByLabel.length !== 0
            ? selectableOperationsByLabel
            : selectableOperations;
        const filteredSelectableOperations = operations.filter(op => {
          const { categoryId } = op;
          if (categoryIds.length === 0) {
            if (!opSelected && isSameValue(operationId, op.operationId)) {
              opSelected = true;
            }
            return true;
          } else if (categoryIds.length === 1 && categoryIds[0] === "") {
            return true;
          } else if (categoryId) {
            const serviceCateogryGroup =
              serviceCategoryGroupsMap[categoryId.toString()];
            if (serviceCateogryGroup) {
              const { categoryGroupId } = serviceCateogryGroup;
              if (
                categoryGroupId &&
                categoryIds.includes(categoryGroupId.toString())
              ) {
                if (!opSelected && isSameValue(operationId, op.operationId)) {
                  opSelected = true;
                }
                return true;
              }
            }
          }
          return false;
        });
        if (!opSelected) {
          this.props.resetLaborPartsTotal();
          this.resetSelectedOperation();
        }
        this.setState({
          selectedCategoryGroups: value,
          selectableOperations: filteredSelectableOperations
        });
      }
    }
  };
  onSelectOperation = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    const { operationId } = this.state;
    if (optionValue && isDifferentValue(operationId, optionValue)) {
      this.setState({ showMask: true }, () => {
        this.findOperation(optionValue);
      });
    }
  };
  selectOperationByCategoryId = serviceCategoryId => {
    const { selectableOperations } = this.state;
    for (const op of selectableOperations) {
      if (op.categoryId === serviceCategoryId) {
        const operationId = makeOperationId(op);
        this.setState({ operationId, showMask: true }, () => {
          this.findOperation(operationId);
        });
        return true;
      }
    }
    return false;
  };

  render() {
    const { msgBox, showLabelFilter } = this.props;

    const {
      // mask,
      msg,
      showFilter,
      showMask,
      vin,
      dealerCode,
      dealerFlag,
      decodeVinOk,
      mileage,
      vehicleDesc,
      acesAttributes,
      undecodedAttributes,
      selectedOperation,
      selectableOperations,
      operationList,
      operationId,
      errors,
      laborPartsTotal,
      partsAndLabor,
      selectedLabors,
      selectedParts,
      selectedCategoryGroups,
      categoryGroups
      // serviceRecommendations
    } = this.state;
    /* set localstate to preview context here */
    const contextValue = {
      vin,
      dealerCode,
      dealerFlag,
      // locale,
      decodeVinOk,
      mileage,
      vehicleDesc,
      acesAttributes,
      undecodedAttributes,
      selectedOperation,
      selectableOperations,
      operationList,
      operationId,
      laborPartsTotal,
      partsAndLabor,
      selectedLabors,
      selectedParts,
      errors,
      updateMask: this.updateMask
      // populateContext: this.populateContext
      // updateVehicleInfo: this.updateVehicleInfo,
      // updateYMM: this.updateYMM
    };
    /* placeholder to show loading mask */
    let searchField = null;
    let labelFilter = null;
    let categoryGroupFilter = null;
    let results = null;
    let resultsContent = null;
    let maskBlock = null;
    // let msgBox = null;
    if (showMask) {
      maskBlock = <LoadingIndicator htmlId="previewMask" size="large" />;
    }
    if (!showFilter) {
      results = maskBlock;
    } else {
      let operationLabel = "Operations";
      if (selectableOperations && selectableOperations.length > 0) {
        operationLabel = "Operations (" + selectableOperations.length + ")";
      } else {
        // msgBox = "No operations for this VIN";
      }
      labelFilter = showLabelFilter ? (
        <div className="ops-preview-labels" id={"preview-labels"}>
          <PickyFilter
            htmlId="labelsPicky"
            name="labels"
            // disabled={disableMenu}
            placeholder={"Filter by Labels"}
            label={"Labels"}
            initialValue={this.state.selectedLabels}
            options={this.state.labels || []}
            includeFilter={false}
            includeSelectAll={false}
            numberDisplayed={1}
            dropdownHeight={200}
            onChange={this.onSelectLabels}
          />
        </div>
      ) : (
        ""
      );
      categoryGroupFilter = (
        <SearchableSelect
          htmlId="categoryGroupSelect"
          name="categoryGroup"
          className="ops-preview-category-group-filter"
          label="Category Group"
          // style={{ width: "250px" }}
          placeholder="Filter by category group"
          enableMultiSelect={false}
          maxHeight={400}
          // maxResults={100}
          // paginate={true}
          // customSelectableItem={customSelectableItem}
          onChange={this.onCategoryGroup}
          value={selectedCategoryGroups}
          options={categoryGroups}
          // hasError={errors.operations !== ""}
          // errorMessage={errors.operations}
        />
      );
      searchField = (
        <SearchableSelect
          htmlId="operationSelect"
          name="operation"
          label={operationLabel}
          style={{ width: "500px" }}
          placeholder=""
          enableMultiSelect={false}
          maxHeight={400}
          maxResults={500}
          paginate={true}
          customSelectableItem={customSelectableItem}
          onChange={this.onSelectOperation}
          value={operationId}
          options={selectableOperations}
          hasError={!!errors.operations}
          errorMessage={errors.operations}
        />
      );
      resultsContent = (
        <ResultsPane
          ref={this.resultsPaneRef}
          selectedLabors={selectedLabors}
          selectedParts={selectedParts}
          partsAndLabor={partsAndLabor}
          selectGridRow={this.selectGridRow}
          includeInspectColumns={!!dealerCode}
        />
      );
      results = (
        <Col xs={12} md={12}>
          <Row>
            <Col xs={12} md={12} className="ops-form-section">
              {labelFilter}
              {categoryGroupFilter}
              {searchField}
              <i>{partsAndLabor ? msg : ""}</i>
              <span>&nbsp;&nbsp;&nbsp;{maskBlock}</span>
            </Col>
          </Row>
          <span className="ops-error-msg">{msgBox}</span>
          <div>{partsAndLabor ? resultsContent : null}</div>
        </Col>
      );
    }
    return (
      <React.Fragment>
        <PreviewContext.Provider value={contextValue}>
          <Row>{results}</Row>
        </PreviewContext.Provider>
      </React.Fragment>
    );
  }
}
export default FiltersPane;

FiltersPane.propTypes = {
  dealerCode: PropTypes.string,
  dealerFlag: PropTypes.string,
  decodeVinOk: PropTypes.bool,
  showFilter: PropTypes.bool,
  showLabelFilter: PropTypes.bool,
  vehicleDesc: PropTypes.object,
  vin: PropTypes.string,
  msgBox: PropTypes.string,
  serviceCategoryGroupsMapLoaded: PropTypes.bool,
  operationList: PropTypes.array,
  serviceRecommendations: PropTypes.array,
  appContext: PropTypes.object,
  previewState: PropTypes.object,
  version: PropTypes.number,
  resetLaborPartsTotal: PropTypes.func,
  savePreviewState: PropTypes.func,
  updateModalTitleWithVersion: PropTypes.func,
  getServiceRecommendations: PropTypes.func,
  setLaborPartsTotal: PropTypes.func,
  serviceApi: PropTypes.func
  // locale: PropTypes.string
};

FiltersPane.defaultProps = {
  dealerCode: "",
  dealerFlag: "0",
  decodeVinOk: false,
  showFilter: false,
  showLabelFilter: true,
  msgBox: "",
  serviceCategoryGroupsMapLoaded: false,
  serviceRecommendations: [],
  version: NaN,
  resetLaborPartsTotal: () => {
    //
  },
  serviceApi: () => {
    // handle function here
  },
  savePreviewState: () => {
    // handle function here
  },
  setLaborPartsTotal: () => {
    //
  },
  getServiceRecommendations: () => {
    //
  },
  previewState: initPreviewState
};
/* eslint-enable no-console */

function customSelectableItem(option) {
  const { label, operationSource, opCode } = option;
  let optionLabel = label;
  if (optionLabel && opCode) {
    optionLabel += ` - ${opCode}`;
  }
  if (operationSource === "DealerPublishedCatalog") {
    return (
      <div>
        {optionLabel}
        <i className="fas fa-tools" />
      </div>
    );
  }
  return optionLabel;
}
function makeOperationId(op) {
  const { operationId, operationSource } = op;
  return `${operationId}:${operationSource}`;
}
