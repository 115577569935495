/* eslint-disable react/prop-types */
import React, { Component } from "react";
/**
 *
 * ? This Component written to apply within ag-grid container
 * @class CustomTooltip
 * @extends {Component}
 */
export default class CustomTooltip extends Component {
  componentDidMount() {
    this.props.reactContainer.className = "xmm-custom-tooltip";
  }

  render() {
    let data = "";
    /* set dynamic cell value */
    let cellValue = "";
    if (this.props.api) {
      const field = this.props.field;
      const row = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex);
      if (row) {
        data = row.data;
        cellValue = data[field];
      } else {
        // eslint-disable-next-line no-console
        // console.log(`field: ${field}, index: ${this.props.rowIndex}`);
      }
    }
    return (
      <div
        className="xmm-custom-tooltip"
        style={{ backgroundColor: this.props.color }}
      >
        <p>
          <span>{cellValue}</span>
        </p>
      </div>
    );
  }
}
