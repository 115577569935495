let translations = null;

export function xlate(msgKey, defaultMsg) {
  if (isTranslationsSet()) {
    const msg = translations[msgKey];
    return msg ? msg : `*${msgKey}*`;
  }
  handleFatalApplicationAccess({
    messageKey: msgKey,
    defaultMessage: defaultMsg
  });
}
export let locale = "en_US";
export const setTranslations = (localeStrings, localeString) => {
  translations = localeStrings;
  locale = localeString;
};
function isTranslationsSet() {
  return translations !== null;
}
export function handleFatalApplicationAccess(detail) {
  window.dispatchEvent(
    new CustomEvent("fatalApplicationAccess", {
      detail,
      bubbles: true,
      cancelable: true
    })
  );
}

export const SupportedLocales = ["en_US", "en_CA", "fr_CA", "en_AU"];

export const MakeLocaleKeyValueMap = {
  /* makes */
  "xmm.portal.makes.acura": "ACURA",
  "xmm.portal.makes.alfa_romeo": "ALFA ROMEO",
  "xmm.portal.makes.any": "ANY",
  "xmm.portal.makes.audi": "AUDI",
  "xmm.portal.makes.bently": "BENTLEY",
  "xmm.portal.makes.bmw": "BMW",
  "xmm.portal.makes.buick": "BUICK",
  "xmm.portal.makes.cadillac": "CADILLAC",
  "xmm.portal.makes.chevrolet": "CHEVROLET",
  "xmm.portal.makes.chrysler": "CHRYSLER",
  "xmm.portal.makes.citroen": "CITROEN",
  "xmm.portal.makes.dodge": "DODGE",
  "xmm.portal.makes.fiat": "FIAT",
  "xmm.portal.makes.ford": "FORD",
  "xmm.portal.makes.fpv": "FPV",
  "xmm.portal.makes.genesis": "GENESIS",
  "xmm.portal.makes.gmc": "GMC",
  "xmm.portal.makes.great_wall": "GREAT WALL",
  "xmm.portal.makes.holden": "HOLDEN",
  "xmm.portal.makes.honda": "HONDA",
  "xmm.portal.makes.hummer": "HUMMER",
  "xmm.portal.makes.hyundai": "HYUNDAI",
  "xmm.portal.makes.infiniti": "INFINITI",
  "xmm.portal.makes.isuzu": "ISUZU",
  "xmm.portal.makes.jaguar": "JAGUAR",
  "xmm.portal.makes.jeep": "JEEP",
  "xmm.portal.makes.kia": "KIA",
  "xmm.portal.makes.land_rover": "LAND ROVER",
  "xmm.portal.makes.lexus": "LEXUS",
  "xmm.portal.makes.lincoln": "LINCOLN",
  "xmm.portal.makes.mazda": "MAZDA",
  "xmm.portal.makes.mercedes_benz": "MERCEDES-BENZ",
  "xmm.portal.makes.mercury": "MERCURY",
  "xmm.portal.makes.mini": "MINI",
  "xmm.portal.makes.mitsubishi": "MITSUBISHI",
  "xmm.portal.makes.nissan": "NISSAN",
  "xmm.portal.makes.oldsmobile": "OLDSMOBILE",
  "xmm.portal.makes.peugeot": "PEUGEOT",
  "xmm.portal.makes.pontiac": "PONTIAC",
  "xmm.portal.makes.porsche": "PORSCHE",
  "xmm.portal.makes.ram": "RAM",
  "xmm.portal.makes.renault": "RENAULT",
  "xmm.portal.makes.rolls_royce": "ROLLS-ROYCE",
  "xmm.portal.makes.saab": "SAAB",
  "xmm.portal.makes.saturn": "SATURN",
  "xmm.portal.makes.scion": "SCION",
  "xmm.portal.makes.seat": "SEAT",
  "xmm.portal.makes.skoda": "SKODA",
  "xmm.portal.makes.smart": "SMART",
  "xmm.portal.makes.sprinter": "SPRINTER",
  "xmm.portal.makes.srt": "SRT",
  "xmm.portal.makes.subaru": "SUBARU",
  "xmm.portal.makes.suzuki": "SUZUKI",
  "xmm.portal.makes.toyota": "TOYOTA",
  "xmm.portal.makes.volkswagen": "VOLKSWAGEN",
  "xmm.portal.makes.volvo": "VOLVO"
};

export const MakeToLocaleKeyMap = {
  ACURA: "xmm.portal.makes.acura",
  "ALFA ROMEO": "xmm.portal.makes.alfa_romeo",
  ANY: "xmm.portal.makes.any",
  AUDI: "xmm.portal.makes.audi",
  BENTLEY: "xmm.portal.makes.bently",
  BMW: "xmm.portal.makes.bmw",
  BUICK: "xmm.portal.makes.buick",
  CADILLAC: "xmm.portal.makes.cadillac",
  CHEVROLET: "xmm.portal.makes.chevrolet",
  CHRYSLER: "xmm.portal.makes.chrysler",
  CITROEN: "xmm.portal.makes.citroen",
  DODGE: "xmm.portal.makes.dodge",
  FIAT: "xmm.portal.makes.fiat",
  FORD: "xmm.portal.makes.ford",
  FPV: "xmm.portal.makes.fpv",
  GENESIS: "xmm.portal.makes.genesis",
  GMC: "xmm.portal.makes.gmc",
  "GREAT WALL": "xmm.portal.makes.great_wall",
  HOLDEN: "xmm.portal.makes.holden",
  HONDA: "xmm.portal.makes.honda",
  HUMMER: "xmm.portal.makes.hummer",
  HYUNDAI: "xmm.portal.makes.hyundai",
  INFINITI: "xmm.portal.makes.infiniti",
  ISUZU: "xmm.portal.makes.isuzu",
  JAGUAR: "xmm.portal.makes.jaguar",
  JEEP: "xmm.portal.makes.jeep",
  KIA: "xmm.portal.makes.kia",
  "LAND ROVER": "xmm.portal.makes.land_rover",
  LEXUS: "xmm.portal.makes.lexus",
  LINCOLN: "xmm.portal.makes.lincoln",
  MAZDA: "xmm.portal.makes.mazda",
  "MERCEDES-BENZ": "xmm.portal.makes.mercedes_benz",
  MERCURY: "xmm.portal.makes.mercury",
  MINI: "xmm.portal.makes.mini",
  MITSUBISHI: "xmm.portal.makes.mitsubishi",
  NISSAN: "xmm.portal.makes.nissan",
  OLDSMOBILE: "xmm.portal.makes.oldsmobile",
  PEUGEOT: "xmm.portal.makes.peugeot",
  PONTIAC: "xmm.portal.makes.pontiac",
  PORSCHE: "xmm.portal.makes.porsche",
  RAM: "xmm.portal.makes.ram",
  RENAULT: "xmm.portal.makes.renault",
  "ROLLS-ROYCE": "xmm.portal.makes.rolls_royce",
  SAAB: "xmm.portal.makes.saab",
  SATURN: "xmm.portal.makes.saturn",
  SCION: "xmm.portal.makes.scion",
  SEAT: "xmm.portal.makes.seat",
  SKODA: "xmm.portal.makes.skoda",
  SMART: "xmm.portal.makes.smart",
  SPRINTER: "xmm.portal.makes.sprinter",
  SRT: "xmm.portal.makes.srt",
  SUBARU: "xmm.portal.makes.subaru",
  SUZUKI: "xmm.portal.makes.suzuki",
  TOYOTA: "xmm.portal.makes.toyota",
  VOLKSWAGEN: "xmm.portal.makes.volkswagen",
  VOLVO: "xmm.portal.makes.volvo"
};
