import React from "react";
import ToastContainer from "@cx/ui/Toast";

/*
 * Note: There are two ways to configure toasts:
 * 1. Globally: Set props on the ToastContainer. These props apply to all toasts on the page.
 * 2. Per toast: Specify an options object as the second parameter to toast.success, toast.info, etc.
 * The object property corresponds to the prop name. The examples below use this approach.
 */
/**
 * @class ToastDefault
 * This class to be used once within Application container
 */
const ToastDefault = () => (
  <div>
    <ToastContainer htmlId="CommonOpsToast" closeOnClick={true} />
  </div>
);

export default ToastDefault;
