import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import SettingsForm from "./SettingsForm";
import ExternalPricing from "./ExternalPricing/ExternalPricing";

class OperationTabs extends Component {
  static propTypes = {
    onChange: PropTypes.func
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: ""
    };
  }

  componentDidMount() {}

  render() {
    const Settings = (
      <SettingsForm
        rowRecord={this.props.rowRecord}
        editForm={true}
        editOption={this.props.editOption}
        updateGridAfterSave={this.props.updateGridAfterSave}
        closeSlider={this.props.closeSlider}
      />
    );
    const ExternalTab = (
      <ExternalPricing
        rowRecord={this.props.rowRecord}
        updateGridAfterSave={this.props.updateGridAfterSave}
        closeSlider={this.props.closeSlider}
      />
    );

    return (
      <Tabs htmlId="operationTabs">
        <Tab label={"Settings"} htmlId="settingsTab">
          {Settings}
        </Tab>
        <Tab
          label={"External Pricing"}
          htmlId="externalTab"
          disabled={this.props.editOption === "add"}
        >
          {ExternalTab}
        </Tab>
      </Tabs>
    );
  }
}

export default OperationTabs;

OperationTabs.propTypes = {
  rowRecord: PropTypes.object,
  editForm: PropTypes.bool,
  editOption: PropTypes.string,
  closeSlider: PropTypes.func,
  updateGridAfterSave: PropTypes.func
};
