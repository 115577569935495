/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
// import SearchableSelect from "@cx/ui/SearchableSelect";
import SearchPane from "./SearchPane";
import VehicleCard from "./VehicleCard";
import LaborPartsPane from "./LaborPartsPane";
import "../../../../../previewGrids/index.scss";
// import ResultsPane from "../../../../../previewGrids/components/ResultsPane";
import { PreviewContext, initPreviewState } from "./../../preview-ops-context";
import {
  defaultToZeroIfNullOrEmpty,
  findRecord,
  sleep
} from "../../../utils/helper";
// import { getPartsAndLaborAndTotal } from "../../../../../previewGrids/components/utils/helper";
import { round } from "../../../utils/value";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import { toast } from "@cx/ui/Toast";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
// import PickyFilter from "../../../filters/PickyFilter";
// import { isSameValue } from "../../../../../commonUtil/utils/string";
import ServiceRecommendationPane from "./ServiceRecommendationPane";
import FiltersPane from "./FiltersPane";

const validStatuses = ["IN-TESTING", "READY"];

/**
 * PreviewPane Class description.
 * E.g:
 * ```html
 * <PreviewPane locale={"en_US"} previewState={state} />
 * ```
 */
class PreviewPane extends React.Component {
  constructor(props) {
    super(props);

    this.filtersPaneRef = React.createRef();

    this.decodeVin = this.decodeVin.bind(this);
    this.getAllOperations = this.getAllOperations.bind(this);
    // this.selectOperation = this.selectOperation.bind(this);
    this.updateVehicleInfo = this.updateVehicleInfo.bind(this);
    this.updateMask = this.updateMask.bind(this);
    this.updateShowFilter = this.updateShowFilter.bind(this);
    this.populateContext = this.populateContext.bind(this);
    // this.onCategoryGroup = this.onCategoryGroup.bind(this);
    // this.onSelectOperation = this.onSelectOperation.bind(this);
    this.selectOperationByCategoryId =
      this.selectOperationByCategoryId.bind(this);
    this.resetLaborPartsTotal = this.resetLaborPartsTotal.bind(this);
    // this.onSelectLabels = this.onSelectLabels.bind(this);
    // read App context values passed as props
    const { appContext, previewState } = props;
    const { role, versionRowSelected } = appContext;
    const version = this.hasSelectedVersion(versionRowSelected)
      ? versionRowSelected[0].version
      : null;
    // const { operationId, selectedOperation } = previewState;
    this.state = {
      role,
      version,
      showMask: false, // flag to show mask when ResultPane loading
      showFilter: previewState.showFilter,
      decodeVinOk: previewState.decodeVinOk,
      acesAttributes: previewState.acesAttributes,
      undecodedAttributes: previewState.undecodedAttributes,
      vin: previewState.vin,
      dealerCode: previewState.dealerCode,
      dealerFlag: previewState.dealerFlag,
      mileage: previewState.mileage || "",
      vehicleDesc: previewState.vehicleDesc,
      errors: { vin: "", operations: "", dealerCode: "" },
      laborPartsTotal: {
        isPublishedCatalog: false,
        laborRate: 0,
        laborTime: 0,
        partsTotal: 0,
        laborTotal: 0,
        total: 0
      },
      priceOverrides: null,
      operationId: previewState.selectedOperation
        ? previewState.selectedOperation.value
        : "",
      selectedOperation: previewState.selectedOperation,
      operationList: previewState.operationList,
      selectableOperations: previewState.selectableOperations,
      partsAndLabor: previewState.partsAndLabor,
      selectedLabors: previewState.selectedLabors,
      selectedParts: previewState.selectedParts,
      selectedLabels: previewState.selectedLabels,
      globalOperationlabelsMap: {},
      selectedCategoryGroups: previewState.selectedCategoryGroups || [],
      categoryGroups: previewState.categoryGroups || [],
      serviceRecommendations: previewState.serviceRecommendations || [],
      versionRowSelected: null,
      opsByYMM: null
    };
  }
  componentDidMount() {
    const { previewState } = this.props;
    const { operationList } = previewState;
    if (operationList && operationList.length > 0) {
      this.resetLaborPartsTotal();
    }
    // this.loadLabels();
    // this.loadGlobalOperationLabels();
    this.updateModalTitleWithVersionAndSchema();
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    // this.updatePreviewState();
  }
  // updatePreviewState = () => {
  //   const {
  //     showFilter,
  //     selectedLabels,
  //     selectableOperations,
  //     selectedOperation,
  //     categoryGroups,
  //     selectedCategoryGroups,
  //     operationList,
  //     partsAndLabor,
  //     selectedLabors,
  //     selectedParts,
  //     acesAttributes,
  //     undecodedAttributes,
  //     vehicleDesc,
  //     vin,
  //     dealerCode,
  //     decodeVinOk,
  //     dealerFlag,
  //     mileage,
  //     serviceRecommendations,
  //     opsByYMM
  //   } = this.state;
  //   const previewState = {
  //     showFilter,
  //     selectedLabels,
  //     categoryGroups,
  //     selectedCategoryGroups,
  //     selectedOperation,
  //     selectableOperations,
  //     operationList,
  //     partsAndLabor,
  //     selectedLabors,
  //     selectedParts,
  //     acesAttributes,
  //     undecodedAttributes,
  //     vehicleDesc,
  //     vin,
  //     dealerCode,
  //     decodeVinOk,
  //     dealerFlag,
  //     mileage,
  //     serviceRecommendations,
  //     opsByYMM,
  //     versionRowSelected: []
  //   };
  //   this.props.savePreviewState(previewState);
  // };
  populateContext = (key, value) => {
    this.setState(
      state => ({
        ...state,
        [key]: value
      }),
      () => {
        // callback
      }
    );
  };
  updateYMM = data => {
    this.setState({ opsByYMM: data });
  };

  // Update grand-parent {preview-context}, from grand children {search-pane, results grid etc }
  updateVehicleInfo = vehicle => {
    sleep(1000).then(() => {
      console.log("preview context vehicle", vehicle);
      this.setState(prevState => {
        return { vehicle };
      });
    });
  };
  setLaborPartsTotal = (laborPartsTotal, priceOverrides) => {
    if (priceOverrides) {
      this.setState({ laborPartsTotal, priceOverrides });
    } else {
      this.setState({ laborPartsTotal });
    }
  };
  selectGridRow = (selectedRows, type) => {
    // laborPartsTotal: { laborRate: 100, laborTime: 1.25, partsTotal: 0 },
    const { laborPartsTotal } = this.state;
    if (type === "labor") {
      let laborTime = 0;
      selectedRows.forEach(labor => {
        const { quantity, laborHours } = labor;
        laborTime +=
          defaultToZeroIfNullOrEmpty(quantity) *
          defaultToZeroIfNullOrEmpty(laborHours);
      });
      const newLaborPartsTotal = { ...laborPartsTotal, laborTime };
      const selectedLabors = selectedRows;
      this.setState({ selectedLabors, laborPartsTotal: newLaborPartsTotal });
    } else if (type === "parts") {
      let partsTotal = 0;
      selectedRows.forEach(parts => {
        const { price, quantity } = parts;
        partsTotal +=
          defaultToZeroIfNullOrEmpty(price) *
          defaultToZeroIfNullOrEmpty(quantity);
      });
      const newLaborPartsTotal = {
        ...laborPartsTotal,
        partsTotal: round(partsTotal)
      };
      const selectedParts = selectedRows;
      this.setState({ selectedParts, laborPartsTotal: newLaborPartsTotal });
    }
  };
  getServiceRecommendationMileage(year) {
    const now = new Date();
    const years = now.getFullYear() - year;
    return years * 10000;
  }
  getServiceRecommendations(dealerCode, mileage, categoryIdToServiceListMap) {
    const { vehicleDesc, vin } = this.state;
    const { id, year } = vehicleDesc;
    const data = Object.assign({}, serviceRecommendationPostData);
    const { vehicle } = data;
    vehicle.dealerCode = dealerCode;
    vehicle.vin = vin;
    vehicle.metaVehicleId = id ? id.toString() : "";
    vehicle.mileage = mileage
      ? Number(mileage)
      : this.getServiceRecommendationMileage(year);
    const { serviceApi } = this.props;
    const request = {
      url: "/opsadmin/recommendations/getRecommendations",
      method: "post",
      data
    };
    serviceApi(
      request,
      response => {
        const { data, errors } = response;
        let serviceRecommendations = [];
        if (data) {
          const { serviceRecommendation } = data;
          if (serviceRecommendation) {
            serviceRecommendations = serviceRecommendation;
            serviceRecommendations.forEach(sr => {
              const { serviceCategoryId } = sr;
              const linkedServices =
                categoryIdToServiceListMap[serviceCategoryId];
              sr.linkedServices = linkedServices;
            });
          }
        }
        this.setState({ mileage: vehicle.mileage, serviceRecommendations });
        console.log("getRecommendations errors:", errors);
        // if (errors && errors.length !== 0) {
        //   const { message } = errors[0];
        //   if (message) {
        //     toast.info(message);
        //   } else {
        //     toast.error("Unknown error in getting service recommendations.");
        //   }
        // }
      },
      error => {
        let { message } = error;
        if (!message) {
          message = "Error in getting service recommendatons.";
        }
        toast.error(error.message);
      }
    );
  }

  updateModalTitleWithVersionAndSchema() {
    const { appContext } = this.props;
    const { versionRowSelected } = appContext;
    if (this.hasSelectedVersion(versionRowSelected)) {
      this.uppdateVersionSchema(versionRowSelected[0]);
    } else {
      this.getLiveVersionAndMotorSchema();
    }
  }

  hasSelectedVersion(versionRowSelected) {
    return (
      versionRowSelected &&
      versionRowSelected.length &&
      validStatuses.includes(versionRowSelected[0].status)
    );
  }

  uppdateVersionSchema(versionRowSelected) {
    const { version, motorSchema, status, liveFlag } = versionRowSelected;
    this.props.updateModalTitleWithVersion(
      version,
      motorSchema,
      status,
      liveFlag
    );
  }

  getLiveVersionAndMotorSchema() {
    const restUrl = "/opsadmin/rest-db/getValues/liveReadyVersion";

    const { serviceApi } = this.props;
    serviceApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        console.log(response);
        if (response && Array.isArray(response) && response.length !== 0) {
          response[0].liveFlag = true;
          this.uppdateVersionSchema(response[0]);
          // const { version, motorSchema, status } = response[0];
          // const liveFlag = true;
          // this.props.updateModalTitleWithVersion(
          //   version,
          //   motorSchema,
          //   status,
          //   liveFlag
          // );
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  updateVin = value => {
    this.setState({ vin: value });
  };
  getAllOperations(dealerCode, mileage) {
    this.resetLaborPartsTotal();
    this.resetSelectedOperation();
    const { serviceApi, appContext } = this.props;
    const { opsByYMM, vin, vehicleDesc } = this.state;
    let { make } = vehicleDesc;
    if (!make) {
      make = "ANY";
    }
    const params = !dealerCode ? {} : { dealerCode };

    // reset operation list and partsAndLabor
    this.filtersPaneRef &&
      this.filtersPaneRef.current &&
      this.filtersPaneRef.current.resetSelectedOperation();

    if (opsByYMM !== null) {
      params.filterOpsByYMM = opsByYMM ? 1 : 0;
    }
    if (appContext.versionRowSelected && appContext.versionRowSelected.length) {
      const status = appContext.versionRowSelected[0].status;
      if (validStatuses.includes(status)) {
        const version = appContext.versionRowSelected[0].version;
        params.version = version;
      }
    }

    const request = {
      url: `/opsadmin/operations/getAllOperationsList/make/${make}/vin/${vin}`,
      method: "get",
      params
    };
    this.updateMask(true);
    serviceApi(
      request,
      response => {
        const { errors } = this.state;
        const { success, operations, message } = response;
        errors.dealerCode = success ? "" : message;
        if (success && message) {
          toast.info(message);
        }
        if (success) {
          const categoryIdToServiceListMap = {};
          operations.forEach(op => {
            const { operationName, categoryId } = op;
            op.label = `${operationName}`;
            op.value = makeOperationId(op); // `${operationId}:${operationSource}`;
            if (categoryId) {
              const list = categoryIdToServiceListMap[categoryId];
              if (list) {
                list.push(op);
              } else {
                categoryIdToServiceListMap[categoryId] = [op];
              }
            }
          });
          operations.sort((a, b) => {
            return a.operationName > b.operationName ? 1 : -1;
          });
          this.setState(
            {
              dealerCode,
              showFilter: true,
              showMask: false,
              operationList: operations,
              selectableOperations: [...operations],
              msgBox: "",
              partsAndLabor: null,
              errors: { ...errors }
            },
            () => {
              // call service recommendations api
              // if (dealerCode) {
              //   this.getServiceRecommendations(
              //     dealerCode,
              //     mileage,
              //     categoryIdToServiceListMap
              //   );
              // }
              this.updateMask(false);
            }
          );
        } else {
          this.setState({
            dealerCode,
            showFilter: true,
            showMask: false,
            operationList: [],
            partsAndLabor: null,
            msgBox: "No operations for this VIN",
            errors: { ...errors }
          });
        }
      },
      error => {
        // let { message } = error;
        const { response } = error;
        const { success, message } = response;
        if (!success && message) {
          const { errors } = this.state;
          errors.dealerCode = message;
          this.setState({
            dealerCode,
            showFilter: true,
            operationList: [],
            partsAndLabor: null,
            msgBox: message,
            errors: { ...errors }
          });
        }
        // this.updateMask(false);
      }
    );
  }
  resetSelectedOperation() {
    this.setState({
      operationId: "",
      selectedOperation: null,
      partsAndLabor: null,
      priceOverrides: {}
    });
  }
  resetLaborPartsTotal() {
    const { laborPartsTotal } = this.state;
    const newLaborPartsTotal = {
      ...laborPartsTotal,
      isPublishedCatalog: false,
      laborRate: 100,
      laborTime: 0,
      partsTotal: 0,
      laborTotal: 0,
      total: 0
    };
    this.setState({ laborPartsTotal: newLaborPartsTotal, priceOverrides: {} });
  }
  decodeVin(vin) {
    this.resetLaborPartsTotal();
    const { serviceApi } = this.props;
    const request = {
      url: `/opsadmin/proxyapi/internaloeproxy/rest/internal/v1/inspect/decodevin/${vin}`,
      method: "get"
    };
    this.updateMask(true);
    this.updateShowFilter(false);
    serviceApi(
      request,
      response => {
        console.log("decode vin resp", response);
        const { success, data } = response;
        if (success) {
          if (data && data.vehicleDesc) {
            const { vehicleDesc } = data;
            const decodeVinOk = true;
            this.setState({ decodeVinOk, vehicleDesc });
            const request2 = {
              url: `/opsadmin/motor/vintoaces/${vin}`,
              method: "get"
            };
            serviceApi(
              request2,
              acesReponse => {
                this.updateMask(false);
                if (acesReponse) {
                  const { aces, undecoded } = acesReponse;
                  const acesAttributes = aces;
                  const undecodedAttributes = undecoded;
                  this.setState({
                    acesAttributes,
                    undecodedAttributes,
                    errors: {
                      vin: "",
                      operations: "",
                      dealerCode: ""
                    },
                    vin
                  });
                } else {
                  this.setState({
                    acesAttributes: [],
                    undecodedAttributes: [],
                    errors: {
                      vin: "",
                      operations: "",
                      dealerCode: ""
                    },
                    vin: ""
                  });
                }
              },
              error => {
                let { message } = error;
                if (!message) {
                  message = "Error in decoding vin into aces attributes...";
                }
                const { errors } = this.state;
                errors.vin = "Failed to decode Aces attributes.";
                this.setState({
                  decodeVinOk: false,
                  vehicleDesc: {},
                  errors: { ...errors }
                });
                this.updateMask(false);
                toast.error(error.message);
              }
            );
          } else {
            const { errors } = this.state;
            const message = "The VIN can't be decoded.";
            errors.vin = message;
            this.setState({
              decodeVinOk: false,
              vehicleDesc: {},
              errors: { ...errors }
            });
            this.updateMask(false);
            // toast.error(message);
          }
        } else {
          // Case - when VIN is validated, status=200 and success=false
          const { errorCode } = response;
          let { message } = response;
          if (errorCode && !message) {
            message = "An invalid VIN";
          }
          console.log("else case", message);
          const { errors } = this.state;
          errors.vin = "An invalid VIN";
          this.setState({
            vin,
            decodeVinOk: false,
            acesAttributes: [],
            undecodedAttributes: [],
            vehicleDesc: {},
            errors: { ...errors }
          });
          this.updateMask(false);
        }
      },
      error => {
        let { message } = error;
        if (!message) {
          message = "Error in decoding vin...";
        }
        const { errors } = this.state;
        errors.vin = "Failed to decode VIN.";
        this.setState({ acesAttributes: [], errors });
        toast.error(error.message);
        this.updateMask(false);
      }
    );
  }
  // Update mask state when Get services button is clicked; decode button clicked
  updateMask = isReady => {
    this.setState(
      {
        showMask: isReady
      },
      () => {
        this.filtersPaneRef &&
          this.filtersPaneRef.current &&
          this.filtersPaneRef.current.updateMask(isReady);
      }
    );
  };
  updateShowFilter = show => {
    this.setState({
      showFilter: show
    });
  };
  findOperation(operationId) {
    const { operationList } = this.state;
    const selectedOp = findRecord(operationList, "value", operationId);
    if (selectedOp && Object.keys(selectedOp).length > 0) {
      let msg = "";
      if (selectedOp.operationSource === "DealerPublishedCatalog") {
        msg = "Displaying data from Dealer Catalog";
      } else if (selectedOp.operationSource === "GlobalCatalog") {
        msg = "Displaying raw data from Motor";
      }
      this.setState(
        {
          selectedLaborRowCount: 0,
          operation: selectedOp,
          operationId,
          msg,
          showMask: true
        },
        () => {
          if (this.filtersPaneRef && this.filtersPaneRef.current) {
            this.filtersPaneRef.current.selectOperation(selectedOp);
          }
        }
      );
    } else {
      this.setState({ msg: "", showMask: false });
    }
  }

  globalOpsPreviewApi = (request, resultHandler, errorHandler) => {
    const { serviceApi } = this.props;
    const url = globalOpsPreviewApiMap[request.apiKey];
    if (url) {
      request.url = url;
      if (request.apiKey === "getPartsPricingAndInventory" && request.data) {
        request.data.appSource = "CatalogManagerPreview";
      }
    }
    serviceApi(request, resultHandler, errorHandler);
  };

  selectOperationByCategoryId = serviceCategoryId => {
    return (
      this.filtersPaneRef &&
      this.filtersPaneRef.current &&
      this.filtersPaneRef.current.selectOperationByCategoryId(serviceCategoryId)
    );
  };

  render() {
    const { locale, previewState, appContext } = this.props;

    const {
      // mask,
      msgBox,
      showFilter,
      showMask,
      vin,
      dealerCode,
      dealerFlag,
      decodeVinOk,
      mileage,
      vehicleDesc,
      acesAttributes,
      undecodedAttributes,
      // selectedOperation,
      selectableOperations,
      operationList,
      // operationId,
      errors,
      laborPartsTotal,
      priceOverrides,
      // partsAndLabor,
      // selectedLabors,
      // selectedParts,
      // selectedCategoryGroups,
      // categoryGroups,
      serviceRecommendations,
      version
    } = this.state;
    /* set localstate to preview context here */
    const contextValue = {
      vin,
      dealerCode,
      dealerFlag,
      locale,
      decodeVinOk,
      mileage,
      vehicleDesc,
      acesAttributes,
      undecodedAttributes,
      // selectedOperation,
      selectableOperations,
      operationList,
      // operationId,
      laborPartsTotal,
      priceOverrides,
      // partsAndLabor,
      // selectedLabors,
      // selectedParts,
      errors,
      updateMask: this.updateMask,
      populateContext: this.populateContext,
      updateVehicleInfo: this.updateVehicleInfo,
      updateYMM: this.updateYMM
    };
    /* placeholder to show loading mask */
    let results = null;
    let maskBlock = null;
    // let msgBox = null;
    if (showMask) {
      maskBlock = <LoadingIndicator htmlId="previewMask" size="large" />;
    }
    if (!showFilter) {
      results = maskBlock;
    } else {
      // let operationLabel = "Operations";
      // if (selectableOperations && selectableOperations.length > 0) {
      //   operationLabel = "Operations (" + selectableOperations.length + ")";
      // } else {
      //   msgBox = "No operations for this VIN";
      // }
      let recommendedServicesAccordion = "";
      if (dealerFlag === "DEPRICATED") {
        recommendedServicesAccordion = (
          <ServiceRecommendationPane
            serviceRecommendations={serviceRecommendations}
            selectOperationByCategoryId={this.selectOperationByCategoryId}
          />
        );
      }
      results = (
        <Col xs={12} md={12}>
          <Row>
            <Col xs={12} md={12}>
              {recommendedServicesAccordion}
            </Col>
          </Row>
          <FiltersPane
            ref={this.filtersPaneRef}
            showFilter={showFilter}
            msgBox={msgBox}
            operationList={operationList}
            dealerCode={dealerCode}
            dealerFlag={dealerFlag}
            version={version}
            serviceRecommendations={serviceRecommendations}
            vehicleDesc={vehicleDesc}
            vin={vin}
            decodeVinOk={decodeVinOk}
            appContext={appContext}
            previewState={previewState}
            savePreviewState={this.props.savePreviewState}
            serviceApi={this.globalOpsPreviewApi}
            resetLaborPartsTotal={this.resetLaborPartsTotal}
            setLaborPartsTotal={this.setLaborPartsTotal}
          />
        </Col>
      );
    }
    return (
      <React.Fragment>
        <PreviewContext.Provider value={contextValue}>
          <div className="ops-sticky-section">
            <SearchPane
              updateVin={this.updateVin}
              vin={vin}
              decodeVin={this.decodeVin}
              decodeVinOk={decodeVinOk}
              getAllOperations={this.getAllOperations}
              errors={errors}
            />

            <Row>
              <Col xs={12} md={12}>
                <hr />
              </Col>
            </Row>
            <Row className="ops-labor-panel">
              <Col xs={6} md={4}>
                <VehicleCard
                  vehicleDesc={vehicleDesc}
                  acesAttributes={acesAttributes}
                  undecodedAttributes={undecodedAttributes}
                />
              </Col>
              <Col xs={6} md={8}>
                <LaborPartsPane
                  laborPartsTotal={laborPartsTotal}
                  priceOverrides={priceOverrides}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={12} md={12}>
              <hr />
            </Col>
          </Row>
          <Row>{results}</Row>
        </PreviewContext.Provider>
      </React.Fragment>
    );
  }
}
export default PreviewPane;

PreviewPane.propTypes = {
  savePreviewState: PropTypes.func,
  updateModalTitleWithVersion: PropTypes.func,
  appContext: PropTypes.object,
  previewState: PropTypes.object,
  serviceApi: PropTypes.func,
  locale: PropTypes.string
};

PreviewPane.defaultProps = {
  serviceApi: () => {
    // handle function here
  },
  savePreviewState: () => {
    // handle function here
  },
  previewState: initPreviewState
};
/* eslint-enable no-console */

const globalOpsPreviewApiMap = {
  getOperationDetail: "",
  getPartsPricingAndInventory: "",
  uniqueLabels: "",
  globalOpsWithLabels: ""
};

const serviceRecommendationPostData = {
  vehicle: {
    vin: "",
    metaVehicleId: "",
    mileage: 10000,
    dealerCode: "",
    services: [
      // {
      //   opCode: "ROT",
      //   serviceCategory: "TIRES - ROTATE",
      //   serviceId: 234765,
      //   dealerCode: "XTIMEMOTORS",
      //   serviceName: "Rotate Tires"
      // },
      // {
      //   opCode: "FLT",
      //   serviceCategory: "CABIN AIR FILTER - R&R",
      //   serviceId: 234766,
      //   dealerCode: "XTIMEMOTORS",
      //   serviceName: "Air filter replacement"
      // }
    ]
  }
};

// function customSelectableItem(option) {
//   const { label, operationSource, opCode } = option;
//   let optionLabel = label;
//   if (optionLabel && opCode) {
//     optionLabel += ` - ${opCode}`;
//   }
//   if (operationSource === "DealerPublishedCatalog") {
//     return (
//       <div>
//         {optionLabel}
//         <i className="fas fa-tools" />
//       </div>
//     );
//   }
//   return optionLabel;
// }
function makeOperationId(op) {
  const { operationId, operationSource } = op;
  return `${operationId}:${operationSource}`;
}
