/* eslint-disable no-console */
import React, { useState, useContext } from "react";
import { PropTypes } from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import { AppContext } from "./../../app-context";
import { makeSecureRestApi } from "../../../api/xmmAxios";
// For Development - This line to import component from git-sub module
import { CatalogPreview } from "../../../operationPreview/src";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";

// PROD Release - Here we import shared component from npm package (published)
// import { CatalogPreview } from "@xtime/commonops-preview-common";

function PreviewModal(props) {
  const appContext = useContext(AppContext);
  const { locale, localeStrings, previewState } = appContext;
  // useEffect - Similar to componentDidMount and componentDidUpdate
  // useEffect(() => {
  // });

  // const [version, setVersion] = useState("");
  // const [motorSchema, setMotorSchema] = useState("");
  // const [liveFlag, setLiveFlag] = useState("");
  const [versionSchemaTip, setVersionSchemaTip] = useState("");

  const hide = () => {
    props.closeModal();
  };

  const savePreviewState = preview => {
    appContext.savePreviewState(preview);
  };

  const updateModalTitleWithVersion = (
    version,
    motorSchema,
    status,
    liveFlag
  ) => {
    let versionStatus = "Ready/Live";
    if (status === "IN-TESTING") {
      versionStatus = "In Testing";
    } else if (!liveFlag) {
      versionStatus = "Ready";
    }
    const tip = `Previewing Data from Version ${version} with Motor Schema ${motorSchema}. Version is ${versionStatus}.`;
    setVersionSchemaTip(tip);
  };

  const helpTip = versionSchemaTip ? (
    <Tooltip htmlId="opsTitleTip" tooltipContent={versionSchemaTip}>
      <IconInfoOutline className="info" />
    </Tooltip>
  ) : (
    ""
  );

  const modalHeader = (
    <ModalDialog.Title>
      <div className="ops-notes">
        {localeStrings["xmmadmin.common.global_ops_preview_lbl"]} {helpTip}
      </div>
    </ModalDialog.Title>
  );
  return (
    <React.Fragment>
      <ModalDialog
        htmlId="PreviewModal"
        show={props.show}
        onHide={hide}
        backdrop={"static"}
        header={modalHeader}
        className="xmm-preview-modal xmm-large-header"
      >
        <CatalogPreview
          locale={locale}
          serviceApi={makeSecureRestApi}
          previewState={previewState}
          appContext={appContext}
          savePreviewState={savePreviewState}
          updateModalTitleWithVersion={updateModalTitleWithVersion}
        />
      </ModalDialog>
    </React.Fragment>
  );
}

export default PreviewModal;

PreviewModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  okAction: PropTypes.func
};
/* eslint-enable no-console */
