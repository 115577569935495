const ApiTokenPartMap = {
  A: "RGF2aWQgQ2hpIExlZQ==",
  B: "U2FjaGluIEIgTW9kaQ==",
  C: "Sm9obmF0aGFuIFRvbGR0",
  D: "RGluZXNoIENoYW5kcmE=",
  E: "U2FieWEgTXVraGVyamVl",
  F: "UmFqYSBQYXN1cHVsZXRp",
  G: "Um9uZyBKZW5uIENoYW5n",
  H: "WXUgR2VuZ2hpcyBLYW5n",
  I: "VmFpYmhhdiBHdXB0YQ==",
  J: "R29wYWwgUHVzdWx1cmk="
};
const baseCharCode = "A".charCodeAt(0); // = 65

export default function generateApiToken() {
  let tokens = "";
  for (let i = 0; i < 3; i++) {
    const index = Math.floor(Math.random() * 9);
    const key = String.fromCharCode(baseCharCode + index);
    tokens += ApiTokenPartMap[key] + key;
  }
  return tokens;
}
