import { baseLoginUrl, namespace } from "../../api/xmmAxios";

export function launchCatalogManager(webKey) {
  let url = baseLoginUrl;
  if (webKey) {
    url += "/?pt=cm";
    if (!["prod", "prodaus"].includes(namespace)) {
      url += `&cmenv=${namespace}`;
    }
    url += `&webKey=${webKey}`;
  }
  window.open(url, webKey);
}
