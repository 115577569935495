/* eslint-disable no-console */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { AppContext } from "../../../../../components/app-context";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import Button from "@cx/ui/Button";
import TextInput from "@cx/ui/TextInput";
import IconMore from "@cx/ui/Icons/IconMore";
import Dropdown from "@cx/ui/Dropdown";
import TextArea from "@cx/ui/TextArea";
import SelectInput from "@cx/ui/SelectInput";
import SearchableSelect from "@cx/ui/SearchableSelect";
import {
  isDifferentValue,
  toEmptyStringIfUndefined
} from "../../../../../commonUtil/utils/string";
import { toast } from "@cx/ui/Toast";
import { makeSecureRestApi } from "../../../../../api/xmmAxios";
import { EditOperationRecord } from "../../../../../constants/ModuleConstants";
import { xlate } from "../../../../../commonUtil/i18n/locales";
import Confirmation from "../../../../../commonUtil/dialog/Confirmation";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";

class SettingsForm extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rowRecord !== prevState.rowRecord) {
      return {
        newRecord: JSON.parse(JSON.stringify(nextProps.rowRecord)),
        rowRecord: nextProps.rowRecord,
        editOption: nextProps.editOption
      };
    }
    return null;
  }
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSearchableSelectChange = this.onSearchableSelectChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onCopyOperation = this.onCopyOperation.bind(this);
    this.onChangeWaiterAllowed = this.onChangeWaiterAllowed.bind(this);
    this.onChangeLoanerAllowed = this.onChangeLoanerAllowed.bind(this);
    const { rowRecord } = props;
    const newRecord = JSON.parse(JSON.stringify(rowRecord));
    const { globalOpsData } = context;
    const { positionOptions } = globalOpsData;
    this.initMsgs();
    this.state = {
      rowRecord,
      newRecord,
      editOption: props.editOption,
      addMode: !newRecord.id ? true : false,
      warning: "",
      dirty: false,
      valid: false,
      errors: this.getErrors(),
      serviceCategories: [],
      positionOptions,
      labels: []
    };
  }

  componentDidMount() {
    this.loadServiceCategories();
    this.loadLabels();
    window.dispatchEvent(
      new CustomEvent("formHandleEvent", {
        detail: this,
        bubbles: true,
        cancelable: true
      })
    );
  }
  initMsgs() {
    this.copyOperationConfirmMessage = xlate(
      "xmmadmin.portal.common.copy_operation_confirm_msg"
    );
  }

  loadServiceCategories() {
    const restUrl = "/opsadmin/rest-db/getValues/serviceCategories";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      serviceCategories => {
        if (serviceCategories) {
          console.log(serviceCategories);
          serviceCategories.forEach(category => {
            const { serviceCategoryId, defaultServiceName } = category;
            category.label = `${defaultServiceName} (${serviceCategoryId})`;
            category.value = serviceCategoryId.toString();
          });
          this.setState({ serviceCategories });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  onChangeWaiterAllowed = event => {
    const target = event.target;
    const { name } = target;
    const value = target.checked ? true : false;
    if (isDifferentValue(this.state.newRecord[name], value)) {
      this.markDirty(name, true);
    }
    this.setState(
      prevState => {
        const { errors } = prevState;
        return {
          newRecord: {
            ...prevState.newRecord,
            [name]: value
          },
          errors
        };
      },
      () => {
        // callback logic
      }
    );
  };
  onChangeLoanerAllowed = event => {
    const target = event.target;
    const { name } = target;
    const value = target.checked ? true : false;
    if (isDifferentValue(this.state.newRecord[name], value)) {
      this.markDirty(name, true);
    }
    this.setState(
      prevState => {
        const { errors } = prevState;
        return {
          newRecord: {
            ...prevState.newRecord,
            [name]: value
          },
          errors
        };
      },
      () => {
        // callback logic
      }
    );
  };
  loadLabels() {
    const restUrl = "/opsadmin/rest-db/getValues/uniqueLabels";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      datalist => {
        if (datalist) {
          console.log(datalist);
          const labels = datalist
            .filter(l => l.label !== null)
            .map(lbl => {
              const { label } = lbl;
              lbl.label = label.toString();
              lbl.value = lbl.label;
              return lbl;
            });
          labels.sort((a, b) => {
            const label1 = a.label.toLowerCase();
            const label2 = b.label.toLowerCase();
            return label1 === label2 ? 0 : label1 > label2 ? 1 : -1;
          });
          this.setState({ labels });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }

  getErrors() {
    return {
      name: "",
      categoryName: "",
      labels: "",
      serviceKind: "",
      description: "",
      duration: "",
      position: ""
    };
  }

  // validate value on blur event
  // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput, TextArea
  onBlur = (cxEvent, isValid, domEvent) => {
    // const { name, id } = cxEvent.target;
    // const endIndex = id.indexOf("-") !== -1 ? id.indexOf("-") : id.length;
    // const fieldName = name !== "" ? name : id.substring(0, endIndex);
    // const valid = this.validate(fieldName);
    const { name, id } = cxEvent.target;
    const fieldName = name || id;
    const valid = this.validate(fieldName);
    this.setState({ valid });
  };
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput, PriceInput
    // if (domEvent && domEvent.type === "blur") {
    //   // console.log("blur", name, value);
    //   this.validate(name);
    //   return;
    // }
    if (isDifferentValue(this.state.newRecord[name], value)) {
      this.markDirty(name);

      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            newRecord: {
              ...prevState.newRecord,
              [name]: value
            },
            errors
          };
        },
        () => {
          // callback logic
        }
      );
    }
  };

  onSearchableSelectChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput, PriceInput
    // if (domEvent && domEvent.type === "blur") {
    //   // console.log("blur", name, value);
    //   this.validate(name);
    //   return;
    // }
    let newValue = name === "labels" ? [] : null;
    if (value && Array.isArray(value) && value.length !== 0) {
      if (name === "serviceCategoryId") {
        const newValues = value.map(v => (v.value ? v.value : v));
        newValue = newValues[0];
      } else if (name === "labels") {
        newValue = value.map(v => (v.label ? v.label : v));
      } else if (name === "positionId") {
        const newValues = value.map(v => (v.value ? v.value : v));
        newValue = newValues[0];
      }
    }
    const { newRecord } = this.state;
    if (isDifferentValue(newRecord[name], newValue)) {
      this.markDirty(name, true);
      newRecord[name] = newValue;
      if (name === "serviceCategoryId") {
        newRecord.categoryName = !newValue ? "" : value[0].defaultServiceName;
      }
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            newRecord,
            errors
          };
        },
        () => {
          // callback logic
        }
      );
    }
  };

  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    let valid = true;
    const { errors, newRecord } = this.state;
    // if (fieldName === "name") {
    if (!newRecord["name"]) {
      errors[fieldName] = xlate("xmm.portal.errors.name_required");
      valid = false;
    } else {
      errors[fieldName] = "";
    }
    // this.setState({ errors, valid: false });
    // }
    return valid;
  }
  // call this for each field change event
  markDirty(fieldName, validate) {
    if (validate) {
      const valid = this.validate(fieldName);
      this.setState({ dirty: true, valid });
    } else {
      this.setState({ dirty: true });
    }
  }
  isDirty = () => {
    return this.state.dirty;
  };
  handleSave = e => {
    const { editOption, newRecord } = this.state;
    const {
      name,
      description,
      operationId,
      labels,
      serviceCategoryId,
      positionId,
      vehicles,
      waiterAllowed,
      loanerAllowed
    } = newRecord;
    const data = Object.assign({}, EditOperationRecord);
    if (editOption === "edit") {
      data.id = operationId;
    }
    data.name = name;
    data.internalName = name;
    data.description = description;
    data.serviceCategoryId = serviceCategoryId;
    data.labels = labels;
    data.positionId = positionId;
    data.vehicles = vehicles;
    data.waiterAllowed = waiterAllowed;
    data.loanerAllowed = loanerAllowed;

    const restUrl =
      editOption === "add"
        ? "/opsadmin/rest-db/addTableData/operation"
        : "/opsadmin/rest-db/updateTableData/operation";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      result => {
        const { editOption, updateGridAfterSave } = this.props;
        if (Array.isArray(result) && result.length !== 0) {
          newRecord.id = result[0].operationId.toString();
          newRecord.operationId = result[0].operationId.toString();
        }
        updateGridAfterSave(newRecord, editOption);
        this.setState({ dirty: false, valid: false, editOption: "edit" });
        if (editOption === "edit") {
          toast.success(result);
        } else {
          toast.success("1 row added.");
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  onCopyOperation = (cxEvent, domEvent) => {
    this.setState({
      showCopyModal: true
    });
  };
  closeCopyModal = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showCopyModal: false
    });
  };
  copyOperationHandler = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showCopyModal: false
    });
    const restAPI = "/opsadmin/rest-db/function/copyOperation";
    const { rowRecord } = this.state;
    const { id } = rowRecord;
    makeSecureRestApi(
      {
        url: restAPI,
        method: "post",
        data: { operationId: id }
      },
      response => {
        console.log(response);
        const copyOperation = response ? response["copy_operation"] : null;
        if (copyOperation) {
          const { value } = copyOperation;
          const rowRecord = JSON.parse(value);
          if (rowRecord.hasOwnProperty("errorCode")) {
            const { errorMessage } = rowRecord;
            toast.error(errorMessage, {
              closeOnClick: true
            });
            return;
          }
          rowRecord.id = rowRecord.operationId.toString();
          if (rowRecord.labels) {
            const { labels } = rowRecord.labels;
            if (labels && Array.isArray(labels)) {
              rowRecord.labels = labels;
            } else {
              rowRecord.labels = [];
            }
          } else {
            rowRecord.labels = [];
          }
          const newRecord = JSON.parse(JSON.stringify(rowRecord));
          const { updateGridAfterSave } = this.props;
          updateGridAfterSave(rowRecord, "add");
          this.setState({ rowRecord, newRecord });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "Error while saving changes";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  getCategoryGroup(serviceCategoryId) {
    const { serviceCategoryGroupsMap } = this.context;
    if (serviceCategoryId) {
      const serviceCategoryGroup =
        serviceCategoryGroupsMap[serviceCategoryId.toString()];
      if (serviceCategoryGroup) {
        return toEmptyStringIfUndefined(serviceCategoryGroup.categoryGroupName);
      }
    }
    return "";
  }

  render() {
    const listItems = [0, 1, 2, 3].map(item => (
      <li key={item.toString()}>
        {
          this.context.localeStrings[
            `xmmadmin.portal.global_operation.vehicle_tool_tip_option_${item}`
          ]
        }
      </li>
    ));
    const tooltipVehicleInfo = (
      <div>
        <p className="xmm-popover-text">
          <ul className="vehicle_tooltip">{listItems}</ul>
        </p>
      </div>
    );

    let editSection = null;
    const msgSection = null;
    const {
      editOption,
      valid,
      dirty,
      newRecord,
      errors,
      serviceCategories,
      positionOptions,
      labels
    } = this.state;
    const disableCopyOperation = dirty || editOption === "add";
    // const formstate = {
    //   newRecord,
    //   dirty,
    //   valid,
    //   errors
    // };
    const saveDisabled = !valid || !dirty;
    if (this.props.editForm) {
      const copyConfirmationDialog = (
        <Confirmation
          htmlId="copyOperationDialog"
          message={this.copyOperationConfirmMessage}
          proceedButtonStyle="danger"
          show={this.state.showCopyModal}
          actionFunction={this.copyOperationHandler}
          closeDialog={this.closeCopyModal}
        />
      );
      const categoryGroup = this.getCategoryGroup(newRecord.serviceCategoryId);
      // show form when edit clicked or add clicked
      editSection = (
        <div className="comment-form">
          <form autoComplete="off">
            <div className="xmm-tab-bar">
              <span className="xmm-msg">{msgSection}</span>
              <Button
                htmlId="saveAction"
                buttonStyle="primary"
                disabled={saveDisabled}
                onClick={this.handleSave}
              >
                Save
              </Button>
              <Dropdown
                htmlId="toolsDropdown"
                name="toolsDropdown"
                id="toolsDropdown"
                // className="xmm-dotted-dropdown btn--icon"
                buttonStyle="link"
                displayCaret={false}
                size="small"
                disabled={disableCopyOperation}
                options={[
                  {
                    label: "Copy Operation",
                    value: "copyOperation",
                    onSelect: this.onCopyOperation
                  }
                ]}
                pullRight
              >
                <IconMore />
              </Dropdown>
            </div>
            <Grid htmlId="settingGrid">
              <Row className="">
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {this.context.localeStrings["xmm.portal.common.name_lbl"]}{" "}
                    <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={5} md={5}>
                  <TextInput
                    htmlId="name"
                    name="name"
                    label=""
                    placeholder="Name"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={newRecord.name || ""}
                    error={errors.name}
                    displayLabel={false}
                  />
                </Col>
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmm.portal.common.description_lbl"
                      ]
                    }{" "}
                    {/* <span className="xmm-red-label">*</span>{" "} */}
                  </span>
                </Col>
                <Col xs={5} md={5}>
                  <TextArea
                    htmlId="description"
                    name="description"
                    label=""
                    maxLength={4000}
                    disabled={false}
                    // required
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={newRecord.description || ""}
                    error={errors.description}
                    displayLabel={false}
                  />
                </Col>
                {/* <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmm.portal.common.service_kind"
                      ]
                    }{" "}
                    <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <SelectInput
                    htmlId="serviceKind"
                    name="serviceKind"
                    disabled={false}
                    displayLabel={false}
                    onChange={this.onChange}
                    value={newRecord.serviceKind}
                    error={errors.serviceKind}
                    options={[
                      { value: "maintenance", label: "maintenance" },
                      { value: "repair", label: "repair" }
                    ]}
                  />
                </Col> */}
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmm.portal.common.category_lbl"
                      ]
                    }{" "}
                  </span>
                </Col>
                <Col xs={5} md={5}>
                  <SearchableSelect
                    htmlId="categoryName"
                    name="serviceCategoryId"
                    disabled={false}
                    enableMultiSelect={false}
                    onChange={this.onSearchableSelectChange}
                    onBlur={this.onBlur}
                    displayLabel={false}
                    maxResults={1000}
                    value={toEmptyStringIfUndefined(
                      newRecord.serviceCategoryId
                    )}
                    options={serviceCategories}
                    error={errors.serviceCategoryId}
                  />
                </Col>
                {/* */}
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmmadmin.portal.nav.settings.category_group"
                      ]
                    }{" "}
                  </span>
                </Col>
                <Col xs={5} md={5}>
                  {/* <div>{categoryGroup}</div> */}
                  <TextInput
                    htmlId="categoryGroupName"
                    name="categoryGroupName"
                    // placeholder="Category Group"
                    // onBlur={this.onBlur}
                    label=""
                    onChange={() => {}}
                    value={categoryGroup || ""}
                    displayLabel={false}
                    disabled={true}
                  />
                </Col>
                {/* */}
                {/*
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmm.portal.common.schedule_duration_lbl"
                      ]
                    }{" "}
                    <span className="xmm-red-label">*</span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <TextInput
                    htmlId="duration"
                    name="duration"
                    placeholder="Duration"
                    disabled={false}
                    value={newRecord.duration}
                    error={errors.duration}
                    displayLabel={false}
                  />
                </Col>
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmmadmin.portal.common.position_lbl"
                      ]
                    }{" "}
                    <span className="xmm-red-label"> * </span>{" "}
                  </span>
                </Col>
                <Col xs={6} md={6}>
                  <SelectInput
                    htmlId="position"
                    name="position"
                    onChange={this.onChange}
                    placeholder="Select"
                    options={[
                      { value: "1", label: "Item 1" },
                      { value: "2", label: "Item 2" },
                      { value: "3", label: "Item 3" },
                      { value: "4", label: "Item 4" }
                    ]}
                    value={newRecord.position}
                    error={errors.position}
                    displayLabel={false}
                  />
                </Col>
                */}
                {/* */}
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmmadmin.portal.common.labels_lbl"
                      ]
                    }{" "}
                  </span>
                </Col>
                <Col xs={5} md={5}>
                  <SearchableSelect
                    allowNewEntries
                    htmlId="labels"
                    name="labels"
                    label="Labels"
                    displayLabel={false}
                    onBlur={this.onBlur}
                    onChange={this.onSearchableSelectChange}
                    maxResults={1000}
                    value={newRecord.labels}
                    options={labels}
                    error={errors.labels}
                  />
                </Col>
                {/* */}
                <Col xs={4} md={4}>
                  <span className="float-right">
                    {
                      this.context.localeStrings[
                        "xmmadmin.portal.common.position"
                      ]
                    }{" "}
                  </span>
                </Col>
                <Col xs={5} md={5}>
                  <SearchableSelect
                    htmlId="position"
                    name="positionId"
                    label="Position"
                    displayLabel={false}
                    enableMultiSelect={false}
                    onBlur={this.onBlur}
                    onChange={this.onSearchableSelectChange}
                    // maxResults={1000}
                    maxHeight={250}
                    value={toEmptyStringIfUndefined(newRecord.positionId)}
                    options={positionOptions}
                    // error={errors.labels}
                  />
                </Col>
                <Col xs={4} md={4}>
                  <span className="float-right">Vehicles</span>
                </Col>
                <Col xs={5} md={5} className="popover-vehicle-container">
                  <span className="float-left vehicle-select-input">
                    <SelectInput
                      htmlId="vehicles"
                      label="Vehicles"
                      name="vehicles"
                      value={newRecord.vehicles}
                      error={errors.vehicles}
                      options={[
                        { label: "All Vehicles", value: "All Vehicles" },
                        { label: "None", value: "None" },
                        {
                          label: "Factory Assigned",
                          value: "Factory Assigned"
                        },
                        {
                          label: "Unsupported Makes Only",
                          value: "Unsupported Makes Only"
                        }
                      ]}
                      onBlur={this.onBlur}
                      displayLabel={false}
                      displayDeselectOption={false}
                      onChange={this.onChange}
                    />
                  </span>
                </Col>
                <Col xs={1} md={1} className="tooltip-vehicle-container">
                  <Popover
                    htmlId="tooltipVehicleInfo"
                    popoverContent={tooltipVehicleInfo}
                    position="right"
                    trigger={["click", "outsideClick"]}
                  >
                    <IconInfoOutline className="info-blue popover-vehicle" />
                  </Popover>
                </Col>
                {copyConfirmationDialog}
                {/* */}
              </Row>
              <Row>
                <Col xs={5} md={5} xsOffset={4} mdOffset={4}>
                  <div className="xmm-checkbox-container xmm-horizontal-form">
                    <input
                      className="form-checkbox"
                      name="loanerAllowed"
                      id="loanerAllowed"
                      type="checkbox"
                      checked={newRecord.loanerAllowed}
                      onChange={this.onChangeLoanerAllowed}
                    />
                    <span className="xmm-checkmark" />
                    <span className="label-checkbox">
                      {
                        this.context.localeStrings[
                          "xmm.portal.operations.form.loaners_label"
                        ]
                      }
                    </span>
                  </div>
                  <div className="xmm-checkbox-container xmm-horizontal-form">
                    <input
                      className="form-checkbox"
                      name="waiterAllowed"
                      id="waiterAllowed"
                      type="checkbox"
                      checked={newRecord.waiterAllowed}
                      onChange={this.onChangeWaiterAllowed}
                    />
                    <span className="xmm-checkmark" />
                    <span className="label-checkbox">
                      {
                        this.context.localeStrings[
                          "xmm.portal.operations.form.waiters_label"
                        ]
                      }
                    </span>
                  </div>
                </Col>
              </Row>
            </Grid>

            {/* <DisplayFormikState {...formstate} /> */}
          </form>
        </div>
      );
    }
    return <React.Fragment>{editSection}</React.Fragment>;
  }
}

export default SettingsForm;

SettingsForm.propTypes = {
  rowRecord: PropTypes.object,
  editForm: PropTypes.bool,
  editOption: PropTypes.string,
  closeSlider: PropTypes.func,
  updateGridAfterSave: PropTypes.func
};

/* eslint-enable no-console */
