/* eslint-disable no-console */
// import React, { useState, useContext } from "react";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import AccordionGroup from "@cx/ui/AccordionGroup";
// import Tooltip from "@cx/ui/Tooltip";
// import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Popover from "@cx/ui/Popover";
import Badge from "@cx/ui/Badge";
import { AgGridReact } from "ag-grid-react";
import { toast } from "@cx/ui/Toast";

function ServiceRecommendationPane(props) {
  // const context = useContext(PreviewContext);
  // const { populateContext } = useContext(PreviewContext); // context method to update state of PreviewPane
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [domLayout] = useState("autoHeight");
  const [rowData, setRowData] = useState([]);
  const [header, setHeader] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    console.log("rowData's length:", rowData);
    if (props.serviceRecommendations !== rowData) {
      setRowData(props.serviceRecommendations);
      setHeader(`Recommendations (${props.serviceRecommendations.length})`);
      // adjustGridColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceRecommendations, rowData]);

  // React.useEffect(() => {
  //   console.log("useEffect no parameters");
  //   // setTimeout(() => {
  //   adjustGridColumns();
  //   // }, 200);
  // });

  const columnDefs = [
    {
      headerName: "Category ID",
      headerClass: "ag-text-header",
      field: "serviceCategoryId",
      cellClass: "xmm-link-cell",
      minWidth: 100,
      maxWidth: 100
    },
    {
      headerName: "Category Name",
      field: "serviceCategory",
      headerClass: "ag-text-header",
      minWidth: 150
    },
    {
      headerName: "Operations",
      field: "linkedServices",
      cellRendererFramework: linkedServicesCellRenderer,
      // valueFormatter: formatLinkedServices,
      headerClass: "ag-text-header",
      minWidth: 200
    },
    {
      headerName: "Score",
      field: "correlationScore",
      headerClass: "ag-numeric-header",
      minWidth: 80,
      maxWidth: 80
      // },
      // {
      //   headerName: "Reasons",
      //   field: "reason",
      //   headerClass: "ag-text-header",
      //   maxWidth: 100
    }
  ];
  const defaultColDef = {
    autoHeight: true,
    sortable: true,
    resizable: true,
    editable: false, // default disable editor
    enableRowGroup: false,
    sortingOrder: ["desc", "asc", null],
    filter: false,
    filterParams: {
      buttons: ["clear"]
    },
    floatingFilter: false, // true - enable column header filters
    suppressMenu: true,
    rowGroup: false
  };
  const getRowNodeId = data => {
    return data.serviceCategoryId;
  };
  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.closeToolPanel();
  };
  const sizeToFit = useCallback(() => {
    gridApi && gridApi.sizeColumnsToFit();
  }, [gridApi]);
  const setAutoHeight = useCallback(() => {
    gridApi && gridApi.setDomLayout("autoHeight");
    document.querySelector("#serviceRecommendationGrid").style.height = "";
  }, [gridApi]);
  const assignColumnState = useCallback(
    defaultSortModel => {
      gridColumnApi &&
        gridColumnApi.applyColumnState({
          state: defaultSortModel,
          defaultState: {
            // important to say 'null' as undefined means 'do nothing'
            sort: null
          }
        });
    },
    [gridColumnApi]
  );
  const applySortConfig = useCallback(() => {
    const defaultSortModel = [
      {
        colId: "correlationScore",
        sort: "desc"
      }
    ];
    assignColumnState(defaultSortModel);
  }, [assignColumnState]);
  const adjustGridColumns = useCallback(() => {
    if (rowData && rowData.length !== 0) {
      sizeToFit();
      setAutoHeight();
      applySortConfig();
    }
  }, [applySortConfig, rowData, setAutoHeight, sizeToFit]);
  const handleGridSizeChanged = useCallback(
    event => {
      const { clientWidth, clientHeight } = event;
      if (clientWidth && clientHeight) {
        adjustGridColumns();
      }
    },
    [adjustGridColumns]
  );
  const clearGridSelections = useCallback(() => {
    gridApi && gridApi.deselectAll();
  }, [gridApi]);
  const onFilterChanged = useCallback(
    params => {
      gridApi && clearGridSelections();
    },
    [clearGridSelections, gridApi]
  );
  const onFirstDataRendered = useCallback(
    event => {
      setTimeout(() => {
        adjustGridColumns();
      }, 200);
    },
    [adjustGridColumns]
  );
  /* Un-select all rows, regardless of filtering from grid */
  const isRowSelectable = useCallback(rowNode => {
    return true; // to see checkbox
  }, []);
  const onCellClickedEvent = useCallback(
    params => {
      const { field } = params.colDef;
      if (field === "serviceCategoryId") {
        const { data } = params;
        const { serviceCategoryId } = data;
        const found = props.selectOperationByCategoryId(serviceCategoryId);
        console.log("Callback of cellclickevent for", data, found);
        if (found) {
          // setHeader(`Recommendations (${rowData.length})`);
        } else {
          toast.info("No services for the selected category ID at this dealer");
        }
      }
    },
    [props]
  );
  const onToggleAccordionClick = setOpen => () => setOpen(prev => !prev);
  const gridContainer = isOpen ? (
    <div
      id="serviceRecommendationGrid"
      className="ag-grid-container ag-theme-balham ops-auto-height no-striped-grid"
    >
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        suppressRowClickSelection={true}
        suppressMenuHide={false}
        suppressContextMenu={true}
        getRowNodeId={getRowNodeId}
        rowData={rowData}
        rowSelection="single"
        multiSortKey="ctrl"
        singleClickEdit={false}
        // stopEditingWhenCellsLoseFocus={true}
        animateRows={true}
        // statusBar={this.state.statusBar}
        enableRangeSelection={false}
        enableCellTextSelection={true}
        enableBrowserTooltips={true}
        onCellClicked={onCellClickedEvent}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onGridSizeChanged={handleGridSizeChanged}
        // onSelectionChanged={handleSelectionChanged}
        // frameworkComponents={frameworkComponents}
        isRowSelectable={isRowSelectable}
        sideBar={false}
        // columnTypes={this.state.columnTypes}
        domLayout={domLayout}
        popupParent={document.body}
      />
    </div>
  ) : (
    <div />
  );
  return (
    <AccordionGroup.Container htmlId="accordionGroupMultipleOpen" independent>
      <AccordionGroup.Item
        header={header}
        eventKey="1"
        htmlId="accordionGroupMultipleOpen1"
        onToggle={onToggleAccordionClick(setIsOpen)}
        expanded={isOpen}
      >
        {gridContainer}
      </AccordionGroup.Item>
    </AccordionGroup.Container>
  );
}

export default ServiceRecommendationPane;

ServiceRecommendationPane.propTypes = {
  serviceRecommendations: PropTypes.array,
  selectOperationByCategoryId: PropTypes.func
};

// function formatLinkedServices(params) {
//   const { data } = params;
//   const { linkedServices } = data;
//   if (linkedServices && linkedServices.length !== 0) {
//     const { operationName } = linkedServices[0];
//     return operationName;
//   }
// }
function renderServices(linkedServices) {
  const list = linkedServices.map((op, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={`key${i}`}>{op.operationName}</li>
  ));
  return <ul>{list}</ul>;
}

function linkedServicesCellRenderer(params) {
  const { data } = params;
  const { serviceCategoryId, linkedServices } = data;
  if (linkedServices && linkedServices.length !== 0) {
    const { operationName } = linkedServices[0];
    if (linkedServices.length === 1) {
      return <div> {operationName} </div>;
    }
    const serviceTooltip = renderServices(linkedServices);
    return (
      <div>
        {" "}
        {operationName}{" "}
        <div className="ops-linked-services">
          {/* <Tooltip
            htmlId={serviceCategoryId}
            tooltipContent={serviceTooltip}
            className="list-tooltip"
          >
            <IconInfoOutline className="info pull-right" />
          </Tooltip>
          */}
          <Popover
            htmlId={`popover-${serviceCategoryId}`}
            className="list-tooltip"
            trigger={["click", "outsideClick"]}
            popoverContent={serviceTooltip}
          >
            <Badge
              htmlId={`count-${serviceCategoryId}`}
              color="blue"
              className="hand-cursor"
            >
              {linkedServices.length}
            </Badge>
          </Popover>
        </div>
      </div>
    );
  }
  return "";
}
