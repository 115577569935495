/* eslint-disable no-useless-escape */
/* declare all regex in the file  */
export const EMPTY_REGEX = /(.){0,}$/;
export const NUMBER_ONLY = /^[0-9*]+$/;
export const NUMBER_WITH_DECIMAL = /^([0-9]){0,}(.)?([0-9]){0,2}$/;
export const NUMBER_WITH_COMMA = /^[0-9,]+$/;
export const NUMBER_SPL_CHAR = /^([0-9 ,.()\-*]){0,}$/;

export const ALPHA_NUMERIC = /^[a-zA-Z0-9 \*]{0,}$/;
export const ALPHA_NUM_WITH_SPACE = /^([a-zA-Z0-9 ]){0,}$/;
export const ALPHA_NUM_WITH_COMMA = /^[a-zA-Z0-9,_]+$/;
// This regex used to allow Punctuation characters   ,.!:"\;/'_&-*?%#@~$(){}[]=+<>
export const ALPHA_NUM_WITH_SPECIAL_CHAR =
  /^([a-zA-Z0-9 ,.!:"\\;\/'_&\-\*\?%#@\~\$()\{\}\[\]\=\+\<\>]){0,}$/;
// This regex used in textfield like opcode, allow Punctuation characters   ,.!:"\;/'_&?%#@~$(){}[]
export const ALPHA_NUM_PUNCTUATION =
  /^([a-zA-Z0-9 ,.!:"\\;\/'_&\-\*\?%#@\~\$()\{\}\[\]]){0,}$/;
/*  This regex to allow newline, white space,tab,form feed,line feed & Punctuation characters ,.!:"\;/'_&-*?%#@~$(){}[]=+
    Used in textarea field like description
*/
export const ALPHA_NUM_WITH_NEWLINE =
  /^([a-zA-Z0-9 \s,.!:"\\;\/'_&\-\*\?%#@\~\$()\{\}\[\]\<\>\=\+]){0,}$/;

export const PHONE_NUMBER = /^([0-9 _()\-*]){0,}$/;
export const EMAIL_REGEX =
  /^(")?(?:[^."])(?:(?:[.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[-\w]*\.){1,5}([A-Za-z]){2,6}$/;
export const USERNAME = /^[a-zA-Z0-9_-]{6,16}$/;
export const URL =
  /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.?)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
export const CSV_ZIP_REGEX = /^.*\.(zip|csv|ZIP|CSV)$/i;
