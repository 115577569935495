import React, { useState, useEffect } from "react";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import PropTypes from "prop-types";
import IconError from "@cx/ui/Icons/IconError";
import IconComplete from "@cx/ui/Icons/IconComplete";
import IconWarning from "@cx/ui/Icons/IconWarning";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import { xlate } from "../../i18n/locales";

/**
 * ? How to use Promise with timeout
 */
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const baseStyle = "xmm-status-box";
const colorStyleMap = {
  pending: "pending",
  success: "success",
  error: "error",
  info: "info",
  text: "text"
};
const handCursorStyle = "hand-cursor";
const statusFadeOut = "fade-out";

/**
 * * This component used as to display generic status message
 * @param  {object} props
 * @class StatusBox
 */
function StatusBox(props) {
  const [message, setMessage] = useState(props.message || "");
  const [type, setType] = useState(props.type || "info");
  const [close, setAutoClose] = useState(props.autoClose || false);
  const [autoCloseTime] = useState(props.autoCloseTime);
  const [autoHide, setAutoHide] = useState(baseStyle);
  const [errorInTooltip, setErrorInTooltip] = useState(props.errorInTooltip);
  const [animated] = useState(props.animated);
  useEffect(() => {
    const startTimeout = close => {
      sleep(autoCloseTime).then(() => {
        if (close) {
          setAutoHide("hidden");
          setMessage("");
          setAutoClose(false);
        }
      });
    };
    const getStatusClassStyle = newClass => {
      const classStyles = [baseStyle];
      classStyles.push(colorStyleMap[type]);
      if (newClass) {
        classStyles.push(newClass);
      }
      return classStyles.join(" ");
    };
    setErrorInTooltip(props.errorInTooltip);
    setType(props.type);
    setMessage(props.message);
    setAutoHide(getStatusClassStyle());
    // callback only when close = true
    if (props.autoClose) {
      if (animated) {
        setAutoHide(getStatusClassStyle(statusFadeOut));
      }
      startTimeout(props.autoClose);
    }
  }, [
    props.type,
    props.message,
    close,
    props.autoClose,
    autoCloseTime,
    props.errorInTooltip,
    errorInTooltip,
    type,
    animated
  ]);

  let maskWidget = "";
  if (type === "pending") {
    maskWidget = (
      <LoadingIndicator
        htmlId="LoadingStatus"
        size="small"
        floatToSide="left"
        className={autoHide}
      />
    );
  } else if (type === "success") {
    maskWidget = <IconComplete htmlId="IconCompleteId" />;
  } else if (type === "error") {
    if (errorInTooltip) {
      maskWidget = (
        <Tooltip htmlId="saveChangesError" tooltipContent={message}>
          <IconError htmlId="IconErrorId" className={handCursorStyle} />
        </Tooltip>
      );
    } else {
      maskWidget = <IconError htmlId="IconErrorId" />;
    }
  } else if (type === "warning") {
    maskWidget = <IconWarning htmlId="IconWarningId" />;
  } else if (type === "info") {
    maskWidget = <IconInfoOutline htmlId="IconInfoOutline" />;
  } else if (type === "text") {
    maskWidget = "";
  }

  let widget = null;
  if (message) {
    // const messageToShow = errorInTooltip
    //   ? xlate("xmm.portal.common.cannot_save_changes")
    //   : message;
    // Add tooltip for Error Label
    let tooltipWidget = message;
    if (errorInTooltip) {
      tooltipWidget = (
        <Tooltip htmlId="saveChanges" tooltipContent={message}>
          <span className={handCursorStyle}>
            {xlate("xmm.portal.common.cannot_save_changes")}
          </span>
        </Tooltip>
      );
    }
    widget = (
      <div className={autoHide}>
        {maskWidget} {"  "} {tooltipWidget}{" "}
        {typeof props.linkHtml === "object" ? props.linkHtml : ""}
      </div>
    );
  }
  return <> {widget}</>;
}
export default StatusBox;

StatusBox.propTypes = {
  animated: PropTypes.bool,
  autoClose: PropTypes.bool,
  autoCloseTime: PropTypes.number,
  errorInTooltip: PropTypes.bool,
  linkHtml: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.string
};

StatusBox.defaultProps = {
  autoClose: false,
  autoCloseTime: 5000,
  errorInTooltip: false,
  animated: true
};
