/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import NavMenuLink from "./NavMenuLink";
import PreviewButton from "./PreviewButton";
import { hasUserRole } from "../../role/roleUtil";
import { namespace } from "../../api/xmmAxios";

const Nav = props => {
  const { links, role } = props;
  const navlinks = (
    <NavMenuLink
      key={"common-ops-header"}
      label={"common-ops-header"}
      links={links}
    />
  );
  const previewEnabled = hasUserRole("preview", role);
  const showPreview = namespace.indexOf("aus") === -1;
  const previewButtonClass = showPreview ? "" : "hide";

  return (
    <div className="nav-container">
      <div className="nav">
        <ul className="nav__pills nav">{navlinks}</ul>
      </div>
      <PreviewButton
        className={previewButtonClass}
        disabled={!previewEnabled}
      />
    </div>
  );
};

Nav.propTypes = {
  links: PropTypes.object.isRequired,
  role: PropTypes.string
};

export default Nav;
