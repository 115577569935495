/* eslint-disable react/prop-types */
// import { enLocaleObj } from "../i18n/locales/en_US";
// import { frLocaleObj } from "../i18n/locales/fr_CA";
// import { deLocaleObj } from "../i18n/locales/de_DE";
// import { esLocaleObj } from "../i18n/locales/es_MX";
import React, { useContext } from "react";
import { AppContext } from "../components/app-context";
import enUSJson from "../i18n/locales/en_US.json";
import frCAJson from "../i18n/locales/fr_CA.json";
import enAUJson from "../i18n/locales/en_AU.json";

/* This util returns locale strings read from static file, based on localekey */
export const getLocaleStrings = localekey => {
  switch (localekey) {
    case "en_US":
      return enUSJson;
    case "fr_CA":
      return frCAJson;
    case "en_AU": {
      const mergedEnAUJson = Object.assign(enUSJson, enAUJson);
      return mergedEnAUJson;
    }
    default:
      return enUSJson;
  }
};

/*  This function used to read locale string from contextAPI
Function component to return locale string wrapped in HTML toast notification using context API
Usage: const element = <FormatLocale id="xmm.portal.operations.title" defaultMessage="Operations" />;
*/
export function FormatLocale(props) {
  const [appContext] = useContext(AppContext);
  const msg = appContext.localeStrings[props.id];
  return <>{msg}</>;
}

/* This func() returns list of localeText related to Ag-Grid
 refer = www.ag-grid.com/javascript-grid-internationalisation
*/
export const loadAgGridLocale = localeStrings => {
  let localeText = {};
  if (localeStrings && Object.keys(localeStrings).length) {
    localeText = {
      // for filter panel
      page: localeStrings["xmm.portal.ag_grid.page"],
      more: localeStrings["xmm.portal.ag_grid.more"],
      to: localeStrings["xmm.portal.ag_grid.to"],
      of: localeStrings["xmm.portal.ag_grid.of"],
      next: localeStrings["xmm.portal.ag_grid.next"],
      last: localeStrings["xmm.portal.ag_grid.last"],
      first: localeStrings["xmm.portal.ag_grid.first"],
      previous: localeStrings["xmm.portal.ag_grid.previous"],
      loadingOoo: localeStrings["xmm.portal.ag_grid.loadingOoo"],
      // for set filter
      selectAll: localeStrings["xmm.portal.ag_grid.selectAll"],
      searchOoo: localeStrings["xmm.portal.ag_grid.searchOoo"],
      SearchOoo: localeStrings["xmm.portal.ag_grid.SearchOoo"],
      blanks: localeStrings["xmm.portal.ag_grid.blanks"],
      // for number filter and text filter
      filterOoo: localeStrings["xmm.portal.ag_grid.filterOoo"],
      equals: localeStrings["xmm.portal.ag_grid.equals"],
      notEqual: localeStrings["xmm.portal.ag_grid.notEqual"],
      // for the date filter
      dateFormatOoo: localeStrings["xmm.portal.ag_grid.dateFormatOoo"],
      // for number filter
      lessThan: localeStrings["xmm.portal.ag_grid.lessThan"],
      greaterThan: localeStrings["xmm.portal.ag_grid.greaterThan"],
      lessThanOrEqual: localeStrings["xmm.portal.ag_grid.lessThanOrEqual"],
      greaterThanOrEqual:
        localeStrings["xmm.portal.ag_grid.greaterThanOrEqual"],
      inRange: localeStrings["xmm.portal.ag_grid.inRange"],
      inRangeStart: localeStrings["xmm.portal.ag_grid.inRangeStart"],
      inRangeEnd: localeStrings["xmm.portal.ag_grid.inRangeEnd"],
      // for text filter
      contains: localeStrings["xmm.portal.ag_grid.contains"],
      notContains: localeStrings["xmm.portal.ag_grid.notContains"],
      startsWith: localeStrings["xmm.portal.ag_grid.startsWith"],
      endsWith: localeStrings["xmm.portal.ag_grid.endsWith"],
      // filter conditions
      andCondition: localeStrings["xmm.portal.ag_grid.andCondition"],
      orCondition: localeStrings["xmm.portal.ag_grid.orCondition"],
      // filter buttons
      applyFilter: localeStrings["xmm.portal.ag_grid.applyFilter"],
      resetFilter: localeStrings["xmm.portal.ag_grid.resetFilter"],
      clearFilter: localeStrings["xmm.portal.ag_grid.clearFilter"],
      // the header of the default group column
      group: localeStrings["xmm.portal.ag_grid.group"],
      // tool panel
      columns: localeStrings["xmm.portal.ag_grid.columns"],
      filters: localeStrings["xmm.portal.ag_grid.filters"],
      rowGroupColumns: localeStrings["xmm.portal.ag_grid.rowGroupColumns"],
      rowGroupColumnsEmptyMessage:
        localeStrings["xmm.portal.ag_grid.rowGroupColumnsEmptyMessage"],
      valueColumns: localeStrings["xmm.portal.ag_grid.valueColumns"],
      pivotMode: localeStrings["xmm.portal.ag_grid.pivotMode"],
      groups: localeStrings["xmm.portal.ag_grid.groups"],
      values: localeStrings["xmm.portal.ag_grid.values"],
      pivots: localeStrings["xmm.portal.ag_grid.pivots"],
      valueColumnsEmptyMessage:
        localeStrings["xmm.portal.ag_grid.valueColumnsEmptyMessage"],
      pivotColumnsEmptyMessage:
        localeStrings["xmm.portal.ag_grid.pivotColumnsEmptyMessage"],
      toolPanelButton: localeStrings["xmm.portal.ag_grid.toolPanelButton"],
      // others
      noRowsToShow: localeStrings["xmm.portal.ag_grid.noRowsToShow"],
      enabled: localeStrings["xmm.portal.ag_grid.enabled"],
      // enterprise menu
      pinColumn: localeStrings["xmm.portal.ag_grid.pinColumn"],
      autosizeThiscolumn:
        localeStrings["xmm.portal.ag_grid.autosizeThiscolumn"],
      autosizeAllColumns:
        localeStrings["xmm.portal.ag_grid.autosizeAllColumns"],
      groupBy: localeStrings["xmm.portal.ag_grid.groupBy"],
      ungroupBy: localeStrings["xmm.portal.ag_grid.ungroupBy"],
      resetColumns: localeStrings["xmm.portal.ag_grid.resetColumns"],
      expandAll: localeStrings["xmm.portal.ag_grid.expandAll"],
      collapseAll: localeStrings["xmm.portal.ag_grid.collapseAll"],
      toolPanel: localeStrings["xmm.portal.ag_grid.toolPanel"],
      export: localeStrings["xmm.portal.ag_grid.export"],
      // enterprise menu pinning
      pinLeft: localeStrings["xmm.portal.ag_grid.pinLeft"],
      pinRight: localeStrings["xmm.portal.ag_grid.pinRight"],
      noPin: localeStrings["xmm.portal.ag_grid.noPin"],
      // enterprise menu aggregation and status bar
      sum: localeStrings["xmm.portal.ag_grid.sum"],
      min: localeStrings["xmm.portal.ag_grid.min"],
      max: localeStrings["xmm.portal.ag_grid.max"],
      none: localeStrings["xmm.portal.ag_grid.none"],
      count: localeStrings["xmm.portal.ag_grid.count"],
      average: localeStrings["xmm.portal.ag_grid.average"],
      filteredRows: localeStrings["xmm.portal.ag_grid.filteredRows"],
      selectedRows: localeStrings["xmm.portal.ag_grid.selectedRows"],
      totalRows: localeStrings["xmm.portal.ag_grid.totalRows"],
      totalAndFilteredRows:
        localeStrings["xmm.portal.ag_grid.totalAndFilteredRows"],
      // standard menu
      copy: localeStrings["xmm.portal.ag_grid.copy"],
      copyWithHeaders: localeStrings["xmm.portal.ag_grid.copyWithHeaders"],
      ctrlC: localeStrings["xmm.portal.ag_grid.ctrlC"],
      paste: localeStrings["xmm.portal.ag_grid.paste"],
      ctrlV: localeStrings["xmm.portal.ag_grid.ctrlV"],
      // chart
      pivotChartTitle:
        localeStrings["xmm.portal.ag_grid.chart.pivotChartTitle"],
      rangeChartTitle:
        localeStrings["xmm.portal.ag_grid.chart.rangeChartTitle"],
      settings: localeStrings["xmm.portal.ag_grid.chart.settings"],
      data: localeStrings["xmm.portal.ag_grid.chart.data"],
      format: localeStrings["xmm.portal.ag_grid.chart.format"],
      categories: localeStrings["xmm.portal.ag_grid.chart.categories"],
      defaultCategory:
        localeStrings["xmm.portal.ag_grid.chart.defaultCategory"],
      series: localeStrings["xmm.portal.ag_grid.chart.series"],
      xyValues: localeStrings["xmm.portal.ag_grid.chart.xyValues"],
      paired: localeStrings["xmm.portal.ag_grid.chart.paired"],
      axis: localeStrings["xmm.portal.ag_grid.chart.axis"],
      color: localeStrings["xmm.portal.ag_grid.chart.color"],
      thickness: localeStrings["xmm.portal.ag_grid.chart.thickness"],
      xType: localeStrings["xmm.portal.ag_grid.chart.xType"],
      automatic: localeStrings["xmm.portal.ag_grid.chart.automatic"],
      category: localeStrings["xmm.portal.ag_grid.chart.category"],
      number: localeStrings["xmm.portal.ag_grid.chart.number"],
      time: localeStrings["xmm.portal.ag_grid.chart.time"],
      xRotation: localeStrings["xmm.portal.ag_grid.chart.xRotation"],
      yRotation: localeStrings["xmm.portal.ag_grid.chart.yRotation"],
      ticks: localeStrings["xmm.portal.ag_grid.chart.ticks"],
      width: localeStrings["xmm.portal.ag_grid.chart.width"],
      length: localeStrings["xmm.portal.ag_grid.chart.length"],
      padding: localeStrings["xmm.portal.ag_grid.chart.padding"],
      chart: localeStrings["xmm.portal.ag_grid.chart.chart"],
      title: localeStrings["xmm.portal.ag_grid.chart.title"],
      background: localeStrings["xmm.portal.ag_grid.chart.background"],
      font: localeStrings["xmm.portal.ag_grid.chart.font"],
      top: localeStrings["xmm.portal.ag_grid.chart.top"],
      right: localeStrings["xmm.portal.ag_grid.chart.right"],
      bottom: localeStrings["xmm.portal.ag_grid.chart.bottom"],
      left: localeStrings["xmm.portal.ag_grid.chart.left"],
      labels: localeStrings["xmm.portal.ag_grid.chart.labels"],
      size: localeStrings["xmm.portal.ag_grid.chart.size"],
      minSize: localeStrings["xmm.portal.ag_grid.chart.minSize"],
      maxSize: localeStrings["xmm.portal.ag_grid.chart.maxSize"],
      legend: localeStrings["xmm.portal.ag_grid.chart.legend"],
      position: localeStrings["xmm.portal.ag_grid.chart.position"],
      markerSize: localeStrings["xmm.portal.ag_grid.chart.markerSize"],
      markerStroke: localeStrings["xmm.portal.ag_grid.chart.markerStroke"],
      markerPadding: localeStrings["xmm.portal.ag_grid.chart.markerPadding"],
      itemPaddingX: localeStrings["xmm.portal.ag_grid.chart.itemPaddingX"],
      itemPaddingY: localeStrings["xmm.portal.ag_grid.chart.itemPaddingY"],
      strokeWidth: localeStrings["xmm.portal.ag_grid.chart.strokeWidth"],
      offset: localeStrings["xmm.portal.ag_grid.chart.offset"],
      offsets: localeStrings["xmm.portal.ag_grid.chart.offsets"],
      tooltips: localeStrings["xmm.portal.ag_grid.chart.tooltips"],
      callout: localeStrings["xmm.portal.ag_grid.chart.callout"],
      markers: localeStrings["xmm.portal.ag_grid.chart.markers"],
      shadow: localeStrings["xmm.portal.ag_grid.chart.shadow"],
      blur: localeStrings["xmm.portal.ag_grid.chart.blur"],
      xOffset: localeStrings["xmm.portal.ag_grid.chart.xOffset"],
      yOffset: localeStrings["xmm.portal.ag_grid.chart.yOffset"],
      lineWidth: localeStrings["xmm.portal.ag_grid.chart.lineWidth"],
      normal: localeStrings["xmm.portal.ag_grid.chart.normal"],
      bold: localeStrings["xmm.portal.ag_grid.chart.bold"],
      italic: localeStrings["xmm.portal.ag_grid.chart.italic"],
      boldItalic: localeStrings["xmm.portal.ag_grid.chart.boldItalic"],
      predefined: localeStrings["xmm.portal.ag_grid.chart.predefined"],
      fillOpacity: localeStrings["xmm.portal.ag_grid.chart.fillOpacity"],
      strokeOpacity: localeStrings["xmm.portal.ag_grid.chart.strokeOpacity"],
      histogramBinCount:
        localeStrings["xmm.portal.ag_grid.chart.histogramBinCount"],
      columnGroup: localeStrings["xmm.portal.ag_grid.chart.columnGroup"],
      barGroup: localeStrings["xmm.portal.ag_grid.chart.barGroup"],
      pieGroup: localeStrings["xmm.portal.ag_grid.chart.pieGroup"],
      lineGroup: localeStrings["xmm.portal.ag_grid.chart.lineGroup"],
      scatterGroup: localeStrings["xmm.portal.ag_grid.chart.scatterGroup"],
      areaGroup: localeStrings["xmm.portal.ag_grid.chart.areaGroup"],
      histogramGroup: localeStrings["xmm.portal.ag_grid.chart.histogramGroup"],
      groupedColumnTooltip:
        localeStrings["xmm.portal.ag_grid.chart.groupedColumnTooltip"],
      stackedColumnTooltip:
        localeStrings["xmm.portal.ag_grid.chart.stackedColumnTooltip"],
      normalizedColumnTooltip:
        localeStrings["xmm.portal.ag_grid.chart.normalizedColumnTooltip"],
      groupedBarTooltip:
        localeStrings["xmm.portal.ag_grid.chart.groupedBarTooltip"],
      stackedBarTooltip:
        localeStrings["xmm.portal.ag_grid.chart.stackedBarTooltip"],
      normalizedBarTooltip:
        localeStrings["xmm.portal.ag_grid.chart.normalizedBarTooltip"],
      pieTooltip: localeStrings["xmm.portal.ag_grid.chart.pieTooltip"],
      doughnutTooltip:
        localeStrings["xmm.portal.ag_grid.chart.doughnutTooltip"],
      lineTooltip: localeStrings["xmm.portal.ag_grid.chart.lineTooltip"],
      groupedAreaTooltip:
        localeStrings["xmm.portal.ag_grid.chart.groupedAreaTooltip"],
      stackedAreaTooltip:
        localeStrings["xmm.portal.ag_grid.chart.stackedAreaTooltip"],
      normalizedAreaTooltip:
        localeStrings["xmm.portal.ag_grid.chart.normalizedAreaTooltip"],
      scatterTooltip: localeStrings["xmm.portal.ag_grid.chart.scatterTooltip"],
      bubbleTooltip: localeStrings["xmm.portal.ag_grid.chart.bubbleTooltip"],
      histogramTooltip:
        localeStrings["xmm.portal.ag_grid.chart.histogramTooltip"],
      noDataToChart: localeStrings["xmm.portal.ag_grid.chart.noDataToChart"],
      pivotChartRequiresPivotMode:
        localeStrings["xmm.portal.ag_grid.chart.pivotChartRequiresPivotMode"]
    };
  }
  return localeText;
};
