/* eslint-disable no-console */
import React, { Component } from "react";
import { bool, PropTypes } from "prop-types";
import { AppContext } from "../../components/app-context";
import ModalDialog from "@cx/ui/ModalDialog";
import CustomLoadingOverlay from "../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import TextEditor from "../../commonUtil/editors/TextEditor";
import Popover from "@cx/ui/Popover";
import EmailInput from "@cx/ui/EmailInput";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import SelectInput from "@cx/ui/SelectInput";
import { toast } from "@cx/ui/Toast";
import { loadAgGridLocale } from "../../i18n/LocaleSender";
import { AgGridReact } from "ag-grid-react";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../api/xmmAxios";
import { isDifferentValue } from "../../commonUtil/utils/string";
import {
  datetimeComparator,
  formatDateTimezone
} from "../../commonUtil/utils/date";
import { applyCustomKeyNavigation } from "../../commonUtil/utils/keyNavigation";

/** Header */
export default class UserManagementDialog extends Component {
  static contextType = AppContext;
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.show !== prevState.show) {
      const { show } = nextProps;
      const { handle } = prevState;
      show && handle && setTimeout(() => handle.loadGridData(), 100);
      return {
        show
      };
    }
    return null;
  }
  constructor(props, context) {
    super(props, context);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.addUser = this.addUser.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.resetNewUser = this.resetNewUser.bind(this);

    const localeStrings = context.localeStrings;
    const gridOptions = {
      newUser: {
        userName: "",
        fullName: "",
        role: "",
        email: "",
        errors: {},
        valid: false
      },
      handle: this,
      show: props.show,
      columnDefs: this.getColumnList(localeStrings),
      defaultColDef: {
        floatingFilter: true, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false,
        enableRowGroup: false,
        suppressKeyboardEvent: applyCustomKeyNavigation,
        sortingOrder: ["asc", "desc", null],
        minWidth: 120,
        // autoHeight: true,
        filter: true,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      rowData: [],
      components: {},
      frameworkComponents: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
        textEditor: TextEditor
      },
      loadingOverlayComponent: "customLoadingOverlay",
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      columnTypes: {
        nonEditableColumn: { editable: false }
      },
      // Note: Set locale strings in this localeText {} for ag-grid controls
      localeText: loadAgGridLocale(localeStrings),
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      onColumnMoved: this.refreshGrid,
      onColumnPinned: this.refreshGrid,
      sideBar: false
    };
    this.state = gridOptions;
  }
  componentDidMount() {
    // this.loadGridData();
    // window.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    // window.removeEventListener("mousedown", this.handleClickOutside);
  }
  setWrapperRef = node => {
    this.wrapperRef = node;
  };
  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // this.gridApi && this.gridApi.stopEditing();
    }
  };
  handleColumnResized = () => {
    this.gridApi.resetRowHeights();
  };
  resetNewUser = () => {
    this.setState({
      newUser: {
        userName: "",
        fullName: "",
        role: "",
        email: "",
        errors: {},
        valid: false
      }
    });
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  applySortConfig = () => {
    const defaultSortModel = [
      {
        colId: "fullName",
        sort: "asc"
      }
    ];
    this.assignColumnState(defaultSortModel);
  };
  adjustGridColumns = () => {
    const { rowData } = this.state;
    if (rowData && rowData.length !== 0) {
      this.sizeToFit();
      // this.setAutoHeight();
      this.applySortConfig();
    }
  };
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeToFit();
  };
  handleGridSizeChanged = () => {
    this.sizeToFit();
  };
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      this.gridApi.setQuickFilter(
        document.querySelector("#page-search-box").value
      );
      // this.clearGridSelections();
    }
  };
  isFormValid = () => {
    const { newUser } = this.state;
    const { userName, fullName, email, role } = newUser;
    if (!userName || !fullName || !email || !role) {
      return false;
    }
    return true;
  };
  onBlur = (cxEvent, isValid, domEvent) => {
    const { value, id } = cxEvent.target;
    const { newUser } = this.state;
    const { errors } = newUser;
    console.log(value, id, errors);
    const name =
      id !== "role-selectButton"
        ? id === "userName_fullName"
          ? "fullName"
          : id
        : "role";
    errors[name] = !value ? "Required Field" : "";
    const validForm = this.isFormValid();
    this.setState({ newUser: { ...newUser, valid: validForm } });
  };
  onChange = (cxEvent, isValid, domEvent) => {
    const { name, value } = cxEvent.target;
    const { newUser } = this.state;
    let newValue = value;
    if (newValue && name === "userName") {
      newValue = newValue.replace(" ", "");
    }
    if (newValue !== newUser[name]) {
      newUser[name] = newValue;
      this.setState({ newUser });
    }
  };
  addUser = () => {
    console.log("Creating new user...");
    const { newUser } = this.state;
    const { userName, fullName, role, email } = newUser;
    const data = {
      userName,
      fullName,
      role,
      email,
      modUserName: this.context.userName
    };
    const restUrl = "/opsadmin/rest-db/addTableData/users";
    console.log(restUrl);
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      newUsers => {
        if (newUsers && Array.isArray(newUsers) && newUsers.length !== 0) {
          const newUser = newUsers[0];
          this.addXmmUser(newUser);
          console.log(newUsers);
          const res = this.gridApi.applyTransaction({
            add: newUsers
          });
          // console.log(res, newUser);
          // console.log(res);
          const rowNode = this.gridApi.getRowNode(newUser.userId);
          rowNode.setSelected(true);
          this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");

          // const { rowData } = this.state;
          // rowData.push(newUser);
          // this.setState({
          //   // rowData,
          // });
          document.querySelector("#addNewUserBtn").click();
        }
        this.gridApi && this.gridApi.hideOverlay();
        hideBodyMask();
      },
      error => {
        const msg = "There was an error in creating a user.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  };
  addXmmUser = newUser => {
    console.log("Creating XMM user...");
    const { userId, userName, fullName, role, email } = newUser;
    const data = {
      userId,
      userName,
      password: "",
      role,
      fullName,
      email,
      modUser: this.context.userName
    };
    const restUrl = "/opsadmin/proxyapi/ddsproxy/rest/proc/newUser";
    console.log(restUrl);
    // showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      response => {
        console.log(response);
      },
      error => {
        const msg = "There was an error while creating the user in XMM DB.";
        toast.error(msg, {
          closeOnClick: true
        });
        // hideBodyMask();
      }
    );
  };
  editXmmUser = user => {
    console.log("Editing XMM user...");
    const { userId, userName, fullName, role, email } = user;
    const data = {
      requestType: "",
      authd: "",
      userId,
      userName,
      password: "",
      role,
      target: "",
      target2: "",
      fullName,
      email
    };
    const restUrl = "/opsadmin/proxyapi/ddsproxy/rest/proc/editUser";
    console.log(restUrl);
    // showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data
      },
      response => {
        console.log(response);
      },
      error => {
        const msg = "There was an error while updating the user in XMM DB.";
        toast.error(msg, {
          closeOnClick: true
        });
        // hideBodyMask();
      }
    );
  };

  /* This method can be called to refresh single or multi rows */
  refreshGrid(params) {
    params.api.refreshCells({ force: true });
  }
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.userId;
  }

  getColumnList(localeStrings) {
    const baseCols = [
      {
        headerName:
          localeStrings["xmmadmin.portal.user_management.username_lbl"],
        headerClass: "ag-text-header",
        field: "userName",
        editable: true,
        cellEditor: "textEditor",
        cellEditorParams: {
          maxLength: 32,
          cellHeight: 25
        },
        cellClass: "editable-cell",
        sortingOrder: ["asc", "desc"],
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        minWidth: 160,
        maxWidth: 160
      },
      {
        headerName: localeStrings["xmmadmin.portal.user_management.name_lbl"],
        headerClass: "ag-text-header",
        field: "fullName",
        sortingOrder: ["asc", "desc"],
        editable: true,
        cellClass: "editable-cell",
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmmadmin.portal.user_management.role_lbl"],
        headerClass: "ag-text-header",
        field: "role",
        sortingOrder: ["asc", "desc"],
        editable: true,
        cellEditorSelector(params) {
          return {
            component: "agRichSelectCellEditor",
            params: {
              values: ["accountmgr", "metavehicle", "preview", "DELETE"]
            }
          };
        },
        cellClass: "editable-caret-cell",
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        },
        minWidth: 120,
        maxWidth: 120
      },
      {
        headerName: localeStrings["xmmadmin.portal.user_management.email_lbl"],
        headerClass: "ag-text-header",
        field: "email",
        editable: true,
        cellClass: "editable-cell",
        sortingOrder: ["asc", "desc"],
        filter: "agTextColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName:
          localeStrings[
            "xmmadmin.portal.user_management.last_service_studio_login_lbl"
          ],
        headerClass: "ag-text-header",
        field: "lastAirLoginTime",
        sortingOrder: ["asc", "desc"],
        filter: "agTextColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        comparator: datetimeComparator,
        valueFormatter: dateFormatter,
        maxWidth: 180
      },
      {
        headerName:
          localeStrings[
            "xmmadmin.portal.user_management.last_catalog_admin_login_lbl"
          ],
        headerClass: "ag-text-header",
        field: "lastAdminLoginTime",
        sortingOrder: ["asc", "desc"],
        filter: "agTextColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        comparator: datetimeComparator,
        valueFormatter: dateFormatter,
        maxWidth: 180
      }
    ];
    return baseCols;
  }
  loadGridData() {
    // Point to static Json file
    // const restUrl = window.origin + "/data/getUsers.json";
    const restUrl = "/opsadmin/rest-db/getValues/users";
    console.log(restUrl);
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data) {
          console.log(data);
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.setState({ rowData: data }, () => {
            setTimeout(() => this.adjustGridColumns(), 0);
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching users.";
        toast.error(msg, {
          closeOnClick: true
        });
        hideBodyMask();
      }
    );
  }
  onSaveCellEdit(params) {
    const restUrl = "/opsadmin/rest-db/updateTableData/users";
    const { colDef, data, value } = params;
    const { field } = colDef;
    const { userId } = data;
    const { userName } = this.context;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: { id: userId, [field]: value, modUserName: userName }
      },
      response => {
        console.log(response);
        this.editXmmUser(params.data);
      },
      error => {
        const { colDef, oldValue } = params;
        data[colDef.field] = oldValue;
        const rowNode = this.gridApi.getRowNode(data.userId);
        if (rowNode) {
          const refreshParams = {
            rowNodes: [rowNode],
            force: true
          };
          this.gridApi.refreshCells(refreshParams);
        }
        const fieldName = this.getFieldName(field);
        let msg = `There was an error updating ${fieldName}.`;
        if (!field) {
          msg = error.message
            ? error.message
            : "There was an error while updating the user.";
        }
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  getFieldName(field) {
    let fieldName = "";
    switch (field) {
      case "userName":
        fieldName = "user name";
        break;
      case "role":
        fieldName = "role";
        break;
      case "email":
        fieldName = "email";
        break;
      case "fullName":
        fieldName = "full name";
        break;
      default:
        break;
    }
    return fieldName;
  }

  onCellValueChanged(params) {
    const { oldValue, newValue } = params;
    if (isDifferentValue(oldValue, newValue)) {
      this.onSaveCellEdit(params);
    }
  }
  onFilterChanged = params => {
    if (this.gridApi) {
      this.clearGridSelections();
    }
  };
  clearGridSelections = () => {
    if (this.gridApi) {
      this.gridApi.deselectAll();
      this.setState({ selectionlist: [] });
    }
  };
  renderNewUser = () => {
    const { userName, fullName, role, email, errors, valid } =
      this.state.newUser;
    const addWidget = (
      <form autoComplete="off" ref={this.setWrapperRef}>
        <TextInput
          htmlId="userName"
          label="Username"
          name="userName"
          value={userName}
          maxLength={32}
          error={errors.userName}
          autoFocus
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        <TextInput
          htmlId="userName_fullName"
          label="Full Name"
          name="fullName"
          value={fullName}
          error={errors.fullName}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        <SelectInput
          htmlId="role"
          label="Role"
          name="role"
          value={role}
          error={errors.role}
          options={[
            { label: "accountmgr", value: "accountmgr" },
            { label: "metavehicle", value: "metavehicle" },
            { label: "preview", value: "preview" }
          ]}
          onBlur={this.onBlur}
          displayDeselectOption={false}
          onChange={this.onChange}
        />
        <EmailInput
          htmlId="email"
          label="Email"
          name="email"
          value={email}
          autoComplete="off"
          error={errors.email}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        <Button
          htmlId="saveBtn"
          size="small"
          block
          buttonStyle="secondary"
          disabled={!valid}
          onClick={this.addUser}
        >
          Save
        </Button>
      </form>
    );
    return addWidget;
  };

  render() {
    const {
      localeStrings
      // languages
    } = this.context;
    const addWidget = this.renderNewUser();
    const header = (
      <React.Fragment>
        <div className="xmm-input-search float-right">
          <input
            type="text"
            id="page-search-box"
            className="xmm-input"
            placeholder={"Search"}
            onInput={this.onSearchBoxChanged}
            autoComplete="off"
          />
        </div>
        <div className="btn-toolbar float-right">
          <Popover
            htmlId="popoverAdd"
            className="xmm-add-user-popover"
            popoverContent={addWidget}
            position="left"
            trigger={["click", "outsideClick"]}
          >
            <Button htmlId="addNewUserBtn" onClick={this.resetNewUser}>
              {
                this.context.localeStrings[
                  "xmmadmin.portal.user_management.new_user_btn"
                ]
              }
            </Button>
          </Popover>
        </div>
      </React.Fragment>
    );
    const gridWidget = (
      <div
        id="manageUsersGrid"
        className="xmm-users-grid-container ag-theme-balham"
      >
        <AgGridReact
          localeText={this.state.localeText}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          suppressMenuHide={false}
          suppressContextMenu={true}
          rowData={this.state.rowData}
          rowSelection={"single"}
          animateRows={true}
          onGridReady={this.onGridReady}
          frameworkComponents={this.state.frameworkComponents}
          loadingOverlayComponent={this.state.loadingOverlayComponent}
          loadingOverlayComponentParams={
            this.state.loadingOverlayComponentParams
          }
          noRowsOverlayComponent={this.state.noRowsOverlayComponent}
          noRowsOverlayComponentParams={this.state.noRowsOverlayComponentParams}
          statusBar={this.state.statusBar}
          components={this.state.components}
          singleClickEdit={true}
          stopEditingWhenGridLosesFocus={true}
          onColumnResized={this.handleColumnResized}
          onCellValueChanged={this.onCellValueChanged}
          onCellClicked={this.onCellClickedEvent}
          onGridSizeChanged={this.handleGridSizeChanged}
          getRowNodeId={this.getRowNodeId}
          sideBar={this.state.sideBar}
          columnTypes={this.state.columnTypes}
          multiSortKey={this.state.multiSortKey}
          enableRangeSelection={false}
          enableCellTextSelection={true}
          enableBrowserTooltips={true}
          // enterMovesDownAfterEdit={true}
          // rowHeight={50}
        />
      </div>
    );
    return (
      <ModalDialog
        htmlId="ModalDialogWithHeader"
        className="xmm-full-width-modal"
        show={this.props.show}
        backdrop={"static"}
        keyboard={false}
        enforceFocus={false} // needed for Popover's TextInput
        header={
          <ModalDialog.Title>
            {localeStrings["xmmadmin.portal.nav.manage_users_lbl"]}
          </ModalDialog.Title>
        }
        onHide={this.props.closeDialog}
      >
        {header}
        {gridWidget}
      </ModalDialog>
    );
  }
}
UserManagementDialog.propTypes = {
  show: bool,
  closeDialog: PropTypes.func
};
UserManagementDialog.defaultProps = {
  show: false
};

function dateFormatter(params) {
  const { value } = params;
  return formatDateTimezone(value, true, "en_US");
}
