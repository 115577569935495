/* eslint-disable no-console */
import React from "react";
import { PropTypes } from "prop-types";
import ExternalOperations from "../ExternalPricing/ExternalOperations";
import PartTypes from "../ExternalPricing/PartTypes";
import FluidTypes from "../ExternalPricing/FluidTypes";

class ExternalPricing extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { rowRecord, updateGridAfterSave } = this.props;
    return (
      <React.Fragment>
        <div className="xmm-slider-external-pricing-scroll comment-form">
          <ExternalOperations
            rowRecord={rowRecord}
            updateGridAfterSave={updateGridAfterSave}
          />
          <PartTypes
            rowRecord={rowRecord}
            updateGridAfterSave={updateGridAfterSave}
          />
          <FluidTypes
            rowRecord={rowRecord}
            updateGridAfterSave={updateGridAfterSave}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default ExternalPricing;

ExternalPricing.propTypes = {
  rowRecord: PropTypes.object,
  updateGridAfterSave: PropTypes.func
};

/* eslint-enable no-console */
