// This context behaves like app level global state
import React from "react";
const initialState = {
  locale: "en_US",
  localeStrings: {},
  dealerCode: "XTIMEMOTORS",
  dealer: {
    webKey: "xtimemotors",
    dealerCode: "XTIMEMOTORS",
    dealerName: "XTIMEMOTORS"
  }
};

export const AppContext = React.createContext(initialState);
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;
