import React, { Component } from "react";
import { bool, PropTypes } from "prop-types";
import { AppContext } from "../../components/app-context";
import ModalDialog from "@cx/ui/ModalDialog";
import Col from "@cx/ui/Col";
import { AboutUsTemplate } from "../../constants/ModuleConstants";
import { getMetaTagContentByName } from "../../commonUtil/utils/dom";
import { detectBrowser } from "../../commonUtil/utils/browser";
import platform from "platform";

/** Header */
export default class AboutUsDialog extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    const infoOptions = {
      record: AboutUsTemplate
    };
    const { localeStrings } = context;
    this.initializeLocaleValues(localeStrings);
    this.state = infoOptions;
  }
  initializeLocaleValues(localeStrings) {
    const { pathname } = window.location;
    this.catalogManagerInfoLabel =
      pathname === "/xmmAdmin"
        ? localeStrings["xmmadmin.portal.common.xmm_admin_info"]
        : localeStrings["xmmadmin.portal.common.catalog_admin_info"];
    this.catalogManagerLabel =
      pathname === "/xmmAdmin"
        ? localeStrings["xmmadmin.portal.common.xmm_admin"]
        : localeStrings["xmmadmin.portal.common.catalog_admin"];
    // this.dealerNameLabel = localeStrings["xmm.portal.common.dealer_name"];
    // this.dealerCodeLabel = localeStrings["xmm.portal.common.dealer_code"];
    this.applicationLabel = localeStrings["xmm.portal.common.application"];
    this.urlLabel = localeStrings["xmm.portal.common.all_caps_url"];
    this.appVersionLabel = localeStrings["xmm.portal.common.app_version"];
    this.usernameLabel = localeStrings["xmm.portal.common.username"];
    this.languageLabel = localeStrings["xmm.portal.common.language"];
    this.browserLabel = localeStrings["xmm.portal.common.browser"];
    this.osLabel = localeStrings["xmm.portal.common.os"];
    this.copyrightLabel = localeStrings["xmm.portal.common.copyright"];
  }
  render() {
    const contextValue = {
      // dealerName: this.context.dealer.dealerName,
      // dealerCode: this.context.dealerCode,
      application: this.catalogManagerLabel,
      url: window.location.href,
      buildVersion: getMetaTagContentByName("xtime:gitBranch").concat(
        " - ",
        getMetaTagContentByName("xtime:buildNumber")
      ),
      browser: detectBrowser(),
      operatingSystem: platform.os.toString(),
      userName: this.context.userName,
      copyYear: new Date(),
      appContext: this.context, // send appContent as props
      locale: this.context.locale
    };
    return (
      <ModalDialog
        htmlId="ModalDialogWithHeader"
        className="sample-modal-dialog"
        show={this.props.show}
        header={
          <ModalDialog.Title>{this.catalogManagerInfoLabel}</ModalDialog.Title>
        }
        onHide={this.props.closeDialog}
      >
        <div className="xmm-info-form">
          {/*
          <Col xs={4} md={4}>
            <strong className="float-right">{this.dealerNameLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.dealerName}</div>
          </Col>
          <Col xs={4} md={4}>
            <strong className="float-right">{this.dealerCodeLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.dealerCode}</div>
          </Col>
          */}
          <Col xs={4} md={4}>
            <strong className="float-right">{this.applicationLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.application}</div>
          </Col>
          <Col xs={4} md={4}>
            <strong className="float-right">{this.urlLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label xmm-wrapped-text">
              {contextValue.url}
            </div>
          </Col>
          <Col xs={4} md={4}>
            <strong className="float-right">{this.appVersionLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.buildVersion}</div>
          </Col>
          <Col xs={4} md={4}>
            <strong className="float-right">{this.usernameLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.userName}</div>
          </Col>
          <Col xs={4} md={4}>
            <strong className="float-right">{this.languageLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.locale}</div>
          </Col>
          <Col xs={4} md={4}>
            <strong className="float-right">{this.browserLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.browser}</div>
          </Col>
          <Col xs={4} md={4}>
            <strong className="float-right">{this.osLabel}</strong>
          </Col>
          <Col xs={8} md={8}>
            <div className="xmm-form-label">{contextValue.operatingSystem}</div>
          </Col>
          <h6>
            {" "}
            {"\xA9 " +
              contextValue.copyYear.getFullYear() +
              " " +
              this.copyrightLabel}{" "}
          </h6>
        </div>
      </ModalDialog>
    );
  }
}
AboutUsDialog.propTypes = {
  show: bool,
  closeDialog: PropTypes.func
};

AboutUsDialog.defaultProps = {
  show: false
};
