import React, { useState } from "react";
import Button from "@cx/ui/Button";
import { makeSecureRestApi } from "../../../api/xmmAxios";

const MainPage = props => {
  const [file, setFile] = useState(null);

  const handelOnUploadFile = event => {
    if (event.target.files[0] === undefined) {
      setFile(null);
      return;
    }
    setFile(event.target.files[0]);
  };

  const uploadJsonFile = () => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    makeSecureRestApi(
      {
        url: "/opsadmin/upload/uploadExpenseCodesFile",
        method: "post",
        data: formData,
        headers
      },
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      <input
        style={{ padding: "10px" }}
        type="file"
        accept=".json"
        onChange={handelOnUploadFile}
      />
      {/* <div className="xmm-error-msg">{errMsg}</div> */}
      <Button
        htmlId="importCsvAction"
        buttonStyle="primary"
        disabled={!file}
        onClick={() => uploadJsonFile()}
      >
        Upload Expense Codes
      </Button>
    </div>
  );
};

export default MainPage;
