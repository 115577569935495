import React from "react";
import { PropTypes } from "prop-types";
import { IntlProvider } from "react-intl";
import { addAppLocaleData } from "./localeData";
addAppLocaleData();

export const LanguageProvider = ({ children, messages, locale }) => {
  return (
    <IntlProvider locale={locale} messages={messages} key={locale}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.object,
  messages: PropTypes.object,
  locale: PropTypes.string
};
