const rightAlignedFields = [
  "price",
  "dmsPrice",
  "msrp",
  "quantityAvailable",
  "unpublishedPackageRate",
  "unpublishedStandaloneRate"
];
/**
 *
 * @param {array} validationFields
 * @param {array} editableFields
 * @param {array} checkboxFields
 * @returns {object}
 */
export function getCellClassRule(
  validationFields,
  editableFields,
  checkboxFields,
  selectableFields
) {
  const cellClassRules = {
    "editable-caret-cell"(params) {
      const { colDef, data } = params;
      if (data) {
        const { field } = colDef;
        if (selectableFields && selectableFields.includes(field)) {
          return true;
        }
      }
    },
    "editable-cell"(params) {
      const { field } = params.colDef;
      if (editableFields && editableFields.includes(field)) {
        const { data } = params;
        if (data) {
          const { errors } = data;
          if (!errors || (errors && !errors[field])) {
            return true;
          }
        }
      }
    },
    "xmm-grid-cell-error"(params) {
      const { field } = params.colDef;
      if (validationFields && validationFields.includes(field)) {
        const { data } = params;
        if (data) {
          const { errors } = data;
          if (errors && errors[field]) {
            return true;
          }
        }
      }
    },
    "xmm-grid-price"(params) {
      const { field } = params.colDef;
      if (rightAlignedFields && rightAlignedFields.includes(field)) {
        return true;
      }
    },
    "xmm-grid-cell-checkbox"(params) {
      const { field } = params.colDef;
      if (checkboxFields && checkboxFields.includes(field)) {
        return true;
      }
    }
  };
  return cellClassRules;
}
