/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name, react/no-multi-comp */
/* eslint-disable no-console */
import React from "react";
import AirLauncher from "./pages/AirLauncher/index";
import XMMAdmin from "./pages/XMMAdmin/index";
import DealerAdmin from "./pages/DealerAdmin/index";
import VendorPage from "./pages/VendorPage/index";
import PushPackageOverrides from "./pages/PushPackageOverrides/index";
import GlobalOperations from "./pages/GlobalOperations/index";
import Version from "./pages/Version/index";
import ExpenseCodes from "./pages/ExpenseCodes/index";
import { hasUserRole } from "../role/roleUtil";
import { namespace } from "../api/xmmAxios";

// create an empty map
const moduleKeys = {
  dashboard: "dashboard",
  launchair: "launchair",
  dealeradmin: "dealeradmin",
  globaloperations: "globaloperations",
  xmmadmin: "xmmadmin",
  version: "version",
  expenseCodes: "expenseCodes"
};

export const moduleMapping = new Map();
export const routes = [];

const launchAirModule = [
  {
    path: "/",
    exact: true,
    leaf: true,
    label: "Launch AIR",
    nodeName: "launchair",
    parentLocaleKey: "xmmadmin.portal.nav.launchair",
    parentName: moduleKeys.launchair,
    localekey: "xmmadmin.portal.nav.launchair",
    main: () => <AirLauncher />
  }
];

const dealeradmindModule = [
  {
    path: "/copytodealer",
    exact: true,
    leaf: false,
    label: "Copy to Dealer",
    nodeName: "dealeradmin",
    parentLocaleKey: "xmmadmin.portal.nav.dealeradmin",
    parentName: moduleKeys.dealeradmin,
    localekey: "xmmadmin.portal.nav.copytodealerdealer_plmp",
    main: () => <DealerAdmin />
  },
  {
    path: "/pushpackages",
    exact: true,
    leaf: false,
    label: "Push Package Override Templates",
    nodeName: "dealeradmin",
    parentLocaleKey: "xmmadmin.portal.nav.dealeradmin",
    parentName: moduleKeys.dealeradmin,
    localekey: "xmmadmin.portal.nav.pushpackages",
    main: () => <PushPackageOverrides />
  },
  {
    path: "/vendor",
    exact: true,
    leaf: false,
    label: "Add New Vendor",
    nodeName: "dealeradmin",
    parentLocaleKey: "xmmadmin.portal.nav.dealeradmin",
    parentName: moduleKeys.dealeradmin,
    localekey: "xmmadmin.portal.nav.add_new_vendor",
    main: () => <VendorPage />
  }
];
const globalOperationsModule = [
  {
    path: "/globalOperations",
    exact: true,
    leaf: true,
    label: "Global Operations",
    nodeName: "globaloperations",
    parentLocaleKey: "xmmadmin.portal.nav.globaloperations",
    parentName: moduleKeys.globaloperations,
    localekey: "xmmadmin.portal.nav.globaloperations",
    main: () => <GlobalOperations />
  }
];
const xmmAdminModule = [
  {
    path: "/xmmAdmin",
    exact: true,
    leaf: true,
    label: "Xtime Admin",
    nodeName: "xmmadmin",
    parentLocaleKey: "xmmadmin.portal.nav.xmmAdmin",
    parentName: moduleKeys.xmmadmin,
    localekey: "xmmadmin.portal.nav.xmmAdmin",
    main: () => <XMMAdmin />
  }
];
const versionModule = [
  {
    path: "/version",
    exact: true,
    leaf: true,
    label: "Version",
    nodeName: "version",
    parentLocaleKey: "xmmadmin.portal.nav.version",
    parentName: moduleKeys.version,
    localekey: "xmmadmin.portal.nav.version",
    main: () => <Version />
  }
];
const expenseCodesModule = [
  {
    path: "/expenseCodes",
    exact: true,
    leaf: true,
    label: "Expense Codes",
    nodeName: "expenseCodes",
    parentLocaleKey: "xmmadmin.portal.nav.expenseCodes",
    parentName: moduleKeys.expenseCodes,
    localekey: "xmmadmin.portal.nav.expenseCodes",
    main: () => <ExpenseCodes />
  }
];

/*
const dashboardModule = [
  {
    path: "/dashboard",
    exact: true,
    leaf: true,
    label: "Dash Board",
    nodeName: "dashboard",
    parentLocaleKey: "xmmadmin.portal.nav.dashboard",
    parentName: moduleKeys.dashboard,
    localekey: "xmmadmin.portal.nav.dashboard",
    main: () => (
      <div>
        {" "}
        <h3> Dash Board </h3>
      </div>
    )
  }
];
*/
// @params {key} as "launchair"
export function getModule(key) {
  return moduleMapping.get(key);
}

function setModule(key, moduleObj) {
  moduleMapping.set(key, moduleObj);
}
/* Add modules to hashmap here in proper order */
function setRouteModules(role, xmmAdministrator) {
  // setModule(moduleKeys.dashboard, dashboardModule);
  const { pathname } = window.location;
  if (pathname !== "/xmmAdmin") {
    if (hasUserRole("launchAir", role)) {
      setModule(moduleKeys.launchair, launchAirModule);
    }
    if (hasUserRole("dealerAdmin", role)) {
      setModule(moduleKeys.dealeradmin, dealeradmindModule);
    }
    if (hasUserRole("globalOperations", role)) {
      // show global operations for US only
      if (!namespace.toLowerCase().includes("aus")) {
        setModule(moduleKeys.globaloperations, globalOperationsModule);
      }
    }
    if (hasUserRole("version", role)) {
      if (!namespace.toLowerCase().includes("aus")) {
        setModule(moduleKeys.version, versionModule);
      }
    }
  }
  if (pathname === "/xmmAdmin" && xmmAdministrator === true) {
    setModule(moduleKeys.xmmadmin, xmmAdminModule);
  }
  if (pathname === "/expenseCodes") {
    setModule(moduleKeys.expenseCodes, expenseCodesModule);
  }
}

export function printModules() {
  for (const key of moduleMapping.keys()) {
    console.log(key);
  }
  moduleMapping.forEach(function (value, key) {
    console.log("values", typeof value);
    console.log(key, JSON.stringify(value, null, 2));
  });
}
function clearModuleMapping() {
  moduleMapping.clear();
}
/* primary method called in App.js to initiate route hashmap */
export function initiateModules(role, xmmadmin) {
  clearModuleMapping();
  setRouteModules(role, xmmadmin);
  updateRoutes();
}

/* This method returns HashMap with header menus */
export function getModuleMapping(role, xmmadmin) {
  initiateModules(role, xmmadmin);
  return moduleMapping;
}

/* This method will iterate over all module mapping and construct Routes[] */
export function updateRoutes() {
  routes.length = 0;
  const routesList = [];
  for (const value of moduleMapping.values()) {
    value.forEach(function (route, index) {
      routesList.push(route);
      routes.push(route);
    });
  }
}
